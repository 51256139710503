import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";

const BankRuptcy = ({ company, finHealthChart, authenticated, openFreeAccModal }) => {
  const chartOptions = {
    title: {
      text: "",
    },
    xAxis: {
      categories: ["2018", "2019", "2020", "2021", "2022"],
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    series: [
      {
        name: company.risk_label,
        data: [
          company.risk_an1,
          company.risk_an2,
          company.risk_an3,
          company.risk_an4,
          company.risk_an5,
        ],
      },
    ],
  };

  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card" style={{height:"493px"}}>
        {authenticated && finHealthChart ? (
          <div className="card-header d-flex justify-content-between">
            <div className="card-title mb-0 w-100">
              <h5 className="mb-0">
                FINANCIAL HEALTH
                {company.risk_label === "Distress" ? (
                  <span
                    title="Calculated using Altman Z' Score model"
                    style={{ float: "right" }}
                    className="badge rounded-pill bg-danger"
                  >
                    {company.risk_label}
                  </span>
                ) : company.risk_label === "Grey" ? (
                  <span
                    title="Calculated using Altman Z' Score model"
                    style={{ float: "right" }}
                    className="badge rounded-pill bg-secondary"
                  >
                    {company.risk_label}
                  </span>
                ) : company.risk_label === "Safe" ? (
                  <span
                    title="Calculated using Altman Z' Score model"
                    style={{ float: "right" }}
                    className="badge rounded-pill bg-success"
                  >
                    {company.risk_label}
                  </span>
                ) : null}
              </h5>
            </div>
          </div>
        ) : (
          <div className="card-header d-flex justify-content-between">
            <div className="card-title mb-0 w-100">
              <h5 className="mb-0">FINANCIAL HEALTH</h5>
            </div>
          </div>
        )}
        <div className="card-body pt-2 text-center">
          {authenticated ? (
            finHealthChart ? (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            ) : (
              <Link
                className="btn btn-outline-danger waves-effect"
                to="/plans"
                style={{ display: "inline-block", marginTop:"33%" }}
              >
                Upgrade Plan
              </Link>
            )
          ) : (
            <a onClick={openFreeAccModal}
                className="btn btn-outline-danger waves-effect"
                href="#"
                style={{ display: "inline-block", marginTop:"33%" }}
              >
                Get Free Account
              </a>
          )}{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default BankRuptcy;
