import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChartOne = ({ domain }) => {
  // Initialize variables with default values
  let noopener = 0;
  let noreferrer = 0;
  let nofollow = 0;
  let external = 0;
  let ugc = 0;
  const screenHeight = window.innerHeight;
  const height = screenHeight > 1900 ? "350px" : "250px";

  try {
    // Parse the JSON data from domain.referring_links_flags
    const data = JSON.parse(domain.referring_links_flags);

    // Separate the values into individual variables
    noopener = data.noopener;
    noreferrer = data.noreferrer;
    nofollow = data.nofollow;
    external = data.external;
    ugc = data.ugc;
  } catch (error) {
    console.error("Error parsing data:", error);
  }

  // Configuration for the donut chart
  const donutChartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: "Flags",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {

        name: "Flags",
        colorByPoint: true,
        data: [
          {
            name: "noopener",
            y: noopener,
            sliced: true,
            selected: true,
          },
          {
            name: "noreferrer",
            y: noreferrer,
          },
          {
            name: "nofollow",
            y: nofollow,
          },
          {
            name: "external",
            y: external,
          },
          {
            name: "ugc",
            y: "",
          },
          {
            name: "",
            y: ugc,
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          }
        ],
      },
    ],
  };

  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card" style={{maxHeight:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div className="card-body pt-2" id="doNutChart" style={{ width: '200px', height: '200px' }}>
          <HighchartsReact highcharts={Highcharts} options={donutChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default DonutChartOne;
