import React, { useEffect, useState } from "react";
import Bookmark from "./bookmark";
import CommonFunctions from "../../common/functions";
import { Link } from "react-router-dom";

const CompanyProfile = ({
  companyId,
  company,
  authenticated,
  openFreeAccModal,
}) => {
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [canBm, setCanBm] = useState(false);

  function getFirstSocialUrl(socialNetwork) {
    // Check if the input string is defined and contains a semicolon
    if (socialNetwork && socialNetwork.includes(";")) {
      // Split the string by semicolon and get the first value
      var valuesArray = socialNetwork.split(";");
      var firstValue = valuesArray[0];
      return firstValue;
    } else {
      // If there is no semicolon or the string is undefined, return an empty string
      return (socialNetwork === "NULL" || socialNetwork === "" ? "" : socialNetwork);
    }
  }

  useEffect(() => {
    if (authenticated) {
      setCanBm(CommonFunctions.checkPermission("can_bookmark", 0));

      // Call the getFirstValue function and set the result in the state
      setFacebookUrl(getFirstSocialUrl(company.Facebook_url));
      setInstaUrl(getFirstSocialUrl(company.Insta_url));
      setLinkedinUrl(getFirstSocialUrl(company.Linkedin_url));
      setTwitterUrl(getFirstSocialUrl(company.Twitter_url));
      setYoutubeUrl(getFirstSocialUrl(company.youtube_url));
    }
  }, [
    company.Facebook_url,
    company.Insta_url,
    company.Linkedin_url,
    company.Twitter_url,
    company.youtube_url,
  ]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card mb-4 alert alert-primary">
          <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
            <div className="flex-grow-1 mt-3 mt-sm-5">
              <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                <div className="user-profile-info">
                  <Link className="text-dark" to="/companies"><i className="fa fa-angle-double-left"></i> Back</Link>
                  <h3><i style={{marginRight:"5px"}}><img style={{width:"40px", height:"40px"}} src={company.logo_url ? company.logo_url.split(';')[0] : ''}/></i> {company.company}</h3>
                  <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                    <li className="list-inline-item d-flex gap-1">
                      <i className="ti ti-color-swatch"></i>{" "}
                      {company.name_industry}
                    </li>
                    <li className="list-inline-item d-flex gap-1">
                      <i className="ti ti-map-pin"></i>{" "}
                      {company.name_subindustry}
                    </li>
                    <li className="list-inline-item d-flex gap-1 w-100 social-icons-li">
                      {facebookUrl !== "" ? (
                          <a
                            href={facebookUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              className="fab fa-facebook fa-2x"
                              style={{ color: "#1877F2" }}
                            ></i>
                          </a>
                        ) : null
                      }
                      {/* YouTube */}
                      {authenticated ? (
                        youtubeUrl === "" ? null : (
                          <a href={youtubeUrl} target="_blank" rel="noreferrer">
                            <i
                              className="fab fa-youtube fa-2x"
                              style={{ color: "#FF0000" }}
                            ></i>
                          </a>
                        )
                      ) : (
                        <a href="#" onClick={openFreeAccModal}>
                          <i
                            className="fab fa-youtube fa-2x"
                            style={{ color: "#FF0000" }}
                          ></i>
                        </a>
                      )}
                      {/* Twitter */}
                      {authenticated ? (
                        twitterUrl === "" ? null : (
                          <a href={twitterUrl} target="_blank" rel="noreferrer">
                            <i
                              className="fab fa-twitter fa-2x"
                              style={{ color: "#1DA1F2" }}
                            ></i>
                          </a>
                        )
                      ) : (
                        <a href="#" onClick={openFreeAccModal}>
                          <i
                            className="fab fa-twitter fa-2x"
                            style={{ color: "#1DA1F2" }}
                          ></i>
                        </a>
                      )}
                      {/* LinkedIn */}
                      {authenticated ? (
                        linkedinUrl === "" ? null : (
                          <a
                            href={linkedinUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              className="fab fa-linkedin fa-2x"
                              style={{ color: "#0A66C2" }}
                            ></i>
                          </a>
                        )
                      ) : (
                        <a href="#" onClick={openFreeAccModal}>
                          <i
                            className="fab fa-linkedin fa-2x"
                            style={{ color: "#0A66C2" }}
                          ></i>
                        </a>
                      )}
                      {/* Instagram */}
                      {authenticated ? (
                        instaUrl === "" ? null : (
                          <a href={instaUrl} target="_blank" rel="noreferrer">
                            <i
                              className="fab fa-instagram fa-2x"
                              style={{ color: "#E4405F" }}
                            ></i>
                          </a>
                        )
                      ) : (
                        <a href="#" onClick={openFreeAccModal}>
                          <i
                            className="fab fa-instagram fa-2x"
                            style={{ color: "#E4405F" }}
                          ></i>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
                {authenticated && canBm && (
                  <Bookmark companyId={companyId}></Bookmark>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
