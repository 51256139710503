import axios from "axios";



export default axios.create({
  baseURL: "https://bck.scoring.ro/api",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
  }
});