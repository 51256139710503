import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChartTwo = ({ domain }) => {
  // Initialize variables with default values
  let ro = 0;
  let com = 0;
  let net = 0;
  let org = 0;
  let info = 0;
  let ir = 0;
  let club = 0;
  let comAu = 0;
  let pl = 0;
  let coUk = 0;
  const screenHeight = window.innerHeight;
  const height = screenHeight > 1900 ? "350px" : "250px";

  try {
    // Parse the JSON data from domain.referring_links_zones
    const data = JSON.parse(domain.referring_links_zones);

    // Separate the values into individual variables
    ro = data.ro;
    com = data.com;
    net = data.net;
    org = data.org;
    info = data.info;
    ir = data.ir;
    club = data.club;
    comAu = data['com.au'];
    pl = data.pl;
    coUk = data['co.uk'];
  } catch (error) {
    console.error("Error parsing data:", error);
  }

  // Configuration for the donut chart
  const donutChartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: true,
      type: 'pie',
  },
    title: {
      text: "Zones",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Zones",
        colorByPoint: true,
        data: [
          {
            name: "ro",
            y: ro,
          },
          {
            name: "com",
            y: com,
          },
          {
            name: "net",
            y: net,
          },
          {
            name: "org",
            y: org,
          },
          {
            name: "info",
            y: info,
          },
          {
            name: "ir",
            y: ir,
          },
          {
            name: "club",
            y: club,
          },
          {
            name: "com.au",
            y: comAu,
          },
          {
            name: "pl",
            y: pl,
          },
          {
            name: "co.uk",
            y: coUk,
          }
        ],
      },
    ],
  };

  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card" style={{maxHeight:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div className="card-body pt-2" id="doNutChart" style={{ width: '200px', height: '200px' }}>
          <HighchartsReact highcharts={Highcharts} options={donutChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default DonutChartTwo;
