import React from "react";
import DonutChart from "../domains/donut";
import DonutChartOne from "../domains/donut_one";
import DonutChartTwo from "../domains/donut_two";
import DonutChartThree from "../domains/donut_three";

const DomainReputationTwo = ({domain}) => {
  function formatNumber(number) {
    const parts = number.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';
    
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    return `${formattedInteger}${decimalPart ? `.${decimalPart}` : ''}`;
  }

  return (
    <div>
      <div className="row">
        <h4 className="py-3 mb-4">
          Domain Referrals
        </h4>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-primary">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-primary">
                    <i className="ti ti-browser-check ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize:"18px"}}>{domain.domain_authority && formatNumber(domain.domain_authority)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Domain Authority</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-warning">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-warning">
                    <i className="ti ti-copy ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.crawled_pages && formatNumber(domain.crawled_pages)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Content Volume</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <i className="ti ti-unlink ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.backlinks && formatNumber(domain.backlinks)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Backlinks</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-info">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-info">
                    <i className="ti ti-exclamation-circle ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.backlinks_spam_score}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Spam Score</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-primary">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-primary">
                    <i className="ti ti-square-arrow-down ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.referring_domains && formatNumber(domain.referring_domains)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Referring Subdomains</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-warning">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-warning">
                    <i className="ti ti-world-www ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.referring_ips && formatNumber(domain.referring_ips)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Referring Domains</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <i className="ti ti-network ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.referring_subnets && formatNumber(domain.referring_subnets)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Referring Ips</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 mb-4">
          <div className="card card-border-shadow-info">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-info">
                    <i className="ti ti-git-branch ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{fontSize: "18px"}}>{domain.referring_domains_nofollow && formatNumber(domain.referring_domains_nofollow)}</h4>
              </div>
              <p className="mb-1" style={{fontSize: "13px"}}>Referring Subnets</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <DonutChart domain={domain}></DonutChart>
        <DonutChartOne domain={domain}></DonutChartOne>
        <DonutChartTwo domain={domain}></DonutChartTwo>
        <DonutChartThree domain={domain}></DonutChartThree>
      </div>

    </div>
  );
};

export default DomainReputationTwo;
