import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams
import AffiliateDataService from "../services/affiliate.service";
import RankChart from "./domains/chart";
import DonutChart from "./domains/donut";
import DomainProfile from "./domain/DomainProfile";
import TechnicalInfo from "./domain/TechnicalInfo";
import DomainPopularity from "./domain/DomainPopularity";
import DomainStructure from "./domain/DomainStructure";
import DomainReputation from "./domain/DomainReputation";
import DomainReputationTwo from "./domain/DomainReputationTwo";
import { useNavigate } from "react-router-dom";

function DomainDetail({ permViewDomProfile }) {
  const navigate = useNavigate();
  const [domain, setDomain] = useState({});
  const { id } = useParams();

  const fetchDomainDetail = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`, // Replace with your user token
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data) {
          console.log("Domain Data: " + JSON.stringify(res.data.data));
          setDomain(res.data.data.attributes);
        } else {
          console.log("Domain not found!");
        }
      })
      .catch((error) => {
        console.error("Error fetching domain data:", error);
      });
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const updateViews = async (userToken, apiUrl, viewCount) => {
    try {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
      const currentYear = currentDate.getFullYear();
      const todaysDate =
        currentMonth + "/" + currentDate.getDate() + "/" + currentYear;

      // Retrieve stored views_limit_start_date from localStorage
      const storedStartDate = localStorage.getItem("viewCountStartDate");

      // Split the date string into day, month, and year components
      const [storedMonth, storedDay, storedYear] = storedStartDate.split("/");

      // Construct a valid date string in the format 'mm/dd/yyyy'
      const storedStartDateValidFormat = `${storedMonth}/${storedDay}/${storedYear}`;

      // Convert the date strings to Date objects
      const todaysDateObj = new Date(todaysDate);
      const storedStartDateObj = new Date(storedStartDateValidFormat);

      // Calculate the difference in milliseconds
      const dateDifference = todaysDateObj - storedStartDateObj;

      // Convert the difference to days
      const daysDifference = dateDifference / (1000 * 60 * 60 * 24);
      let dataToUpdate = {
        views_limit: viewCount,
      };
      
      // Set the pageViewsLimit based on userSelPlanId
      const pageViewsLimit = localStorage.getItem("planViewCountLimit");

      // Check if storedStartDate is available and the difference is greater than 30 days
      if (daysDifference > 30) {
        // If the difference is greater than 30 days, set views_limit to 1
        dataToUpdate.views_limit = 1;
        // Update views_limit_start_date to the current date
        dataToUpdate.views_limit_start_date = `${currentMonth}/${currentDate.getDate()}/${currentYear}`;
        localStorage.setItem("viewCount", 1);
        localStorage.setItem("viewCountStartDate", todaysDate);
      } else {
        // If not greater than 30 days, use the regular logic
        if (viewCount === 1) {
          // Ensure two digits for day and month
          dataToUpdate.views_limit_start_date = `${currentMonth}/${currentDate.getDate()}/${currentYear}`;
          localStorage.setItem("viewCount", viewCount);
        } else {
          // Update the view count in localStorage
          localStorage.setItem("viewCount", viewCount.toString());

          // Check if the limit is reached
          if (viewCount >= pageViewsLimit) {
            // Redirect to the /plans page
            navigate("/plans");
          }
        }
      }

      await axios.put(
        `${apiUrl}/scorers/${localStorage.getItem("userPlanId")}`,
        {
          data: dataToUpdate,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "Error updating views count for this record:",
        error.message
      );
      throw error;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    // if (!permViewDomProfile) {
    //   window.location.href = "/plans";
    //   return false;
    // }
    // Define your API URL based on the company id parameter
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;

      // Get the current view count from localStorage or initialize it to 0
      let viewCount = parseInt(localStorage.getItem("viewCount")) || 0;

      // Increment the view count
      viewCount++;
      updateViews(userToken, apiUrl, viewCount);

    //const userData = localStorage.getItem('userData');
    //const userDataJSON = JSON.parse(userData);
    fetchDomainDetail(userToken, apiUrl + `/domains/${id}`);
    // Fetch company details using axios
  }, [id, permViewDomProfile, navigate]); // Execute this effect whenever the id parameter changes

  return (
    <div>
      {/* <CompanyProfile companyId={id} company={company}></CompanyProfile> */}
      <DomainProfile domain={domain}></DomainProfile>
      <hr />

      {/*technical info*/}
      <TechnicalInfo domain={domain}></TechnicalInfo>
      <hr />

      {/*domain popularity */}
      <DomainPopularity domain={domain}></DomainPopularity>
      <hr />

      {/*domain structure */}
      <DomainStructure domain={domain}></DomainStructure>
      <hr />

      {/*domain reputation */}
      <DomainReputation domain={domain}></DomainReputation>
      <hr />

      {/*domain reputation*/}
      <DomainReputationTwo domain={domain}></DomainReputationTwo>
    </div>
  );
}

export default DomainDetail;
