import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import authImage from "../assets/img/illustrations/auth-login-illustration-light.png";
import bgShapeImageLight from "../assets/img/illustrations/bg-shape-image-light.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

export default class SiteRegister extends Component {
  constructor(props) {
    super(props);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    //this.onChangeReffBy = this.onChangeReffBy.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
    //this.onChangeUserPhone = this.onChangeUserPhone.bind(this);
    this.saveRegisterDetails = this.saveRegisterDetails.bind(this);
    //this.onChangeCaptcha = this.onChangeCaptcha.bind(this);

    this.state = {
      userName: "",
      reffBy: "",
      userEmail: "",
      //userPhone: "",
      passWord: "",
      // c1: Math.floor(Math.random() * 10),
      // c2: Math.floor(Math.random() * 10),
      // captchaAns: "",
      loginErr: "",
      userRole: 1,
      showPassword: false,
      emailSent: "",
    };
  }

  componentDidMount() {
    var memberId = this.getQueryVariable('mId');
    if(memberId != '' || memberId != false){
      this.callMemberData(memberId);
    }
  }

  getQueryVariable(ref) {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    if(vars.length > 0){
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if (pair[0] == ref) { return pair[1]; }
      }
    }
    return (false);
  }

  // onChangeCaptcha(e) {
  //   this.setState({
  //     captchaAns: e.target.value
  //   });
  // }

  // onChangeReffBy(e) {
  //   this.setState({
  //     reffBy: e.target.value
  //   });
  // }
  onChangeUsername(e) {
    this.setState({
      userName: e.target.value
    });
  }
  onChangeUserEmail(e) {
    this.setState({
      userEmail: e.target.value
    });
  }
  // onChangeUserPhone(e) {
  //   const formattedPhoneNumber = this.formatPhoneNumber(e.target.value);
  //   this.setState({
  //     userPhone: formattedPhoneNumber
  //   });
  // }

  onChangePassword(e) {
    this.setState({
      passWord: e.target.value
    });
  }

  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  saveRegisterDetails() {
    //validate form
    if (this.state.userName === "") {
      this.setState({
        loginErr: "Username required!"
      });
      return false;
    }

    if (this.state.userEmail.trim() === ""){
      this.setState({
        loginErr: "Invalid Email!"
      });
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.userEmail)){
      this.setState({
        loginErr: "Invalid Email!"
      });
      return false;
    }

    // if (this.state.userPhone.trim() === "") {
    //   this.setState({
    //     loginErr: "Phone required!"
    //   });
    //   return false;
    // }

    if (this.state.passWord === "") {
      this.setState({
        loginErr: "Password required!"
      });
      return false;
    }
    if (this.state.passWord.length < 8) {
      this.setState({
        loginErr: "Password must have atleast 8 characters!"
      });
      return false;
    }
    if (this.state.userName.length < 3) {
      this.setState({
        loginErr: "Username must be of minimum three characters!"
      });
      return false;
    }

    // var expectedAns = (this.state.c1 + this.state.c2);
    // if (expectedAns != this.state.captchaAns) {
    //   this.setState({
    //     loginErr: "Wrong Captcha Answer!"
    //   });
    //   return false;
    // }

    var data = {
      userName: this.state.userName,
      userEmail: this.state.userEmail,
      //userPhone: this.state.userPhone,
      passWord: this.state.passWord,
    };

    const apiUrl = AffiliateDataService.baseURL;
    axios
    .post(apiUrl + '/auth/local/register', {
      username: data.userName,
      email: data.userEmail,
      password: data.passWord,
    })
    .then(response => {
      this.setState({
        submitted: true
      });
      if (response.data.jwt !== null) {
        this.setState({
          emailSent: data.userEmail
        });
        localStorage.setItem("registered", true);
        //create credits record for this user
        // axios
        // .post(
        //   apiUrl + '/user-credits',
        //   {
        //     data: {
        //       user: response.data.user.id,
        //       credits: 50,
        //     },
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${response.data.jwt}`,
        //       'Content-Type': 'application/json',
        //     },
        //   }
        // )
        // .then(response => {
        //   console.log("User credits record added!");
        // });
        
        setTimeout(() => {
          window.location.href = "signin?u=1";
        }, 3000);
        
        this.setState({
          userName: "",
          userEmail: "",
          passWord: ""
        });
      }
    })
    .catch(error => {
      // Handle error.
      console.log('An error occurred:', error.response);
    });
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const { loginErr, emailSent } = this.state;
    return (
      <div className="light-style customizer-hide">
        <div className="authentication-wrapper authentication-cover authentication-bg">
          <div className="authentication-inner row">
            <div className="d-none d-lg-flex col-lg-7 p-0">
              <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                <img
                  src={authImage}
                  alt="auth-login-cover"
                  className="img-fluid my-5 auth-illustration"
                  data-app-light-img="illustrations/auth-login-illustration-light.png"
                  data-app-dark-img="illustrations/auth-login-illustration-dark.png"
                />

                <img
                  src={bgShapeImageLight}
                  alt="auth-login-cover"
                  className="platform-bg"
                  data-app-light-img="illustrations/bg-shape-image-light.png"
                  data-app-dark-img="illustrations/bg-shape-image-dark.png"
                />
              </div>
            </div>
            <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <div className="app-brand mb-4">
                  <a href="index.html" className="app-brand-link gap-2">
                    <span className="app-brand-logo demo">
                      <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      </svg>
                    </span>
                  </a>
                </div>
                <h3 className="mb-1 fw-bold">Welcome to Scoring! 👋</h3>
                {loginErr !== "" ? <h5 className="text text-danger">{loginErr}</h5> : ""}
                {emailSent !== "" ? <p className="text text-success">Account activation link sent to your email address {emailSent} Please follow the link inside to continue.</p> : ""}
                <p className="mb-4">Please register your account and start the adventure</p>

                {/* <form id="formAuthentication" className="mb-3" action="index.html" method="POST"> */}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    required
                    value={this.state.userName}
                    onChange={this.onChangeUsername}
                    name="userName"
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userEmail"
                    required
                    value={this.state.userEmail}
                    onChange={this.onChangeUserEmail}
                    name="userEmail"
                    autoFocus
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">Password</label>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control"
                      id="passWord"
                      required
                      value={this.state.passWord}
                      onChange={this.onChangePassword}
                      name="passWord"
                      min={8}
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={this.togglePasswordVisibility}
                    >
                      <i
                        className={`ti ${
                          this.state.showPassword ? "ti-eye" : "ti-eye-off"
                        }`}
                      ></i>
                    </span>{" "}
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-me" />
                    <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                  </div>
                </div>
                <button onClick={this.saveRegisterDetails} className="btn btn-primary d-grid w-100">Register Now</button>
                {/* </form> */}

                <p className="text-center mt-3">
                  <span>Already have an account?</span>
                  <a href="/signin">
                    <span>Sign In</span>
                  </a>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
