import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams
import AffiliateDataService from "../services/affiliate.service";
import GeneralInfo from "./company/general-info";
import FinancialIndicators from "./company/financial-indicators";
import BankRuptcy from "./company/bankrupcy";
import CompanyProfile from "./company/profile";
import TurnOverChart from "./company/section3/turnover";
import EbitChart from "./company/section3/ebit";
import EbitdaChart from "./company/section3/ebitda";
import RoaChart from "./company/section3/roa";
import RoeChart from "./company/section3/roe";
import IndeptnessChart from "./company/section3/indeptness";
import InventoryTurnoverChart from "./company/section3/inventory_tunover";
import ReceivablesTurnoverChart from "./company/section3/receivables_tunover";
import EmployeesChart from "./company/section3/no-of-employees";
import FreeAcc from "./company/free-account-modal";
import { useNavigate } from "react-router-dom";

function CompanyDetail({ permViewComProfile, finHealthChart, authenticated }) {
  const navigate = useNavigate();
  const [company, setCompany] = useState({});
  const [isFreeAccModalOpen, setFreeAccModalOpen] = useState(false);

  const { id } = useParams();

  const openFreeAccModal = () => {
    setFreeAccModalOpen(true);
  };

  const closeFreeAccModal = () => {
    setFreeAccModalOpen(false);
  };

  const fetchCompanyDetail = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: authenticated
          ? {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            }
          : {},
      })
      .then((res) => {
        if (res.data) {
          //console.log("Company Data: " + JSON.stringify(res.data.data));
          setCompany(res.data.data.attributes);
        } else {
          console.log("Company not found!");
        }
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const updateViews = async (userToken, apiUrl, viewCount) => {
    try {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
      const currentYear = currentDate.getFullYear();
      const todaysDate =
        currentMonth + "/" + currentDate.getDate() + "/" + currentYear;

      // Retrieve stored views_limit_start_date from localStorage
      const storedStartDate = localStorage.getItem("viewCountStartDate");

      // Split the date string into day, month, and year components
      const [storedMonth, storedDay, storedYear] = storedStartDate.split("/");

      // Construct a valid date string in the format 'mm/dd/yyyy'
      const storedStartDateValidFormat = `${storedMonth}/${storedDay}/${storedYear}`;

      // Convert the date strings to Date objects
      const todaysDateObj = new Date(todaysDate);
      const storedStartDateObj = new Date(storedStartDateValidFormat);

      // Calculate the difference in milliseconds
      const dateDifference = todaysDateObj - storedStartDateObj;

      // Convert the difference to days
      const daysDifference = dateDifference / (1000 * 60 * 60 * 24);
      let dataToUpdate = {
        views_limit: viewCount,
      };
      
      // Set the pageViewsLimit based on userSelPlanId
      const pageViewsLimit = localStorage.getItem("planViewCountLimit");

      // Check if storedStartDate is available and the difference is greater than 30 days
      if (daysDifference > 30) {
        // If the difference is greater than 30 days, set views_limit to 1
        dataToUpdate.views_limit = 1;
        // Update views_limit_start_date to the current date
        dataToUpdate.views_limit_start_date = `${currentMonth}/${currentDate.getDate()}/${currentYear}`;
        localStorage.setItem("viewCount", 1);
        localStorage.setItem("viewCountStartDate", todaysDate);
      } else {
        // If not greater than 30 days, use the regular logic
        if (viewCount === 1) {
          // Ensure two digits for day and month
          dataToUpdate.views_limit_start_date = `${currentMonth}/${currentDate.getDate()}/${currentYear}`;
          localStorage.setItem("viewCount", viewCount);
        } else {
          // Update the view count in localStorage
          localStorage.setItem("viewCount", viewCount.toString());

          // Check if the limit is reached
          if (viewCount >= pageViewsLimit) {
            // Redirect to the /plans page
            navigate("/plans");
          }
        }
      }

      await axios.put(
        `${apiUrl}/scorers/${localStorage.getItem("userPlanId")}`,
        {
          data: dataToUpdate,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "Error updating views count for this record:",
        error.message
      );
      throw error;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    console.log("I am authenticated" + authenticated);

    // Define your API URL based on the company id parameter
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;

    if (authenticated) {
      // Get the current view count from localStorage or initialize it to 0
      let viewCount = parseInt(localStorage.getItem("viewCount")) || 0;

      // Increment the view count
      viewCount++;
      updateViews(userToken, apiUrl, viewCount);
      //console.log(`Hit number: ${viewCount}`);
      //console.log("view_profile " + permViewComProfile);
      // if (!permViewComProfile) {
      //   window.location.href = "/plans";
      //   return false;
      // }
    }

    //const userData = localStorage.getItem('userData');
    //const userDataJSON = JSON.parse(userData);
    fetchCompanyDetail(userToken, apiUrl + `/companies/${id}`);
    // Fetch company details using axios
  }, [id, authenticated, permViewComProfile, navigate]); // Execute this effect whenever the id parameter changes

  return (
    <div>
      
      <CompanyProfile
        companyId={id}
        company={company}
        authenticated={authenticated}
        openFreeAccModal={openFreeAccModal}
      ></CompanyProfile>
      <div className="row">
        <GeneralInfo
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></GeneralInfo>
        <FinancialIndicators
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></FinancialIndicators>
        <BankRuptcy
          company={company}
          finHealthChart={finHealthChart}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></BankRuptcy>
      </div>
      <div className="row">
        <TurnOverChart
          company={company}
          authenticated={authenticated}
        ></TurnOverChart>
        <EbitChart company={company} authenticated={authenticated}></EbitChart>
        <EbitdaChart
          company={company}
          authenticated={authenticated}
        ></EbitdaChart>
      </div>
      <div className="row">
        <IndeptnessChart
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></IndeptnessChart>
        <ReceivablesTurnoverChart
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></ReceivablesTurnoverChart>
        <InventoryTurnoverChart
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></InventoryTurnoverChart>
      </div>
      <div className="row">
        <RoaChart
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></RoaChart>
        <RoeChart
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></RoeChart>
        <EmployeesChart
          company={company}
          authenticated={authenticated}
          openFreeAccModal={openFreeAccModal}
        ></EmployeesChart>
      </div>
      <FreeAcc showModal={isFreeAccModalOpen} handleClose={closeFreeAccModal} />
    </div>
  );
}

export default CompanyDetail;
