import React, { useState, useEffect } from "react";
import axios from "axios";
import AffiliateDataService from "../services/affiliate.service";

const Pricing = ({ isAnnual }) => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    // Make an Axios GET request to fetch plans data
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.head.appendChild(script);

    axios
      .get(apiUrl + "/membership-plans", {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPlans(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  function updateUserPlan(newPlanId, isAnnual) {
    // Create membership record for this user
    const userPanRecordId = localStorage.getItem("userPlanId");
  
    // Get current date
    const currentDate = new Date();
  
    // Calculate expiry date based on the plan type
    const expiryDate = new Date(currentDate);
    if (isAnnual === 1) {
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    } else {
      expiryDate.setMonth(expiryDate.getMonth() + 1);
    }
  
    // Format dates to YYYY-MM-DD
    const formattedStartDate = currentDate.toISOString().split('T')[0];
    const formattedExpiryDate = expiryDate.toISOString().split('T')[0];
  
    // Update the user's plan
    axios.put(
      `${AffiliateDataService.baseURL}/scorers/${userPanRecordId}`,
      {
        data: {
          plan_id: newPlanId,
          plan_started_on: formattedStartDate,
          plan_exiry_date: formattedExpiryDate,
        },
      }
    ).then(() => {
      console.log("User membership record updated!");
      window.location = "/logout";
    }).catch((error) => {
      console.error("Error updating membership record:", error);
    });
  
    return false;
  }
  
  return (
    <div className="row">
      {plans.map((plan) => (
        <div key={plan.id} className="col-lg mb-md-0 mb-4">
          <div className="card border-primary border shadow-none">
            <div className="card-body position-relative">
              <div className="position-absolute end-0 me-4 top-0 mt-4">
                <span className="badge bg-label-primary"></span>
              </div>
              <div className="my-3 pt-2 text-center">
                {/* <img src={plan.image} alt={plan.attributes.name + " Image"} height="140" /> */}
              </div>
              <h3 className="card-title text-center text-capitalize mb-1">
                {plan.attributes.plan_name}
              </h3>
              <p className="text-center">{plan.attributes.plan_slug}</p>
              <div className="text-center">
                <div className="d-flex justify-content-center">
                  <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">
                    $
                  </sup>
                  {isAnnual ? (
                    <h1 className="price-toggle price-yearly display-4 text-primary mb-0">
                      {plan.attributes.yearly_price}
                    </h1>
                  ) : (
                    <h1 className="price-toggle price-monthly display-4 text-primary mb-0">
                      {plan.attributes.monthly_price}
                    </h1>
                  )}{" "}
                  <sub className="h6 text-muted pricing-duration mt-auto mb-2 fw-normal">
                    {isAnnual ? "/ year" : "/ month"}
                  </sub>
                </div>
                <small className="price-yearly price-yearly-toggle text-muted">
                  $ {plan.attributes.yearly_price} / year
                </small>
              </div>
              {/* <stripe-buy-button
                buy-button-id="buy_btn_1OKPe42LhaGCsLJBxHwgWYPh"
                publishable-key="pk_test_EEgo0GqTNGvrwajTMoDkCe1U"
              ></stripe-buy-button> */}

              <ul className="ps-3 my-4 pt-2">
                {plan.attributes.yearly_details}
                {/* {plan.features.map((feature, index) => (
                  <li key={index} className="mb-2">
                    {feature}
                  </li>
                ))} */}
              </ul>

              {/* <a
                href="https://buy.stripe.com/test_eVabLFeLO4VW4jCbII"
                className="btn btn-primary d-grid w-100 waves-effect waves-light"
              >
                Upgrade
              </a> */}
              <a
                href="#"
                onClick={() => updateUserPlan(plan.id)}
                className="btn btn-primary d-grid w-100 waves-effect waves-light"
              >
                Upgrade
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Pricing;
