import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipComp = ({ tooltipText, visibleText, tooltipWidth }) => {
  // Truncate the visibleText if it's longer than maxLength
  const truncatedVisibleText = visibleText && visibleText.length > 20
    ? `${visibleText.slice(0, 20)}...`
    : visibleText;

  const tooltipStyle = {
    maxWidth: tooltipWidth, // Set the width of the truncated text
  };

  // Ensure tooltipText is an HTML string
  const htmlText = tooltipText ? { __html: tooltipText } : null;

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" className="tool-tip-style">
            <div dangerouslySetInnerHTML={htmlText}></div>
          </Tooltip>
        }
      >
        <p>{truncatedVisibleText}</p>
      </OverlayTrigger>
    </div>
  );
};

export default TooltipComp;
