import http from "../http-common";

class AffiliateDataService {
  // getAll() {
  //   return http.get("/websites");
  // }

  baseURL= "https://bck.scoring.ro/api";

  getAllPayoutRequests(affCode) {
    return http.get(`/aff-all-payout-requests?affCode=${affCode}`);
  }

  getAffiliateOrders(affCode) {
    return http.get(`/aff-orders?affCode=${affCode}`);
  }

  getReferredUsers(affCode) {
    return http.get(`/aff-referred-users?affCode=${affCode}`);
  }

  login(userName, passWord) {
    return http.post("/auth/local", {userName, passWord});
  }

  forgot(email) {
    return http.post("/aff-forgot-password", {email});
  }

  reset(token, passWord) {
    return http.post("/aff-reset-password", {token, passWord});
  }

  register(userName, userEmail, userPhone, passWord, reffBy, userRole) {
    return http.post("/aff-signup", {userName, userEmail, userPhone, passWord, reffBy, userRole});
  }

  savePaypal(paypalEmail, affCode) {
    return http.post("/save-aff-paypal-email", {paypalEmail, affCode});
  }

  requestPayout(affCode, payMethod) {
    return http.post("/aff-request-payout", {affCode, payMethod});
  }

  affCurrentPayoutRequest(affCode) {
    return http.post("/aff-current-payout-request", {affCode});
  }
  
  validateReffByCode(affCode) {
    return http.post("/validate-reff-by-code", {affCode});
  }

  activateAffiliateById(affId) {
    return http.post("/activate-affiliate", {affId});
  }

  pullMemberData(memberId) {
    return http.post("/pull-member-data", {memberId});
  }

  // update(id, data) {
  //   //console.log("updated data put: " + JSON.stringify(data));
  //   return http.put(`/websites/${id}`, data);
  // }

  // delete(id) {
  //   return http.delete(`/websites/${id}`);
  // }

  // deleteAll() {
  //   return http.delete(`/websites`);
  // }

  // findByTitle(title) {
  //   return http.get(`/websites?title=${title}`);
  // }
}

export default new AffiliateDataService();