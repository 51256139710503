import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios"; // Import Axios
import ModalComponent from "./modal-appened"; // Import your modal component

function EmailsTable({ emails, pageNumber, allowNext, totalRecords, decrementCredits, emailListId}) {
  const [myCredits, setMyCredits] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedEmails, setSortedEmails] = useState([...emails]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("domain");
  const itemsPerPage = 100;
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [bookmarkedDomains, setBookmarkedDomains] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalData, setIsModalData] = useState(false); //data to be passed to modal component
  const [domData, setDomData] = useState(false); //data to be passed to modal component
  const [isModalDataType, setIsModalDataType] = useState(false); //data to be passed to modal component
  const [loadingId, setLoadingId] = useState(null);
  const [businessEmailCount, setBusinessEmailCount] = useState(0);
  const [namesCount, setNamesCount] = useState(0);
  const [compCount, setCompCount] = useState(0);
  const [domCount, setDomCount] = useState(0);

  useEffect(() => {
    // Update the sortedDomains whenever the emails prop changes
    setSortedEmails([...emails]);
  }, [emails]);

  const openModal = (comRes, domRes) => {
    if (comRes !== "" || comRes !== "null") {
      setIsModalData(comRes);
      setIsModalDataType("com");
      setDomData(domRes);
    } else if (domRes !== "" || domRes !== "null") {
      setIsModalData(domRes);
      setIsModalDataType("dom");
    } else {
      setIsModalData("");
      setIsModalDataType("");
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setMyCredits(localStorage.getItem("userCredits"));
    console.log("Credits Balance: " + myCredits);

    // Sort the data when sortColumn or sortOrder changes
    const sorted = [...sortedEmails].sort((a, b) => {
      const colA = getSortableValue(a, sortColumn);
      const colB = getSortableValue(b, sortColumn);

      if (typeof colA === "number" && typeof colB === "number") {
        // If both values are numbers, compare them as numbers
        return sortOrder === "asc" ? colA - colB : colB - colA;
      } else {
        // If either value is not a number, compare them as strings
        if (sortOrder === "asc") {
          return colA.toString().localeCompare(colB.toString());
        } else {
          return colB.toString().localeCompare(colA.toString());
        }
      }
    });
    setSortedEmails(sorted);
  }, [sortColumn, sortOrder]);

  const handleNextPage = () => {
    const nPage = parseInt(pageNumber + 1);
    window.location.href = "/email-list?p=" + nPage;
  };

  const handlePrevPage = () => {
    const pPage = parseInt(pageNumber - 1);
    window.location.href = "/email-list?p=" + pPage;
  };

  const handleSort = (column) => {
    // Define the columns where sorting is allowed
    const allowedColumns = ["email", "name"];

    if (allowedColumns.includes(column)) {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  const renderSortingArrow = (column) => {
    if (column === sortColumn) {
      if (sortOrder === "asc") {
        return <span>&uarr;</span>;
      } else {
        return <span>&darr;</span>;
      }
    }
    return null;
  };

  // Function to get the sortable value for a column
  const getSortableValue = (item, column) => {
    if (item && item.attributes && item.attributes[column] !== undefined) {
      const columnValue = item.attributes[column];
      return columnValue.toString();
    }

    // Handle the case where item.attributes or item.attributes[column] is undefined
    return "";
  };

  const searchCompany = async (domain) => {
    try {
      //const firstPartOfDomain = domain.split('.')[0];
      const conDomain = ' '+domain;
      const response = await axios.get(
        `${apiUrl}/companies?filters[$or][0][websites][$startsWith]=${domain}&filters[$or][1][websites][$contains]=${conDomain}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      
      return response.data.data.length > 0 ? response.data : false;
    } catch (error) {
      console.error("Error searching for company:", error.message);
      throw error;
    }
  };

  const searchDomain = async (domain) => {
    try {
      const response = await axios.get(
        `${apiUrl}/domains?filters[web][$eq]=${domain}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      return response.data.data.length > 0 ? response.data : false;
    } catch (error) {
      console.error("Error searching for domain:", error.message);
      throw error;
    }
  };

  const updateEmailRecord = async (emailId, companyData, domainData) => {
    try {
      const response = await axios.put(
        `${apiUrl}/email-records/${emailId}`,
        {
          data: {
            company_res: JSON.stringify(companyData),
            domain_res: JSON.stringify(domainData),
            appended: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      // Return the updated data
      return response.data.data;
    } catch (error) {
      console.error("Error updating email record:", error.message);
      throw error;
    }
  };

  const handleAppendItem = async (emailAddress, emailId) => {
    try {
      setLoadingId(emailId);
      const domain = extractDomain(emailAddress);

      if (!domain) {
        console.error("Invalid email address");
        return;
      }

      const companyData = await searchCompany(domain);
      let domainData;

      if (!companyData) {
        domainData = await searchDomain(domain);

        if (domainData) {
          // Update the email record with domain data
          const updatedEmailRecordsData = await updateEmailRecord(
            emailId,
            null,
            domainData
          );

          // Update the local state to reflect the changes in the table
          setSortedEmails((prevSortedEmails) =>
            prevSortedEmails.map((email) =>
              email.id === emailId
                ? { ...email, ...updatedEmailRecordsData }
                : email
            )
          );

          //decrease credit
          // Decrease user credits count if remaining balance is > 0
          if (myCredits > 0) {
            myCredits--;
            localStorage.setItem("userCredits", myCredits);
            setMyCredits(myCredits);
            //update user credits
            let dataToUpdate = {
              credits: myCredits,
            };
            axios.put(
              `${
                AffiliateDataService.baseURL
              }/scorers/${localStorage.getItem("userPlanId")}`,
              {
                data: dataToUpdate,
              },
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              }
            );
          }
        } else {
          // Show an alert or notification for no data found
          setLoadingId(null);
          alert("No data found!");
          return;
        }
      } else {
        domainData = await searchDomain(domain);
        // Update the email record with company data
        const updatedEmailRecordsData = await updateEmailRecord(
          emailId,
          companyData,
          domainData
        );

        // Update the local state to reflect the changes in the table
        setSortedEmails((prevSortedEmails) =>
          prevSortedEmails.map((email) =>
            email.id === emailId
              ? { ...email, ...updatedEmailRecordsData }
              : email
          )
        );

        //decrease credit
        let userCredits = parseInt(localStorage.getItem("userCredits")) || 0;

        // Decrease user credits count if remaining balance is > 0
        if (userCredits > 0) {
          userCredits--;
          localStorage.setItem("userCredits", userCredits);
          //update user credits
          let dataToUpdate = {
            credits: userCredits,
          };
          axios.put(
            `${AffiliateDataService.baseURL}/scorers/${localStorage.getItem(
              "userPlanId"
            )}`,
            {
              data: dataToUpdate,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        }
      }
      setLoadingId(null);
      decrementCredits(myCredits);
    } catch (error) {
      console.error("Error:", error.message);
      // Show an alert or notification for the error
      alert("Error: " + error.message);
    }
  };

  const handleDeleteEmail = (emailId) => {
    // You can optionally display a confirmation dialog here
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this email?"
    );

    if (confirmDelete) {
      // Send a DELETE request to your API
      axios.delete(`${apiUrl}/email-records/${emailId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // Optionally, you can display a success message
      setNotify("Email deleted successfully.");
      setNotifyClass("success");

      //get page number
      const queryParams = getQueryParams(window.location.href);
      const pn = queryParams.p ? parseInt(queryParams.p) : 1;

      setTimeout(() => {
        if (pn == 1) {
          window.location.href = "/email-list/"+emailListId;
        } else {
          window.location.href = "/email-list/"+emailListId+"&p=" + pn;
        }
      }, 1000);
    }
  };

  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  //extract domain from the email address
  const extractDomain = (email) => {
    if (!email) return null;

    const atIndex = email.indexOf("@");
    if (atIndex === -1) return null;
    if (
      email.slice(atIndex + 1) === "gmail.com" ||
      email.slice(atIndex + 1) === "yahoo.com" ||
      email.slice(atIndex + 1) === "hotmail.com"
    ) {
      return "Personal";
    } else {
      return email.slice(atIndex + 1);
    }
  };

  useEffect(() => {
    const countBusinessEmails = () => {
      const count = sortedEmails.filter(
        (email) =>
          email.attributes &&
          email.attributes.email &&
          isBusinessEmail(email.attributes.email)
      ).length;

      const namesCount = sortedEmails.filter(
        (email) => email.attributes && email.attributes.name
      ).length;

      const companiesCount = sortedEmails.filter(
        (email) => email.attributes && email.attributes.company_res
      ).length;

      const domainsCount = sortedEmails.filter(
        (email) => email.attributes && email.attributes.company_res
      ).length;

      setBusinessEmailCount(count);
      setNamesCount(namesCount);
      setCompCount(companiesCount);
      setDomCount(domainsCount);
    };

    const isBusinessEmail = (email) => {
      const emailDomain = getEmailDomain(email);
      return !["gmail.com", "yahoo.com", "hotmail.com"].includes(emailDomain);
    };

    const getEmailDomain = (email) => {
      const [, domain] = email.split("@");
      return domain.toLowerCase();
    };

    countBusinessEmails();
  }, [sortedEmails]);

  const isMobileScreen = window.innerWidth <= 768;

  return (
    <div className="card">
      <div className="mt-2">
        <div className={isMobileScreen ? "row" : "row m-4"}>
          <div class="col-lg-3 col-6 mb-4">
            <div class="card card-border-shadow-primary h-100">
              <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                  <div class="avatar me-2">
                    <span class="avatar-initial rounded bg-label-primary">
                      <i class="ti ti-mail-opened ti-md"></i>
                    </span>
                  </div>
                  <h4 class="ms-1 mb-0" style={{ fontSize: "16px" }}>
                    Business Emails
                  </h4>
                </div>
                <p class="mb-1">
                  <strong>{businessEmailCount}</strong> (
                  {((businessEmailCount / sortedEmails.length) * 100).toFixed(
                    2
                  )}
                  %)
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6 mb-4">
            <div class="card card-border-shadow-primary h-100">
              <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                  <div class="avatar me-2">
                    <span class="avatar-initial rounded bg-label-primary">
                      <i class="ti ti-users-group ti-md"></i>
                    </span>
                  </div>
                  <h4 class="ms-1 mb-0" style={{ fontSize: "16px" }}>
                    Names
                  </h4>
                </div>
                <p class="mb-1">
                  <strong>{namesCount}</strong> (
                  {((namesCount / sortedEmails.length) * 100).toFixed(1)}%)
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6 mb-4">
            <div class="card card-border-shadow-primary h-100">
              <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                  <div class="avatar me-2">
                    <span class="avatar-initial rounded bg-label-primary">
                      <i class="ti ti-building ti-md"></i>
                    </span>
                  </div>
                  <h4 class="ms-1 mb-0" style={{ fontSize: "16px" }}>
                    Companies
                  </h4>
                </div>
                <p class="mb-1">
                  <strong>{compCount}</strong> (
                  {((compCount / sortedEmails.length) * 100).toFixed(1)}%)
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6 mb-4">
            <div class="card card-border-shadow-primary h-100">
              <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                  <div class="avatar me-2">
                    <span class="avatar-initial rounded bg-label-primary">
                      <i class="ti ti-network ti-md"></i>
                    </span>
                  </div>
                  <h4 class="ms-1 mb-0" style={{ fontSize: "16px" }}>
                    Domains
                  </h4>
                </div>
                <p class="mb-1">
                  <strong>{domCount}</strong> (
                  {((domCount / sortedEmails.length) * 100).toFixed(1)}%)
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <h5 className="card-header pl-0">
          Emails List (<i>{totalRecords}</i> found!)
        </h5> */}
        {notify !== "" ? (
          <div
            className={`w-50 alert alert-${notifyClass}`}
            style={{
              position: "fixed",
              top: "80px",
              right: "0px",
              zIndex: "10",
            }}
          >
            {notify}
          </div>
        ) : null}
        <div
          className={
            isMobileScreen
              ? "text-nowrap company-table"
              : "text-nowrap company-table m-4"
          }
          style={isMobileScreen ? { padding: "0px", margin: "0px" } : {}}
        >
          <table className="table table-hover table-bordered table-striped p-2">
            <thead>
              <tr>
                <th className="" onClick={() => handleSort("email")}>
                  Email {renderSortingArrow("email")}
                </th>
                <th className="hide-tab-mob" onClick={() => handleSort("name")}>
                  Name {renderSortingArrow("name")}
                </th>
                <th className="hide-tab-mob">Company</th>
                <th className="hide-tab-mob">Industry</th>
                <th className="hide-tab-mob">Revenue</th>
                <th className="hide-tab-mob">Employees</th>
                <th className="hide-tab-mob">Domain</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              {sortedEmails
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((email) => (
                  <tr key={email.id}>
                    <td className="">
                      <span className="fw-medium">
                        {email.attributes && email.attributes.email}
                      </span>
                    </td>
                    <td className="hide-tab-mob">{email.attributes.name}</td>
                    <td className="hide-tab-mob">
                      {email.attributes.company_res !== "null" &&
                      email.attributes.company_res !== null
                        ? JSON.parse(email.attributes.company_res).data[0]
                            ?.attributes?.company
                        : ""}
                    </td>
                    <td className="hide-tab-mob">
                      {email.attributes.company_res !== "null" &&
                      email.attributes.company_res !== null
                        ? JSON.parse(email.attributes.company_res).data[0]
                            ?.attributes?.name_industry
                        : ""}{" "}
                    </td>
                    <td className="hide-tab-mob">
                      {email.attributes.company_res !== "null" &&
                      email.attributes.company_res !== null
                        ? JSON.parse(email.attributes.company_res).data[0]
                            ?.attributes?.ca_an5
                        : ""}
                    </td>
                    <td className="hide-tab-mob">
                      {email.attributes.company_res !== "null" &&
                      email.attributes.company_res !== null
                        ? JSON.parse(email.attributes.company_res).data[0]
                            ?.attributes?.nr_sal_an5
                        : ""}
                    </td>
                    <td className="hide-tab-mob">
                      {email.attributes.appended
                        ? extractDomain(email.attributes.email)
                        : ""}
                    </td>

                    <td>
                      <div className="d-flex">
                        {email.attributes.appended ? (
                          <i
                            onClick={() =>
                              openModal(
                                JSON.parse(email.attributes.company_res),
                                JSON.parse(email.attributes.domain_res)
                              )
                            }
                            style={{ cursor: "pointer" }}
                            className="text-danger ti ti-eye"
                          ></i>
                        ) : (
                          ""
                        )}
                        {!email.attributes.appended &&
                          extractDomain(email.attributes.email) !==
                            "Personal" &&
                          myCredits > 0 && (
                            <i
                              onClick={() =>
                                handleAppendItem(
                                  email.attributes.email,
                                  email.id
                                )
                              }
                              style={{ cursor: "pointer" }}
                              className={`text-danger ${
                                loadingId === email.id
                                  ? "fa fa-spin fa-spinner"
                                  : "ti ti-plus"
                              }`}
                            ></i>
                          )}{" "}
                        <i
                          onClick={() => handleDeleteEmail(email.id)}
                          style={{ cursor: "pointer" }}
                          className="text-danger ti ti-trash"
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="float-right-pagination">
          {pageNumber == 1 ? (
            ""
          ) : (
            <button className="btn btn-primary" onClick={handlePrevPage}>
              &laquo; Prev
            </button>
          )}
          {allowNext ? (
            <button className="btn btn-primary" onClick={handleNextPage}>
              Next &raquo;
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <ModalComponent
        show={isModalOpen}
        handleClose={closeModal}
        modalData={isModalData}
        dataType={isModalDataType}
        domData={domData}
      />
    </div>
  );
}

export default EmailsTable;
