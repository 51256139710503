import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieChart = ({domain}) => {
  // Configuration for the pie chart
  const pieChartOptions = {
    chart: {
      type: "pie",
      height: 250,
    },
    title: {
      text: "Traffic Source",
    },
    series: [
      {
        name: "Data",
        data: [
          ["Direct", domain.direct_traffic_share_website_traffic_host],
          ["Search", domain.search_traffic_share_website_traffic_host],
          ["Display", domain.display_traffic_share_website_traffic_host],
          ["Referral", domain.referral_traffic_share_website_traffic_host],
          ["Social", domain.social_traffic_share_website_traffic_host],
          ["Email", domain.mail_traffic_share_website_traffic_host],
        ],
      },
    ],
  };

  return (
    <div className="col-lg-4 col-12 mb-4">
      <div className="card">
        <div className="card-body pt-2">
          <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
