import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChart = ({ domain }) => {
  // Initialize variables with default values
  let anchor = 0;
  let image = 0;
  let redirect = 0;
  const screenHeight = window.innerHeight;
  const height = screenHeight > 1900 ? "350px" : "250px";
 
  try {
    // Parse the JSON data from domain.referring_links_types
    const data = JSON.parse(domain.referring_links_types);

    // Separate the values into individual variables
    anchor = data.anchor;
    image = data.image;
    redirect = data.redirect;
  } catch (error) {
    console.error("Error parsing data:", error);
  }

  // Configuration for the donut chart
  const donutChartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
  },
    title: {
      text: "Links",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Links",
        colorByPoint: true,
        data: [
          {
            name: "Anchor",
            y: anchor,
            sliced: false,
            selected: false,
          },
          {
            name: "Image",
            y: image,
          },
          {
            name: "Redirect",
            y: redirect,
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          },
          {
            name: "",
            y: "",
          }
        ],
      },
    ],
  };

  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card" style={{maxHeight:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div className="card-body pt-2" id="doNutChart" style={{ width: '200px', height: '200px' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={donutChartOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
