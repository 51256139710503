import React, { useEffect, useState } from "react";
import domainIcon from "../assets/svg/flags/us.svg";

const ModalComponent = ({
  show,
  handleClose,
  modalData,
  dataType,
  domData,
}) => {
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [company, setCompany] = useState("");
  const [companyIcon, setCompanyIcon] = useState("");
  const [annualRev, setAnnualRev] = useState("");
  const [foundYear, setFoundYear] = useState("");
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [dom, setDom] = useState("");
  const [desc, setDesc] = useState("");
  const [tech, setTech] = useState("");
  const [analytics, setAnalytics] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [expId, setExpId] = useState(false);

  const toggleExpansion = (epId) => {
    setIsExpanded(!isExpanded);
    setExpId(epId);
  };

  const setSocialUrl = (socialUrl) => {
    if (socialUrl && socialUrl.includes(";")) {
      const valuesArray = socialUrl.split(";");
      const firstValue = valuesArray[0];
      return firstValue;
    } else {
      return socialUrl || ""; // Return an empty string if socialUrl is undefined
    }
  };

  useEffect(() => {
    if (
      !modalData ||
      !modalData.data ||
      !modalData.data[0] ||
      !modalData.data[0].attributes
    ) {
      // Handle the case where the necessary properties are not available
      return;
    }

    const domain = modalData.data[0].attributes;
    const moreDet = domData.data[0].attributes;
    const web = dataType === "com" ? domain.websites : domain.web;

    setAnnualRev(domain.tunover_level_filter);
    setFoundYear(domain.year);
    setCountry(domain.country);
    setIndustry(domain.name_industry);
    setDom(domain.websites);
    setDesc(moreDet.description);
    setTech(moreDet.technologies);
    setAnalytics(moreDet.analytics_and_tracking);
    setCompany(domain.company);
    setCompanyIcon(domain.logo_url);

    if (web) {
      const websitesArray = web.split(";").map((website) => website.trim());
      setWebsiteUrl(websitesArray[0]);
    }

    if (dataType === "com") {
      setFacebookUrl(setSocialUrl(domain.Facebook_url));
      setInstaUrl(setSocialUrl(domain.Insta_url));
      setLinkedinUrl(setSocialUrl(domain.Linkedin_url));
      setTwitterUrl(setSocialUrl(domain.Twitter_url));
      setYoutubeUrl(setSocialUrl(domain.youtube_url));
    } else if (dataType === "dom") {
      setFacebookUrl(setSocialUrl(domain.facebook));
      setInstaUrl(setSocialUrl(domain.instagram));
      setLinkedinUrl(setSocialUrl(domain.linkedin));
      setTwitterUrl(setSocialUrl(domain.twitter));
      setYoutubeUrl(setSocialUrl(domain.video_platforms));
    }
  }, [modalData, dataType]);

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      id="modalScrollable"
      tabIndex="-1"
      style={{ display: show ? "block" : "none" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              onClick={handleClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{zIndex:100}}
            ></button>
          </div>
          <div className="modal-body">
            {/* //domain name and icon */}
            <div className="card mb-4">
              <div className="card-body">
                <h3 className="mb-0 col-md-12">
                  {" "}
                  <i style={{marginRight:"5px"}}><img src={companyIcon} style={{ width: "50px", height: "40px" }}/></i>
                  <strong>{company}</strong>
                </h3>
              </div>
            </div>

            <h4 className="card-title">Social Profiles</h4>
            <div className="card mb-4">
              <div className="card-body">
                <div className="row">
                  {" "}
                  <div className="card-text col-md-5 text-red">{websiteUrl}</div>
                  <div className="card-text text-right col-md-7">
                  {facebookUrl == "" || facebookUrl === "NULL" ? (
                        ""
                      ) : (
                        <a
                      href={facebookUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: "15px" }}
                    >
                      <i
                        className="fab fa-facebook fa-2x"
                        style={{ color: "#1877F2" }}
                      ></i>
                    </a>
                      )}
                      {youtubeUrl == ""  || youtubeUrl === "NULL" ? (
                        ""
                      ) : (
                    <a
                      href={youtubeUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: "15px" }}
                    >
                      <i
                        className="fab fa-youtube fa-2x"
                        style={{ color: "#FF0000" }}
                      ></i>
                    </a>
                      )}

                      {twitterUrl == "" || twitterUrl === "NULL" ? (
                        ""
                      ) : (
                    <a
                      href={twitterUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: "15px" }}
                    >
                      <i
                        className="fab fa-twitter fa-2x"
                        style={{ color: "#1DA1F2" }}
                      ></i>
                    </a>
                      )}
                      {linkedinUrl == "" || linkedinUrl === "NULL" ? (
                        ""
                      ) : (
                    <a
                      href={linkedinUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: "15px" }}
                    >
                      <i
                        className="fab fa-linkedin fa-2x"
                        style={{ color: "#0A66C2" }}
                      ></i>
                    </a>
                      )}
                      {instaUrl == ""  || instaUrl === "NULL" ? (
                        ""
                      ) : (
                    <a href={instaUrl} target="_blank" rel="noreferrer">
                      <i
                        className="fab fa-instagram fa-2x"
                        style={{ color: "#E4405F" }}
                      ></i>
                    </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            {/* //domain name and icon ends */}

            <h4 className="card-title">Additional Info</h4>
            <div className="row">
              <div className="col-md-6">
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">Annual Revenue</h5>
                    <p className="card-text text-left">{annualRev}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">Found Year</h5>
                    <p className="card-text text-left">{foundYear}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">Country</h5>
                    <p className="card-text text-left">{country}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">Industry</h5>
                    <p className="card-text text-left">{industry}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-4" style={{ minHeight: "147px" }}>
                  <div className="card-body">
                    <h5 className="card-title">Domain</h5>
                    {dom ? (
                      <>
                        <p
                          className={`card-text ${
                            isExpanded && expId === 1 ? "expanded" : ""
                          }`}
                        >
                          {isExpanded && expId === 1
                            ? dom
                            : `${dom.slice(0, 20)}...`}
                        </p>
                        {dom.length > 20 && (
                          <a href="#" onClick={() => toggleExpansion(1)}>
                            {isExpanded && expId === 1 ? "Show Less" : "Show More"}
                          </a>
                        )}
                      </>
                    ) : (
                      <p className="small text-muted">
                        Unavailable data, please try again in some time!
                      </p>
                    )}
                  </div>
                </div>
              </div> 
             <div className="col-md-6">
                <div className="card mb-4" style={{ minHeight: "147px" }}>
                  <div className="card-body">
                    <h5 className="card-title">Description</h5>
                    {desc ? (
                      <>
                        <p
                          className={`card-text ${
                            isExpanded && expId === 2 ? "expanded" : ""
                          }`}
                        >
                          {isExpanded && expId === 2
                            ? desc
                            : `${desc.slice(0, 20)}...`}
                        </p>
                        {desc.length > 20 && (
                          <a href="#" onClick={() => toggleExpansion(2)}>
                            {isExpanded && expId === 2 ? "Show Less" : "Show More"}
                          </a>
                        )}
                      </>
                    ) : (
                      <p className="small text-muted">
                        Unavailable data, please try again in some time!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-4" style={{ minHeight: "147px" }}>
                  <div className="card-body">
                    <h5 className="card-title">Technologies</h5>
                    {tech ? (
                      <>
                        <p
                          className={`card-text ${
                            isExpanded && expId === 3 ? "expanded" : ""
                          }`}
                        >
                          {isExpanded && expId === 3
                            ? tech
                            : `${tech.slice(0, 20)}...`}
                        </p>
                        {tech.length > 20 && (
                          <a href="#" onClick={() => toggleExpansion(3)}>
                            {isExpanded && expId === 3 ? "Show Less" : "Show More"}
                          </a>
                        )}
                      </>
                    ) : (
                      <p className="small text-muted">
                        Unavailable data, please try again in some time!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card mb-4" style={{ minHeight: "147px" }}>
                  <div className="card-body">
                    <h5 className="card-title">Analytics</h5>
                    {analytics ? (
                      <>
                        <p
                          className={`card-text ${
                            isExpanded && expId === 4 ? "expanded" : ""
                          }`}
                        >
                          {isExpanded && expId === 4
                            ? analytics
                            : `${analytics.slice(0, 20)}...`}
                        </p>
                        {analytics.length > 20 && (
                          <a href="#" onClick={() => toggleExpansion(4)}>
                            {isExpanded && expId === 4 ? "Show Less" : "Show More"}
                          </a>
                        )}
                      </>
                    ) : (
                      <p className="small text-muted">
                        Unavailable data, please try again in some time!
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-label-secondary waves-effect"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
