import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";




export default class AffiliateOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      affCode: localStorage.getItem('userAffiliateCode'),
      orders: [],
      totalOrders: 0,
      bountyType: '',
      bountyRate: 0
    };
  }

  componentDidMount() {
    this.retrieveOrders();
  }

  retrieveOrders() {
    AffiliateDataService.getAffiliateOrders(this.state.affCode)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            orders: (this.props.limit !== '' ? response.data.orders : response.data.orders.slice(0, this.props.limit)),
            totalOrders: response.data.totalOrders,
            // bountyRate: response.data.bountyRate,
            // bountyType: response.data.bountyType,
          });
        }
        //console.log("Orders: " + this.props.limit);
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { orders, totalOrders} = this.state;
    return (
      <div>
        <h2>Affiliates Orders <strong className="float-right badge badge-warning"><i>{totalOrders} Orders</i></strong></h2>
        <div className="af-label">Labels <span>Commission Paid</span></div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Email</th>
                <th>Order Date</th>
                <th>Order Amount</th>
                <th>Commission</th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((order, index) => (
                  <tr>
                    <td>{order.order_id}</td>
                    <td>{order.order.client_email}</td>
                    <td>{order.order.order_date}</td>
                    <td>{'$' + order.order.amount}</td>
                    <td>{'$' + order.amount}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
