import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChartThree = ({ domain }) => {
  // Initialize variables with default values
  let unknown = 0;
  let blogs = 0;
  let cms = 0;
  let organization = 0;
  let messageBoards = 0;
  let ecommerce = 0;
  let news = 0;
  const screenHeight = window.innerHeight;
  const height = screenHeight > 1900 ? "350px" : "250px";

  try {
    // Parse the JSON data from domain.referring_links_platform_types
    const data = JSON.parse(domain.referring_links_platform_types);

    // Separate the values into individual variables
    unknown = data.unknown;
    blogs = data.blogs;
    cms = data.cms;
    organization = data.organization;
    messageBoards = data['message-boards'];
    ecommerce = data.ecommerce;
    news = data.news;
  } catch (error) {
    console.error("Error parsing data:", error);
  }

  // Configuration for the donut chart
  const donutChartOptions = {

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 350
    },
    title: {
      text: "Platforms",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {

        name: "Platforms",
        colorByPoint: true,
        data: [
          {
            name: "Unknown",
            y: unknown,

          },
          {
            name: "Blogs",
            y: blogs,
          },
          {
            name: "Cms",
            y: cms,
          },
          {
            name: "Organization",
            y: organization,
          },
          {
            name: "Message Boards",
            y: messageBoards,
          },
          {
            name: "Ecommerce",
            y: ecommerce,
          },
          {
            name: "News",
            y: news,
          }
        ],
      },
    ],
  };

  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card" style={{maxHeight:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div className="card-body pt-2" id="doNutChart" style={{ width: '200px', height: '200px' }}>
          <HighchartsReact highcharts={Highcharts} options={donutChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default DonutChartThree;
