import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import AllOrders from "./all-orders.js";
import AllRequests from "./all-requests.js";
import AllUsers from "./all-users.js";
import SiteHeader from "./header.js";
import SiteTabs from "./tabs.js";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  EmailIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  PinterestShareButton
} from "react-share";
//const [copySuccess, setCopySuccess] = useState('');

export default class AffiliateDashboard extends Component {
  constructor(props) {
    super(props);
    this.onChangePaypal = this.onChangePaypal.bind(this);
    this.onChangeMethod = this.onChangeMethod.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.savePayPalEmail = this.savePayPalEmail.bind(this);
    this.requestPayout = this.requestPayout.bind(this);

    this.state = {
      payoutRequest: localStorage.getItem('payoutRequest'),
      minimumPayout: localStorage.getItem('minimumPayout'),
      paypalEmail: localStorage.getItem('paypalEmail'),
      userName: localStorage.getItem('userName'),
      signUpLink: localStorage.getItem('userSignUpLink'),
      orderLink: localStorage.getItem('userOrderLink'),
      affCode: localStorage.getItem('userAffiliateCode'),
      userTotal: localStorage.getItem('userTotal'),
      registered: localStorage.getItem('registered'),
      authenticated: localStorage.getItem('authenticated'),
      allPayoutReqs: localStorage.getItem("allPayoutRequests"),
      payoutReqModel: [],
      prePayoutReqModel: localStorage.getItem("previousPayoutRequestModel"),
      responseMessage: "",
      reqBtn: "",
      responseStatus: false,
      shareTitle: localStorage.getItem("shareTitle"),
      shareOrderTitle: localStorage.getItem("shareOrderTitle"),
      copied: false,
      copied1: false,
      userRole: localStorage.getItem("userRole"),
      payMethod: 1
    };
  }

  componentDidMount() {
    this.retrieveCurrentPayoutRequest();
    console.log("Uer Role " + this.state.userRole);
    if (this.state.authenticated !== "true") {
      window.location = "signin";
      return false;
    }
  }

  // your function to copy here
  // copyToClipBoard(copyMe){
  //   navigator.clipboard.writeText(copyMe);
  //   this.setState({
  //     copied: 1
  //   });
  //   //alert('Copied!')
  // }

  handleCopy() {
    // get the input Element ID. Save the reference into copyText
    var copyText = document.getElementById("mail")
    // select() will select all data from this input field filled  
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    // execCommand() works just fine except IE 8. as w3schools mention
    document.execCommand("copy")
    // alert the copied value from text input
    alert(`Email copied: ${copyText.value} `)
  }
  onChangePaypal(e) {
    this.setState({
      paypalEmail: e.target.value
    });
  }

  onChangeUsername(e) {
    this.setState({
      userName: e.target.value
    });
  }

  onChangeMethod(e) {
    this.setState({
      payMethod: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      passWord: e.target.value
    });
  }

  savePayPalEmail() {
    var data = {
      paypalEmail: this.state.paypalEmail,
      affCode: this.state.affCode
    };

    AffiliateDataService.savePaypal(data.paypalEmail, data.affCode)
      .then(response => {
        this.setState({
          responseMessage: response.data.message,
          responseStatus: response.data.success,
          paypalEmail: localStorage.setItem('paypalEmail', data.paypalEmail)
        });
        window.scrollTo(0, 0)
      })
      .catch(e => {
        console.log(e);
      });
  }

  retrieveCurrentPayoutRequest() {
    AffiliateDataService.affCurrentPayoutRequest(this.state.affCode)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            payoutReqModel: response.data.payoutReq,
          });
        }
        //console.log("Req: " + this.state.payoutReqModel);
      })
      .catch(e => {
        console.log(e);
      });
  }

  requestPayout() {
    var data = {
      affCode: this.state.affCode,
      payMethod: this.state.payMethod
    };

    AffiliateDataService.requestPayout(data.affCode, data.payMethod)
      .then(response => {
        this.setState({
          responseMessage: response.data.message,
          responseStatus: response.data.success,
          payoutRequest: (response.data.success === true ? 1 : ""),
          userTotal: 0
        });

        //set user total to 0 after payout request and save in session
        localStorage.setItem('userTotal', 0);

        //call payout request record
        this.retrieveCurrentPayoutRequest();

        if (response.data.success === true) {
          localStorage.setItem("payoutRequest", 1);
        }
        window.scrollTo(0, 0)
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { userRole, copied, copied1, shareOrderTitle, shareTitle, payoutReqModel, signUpLink, orderLink, userTotal, affCode, responseMessage, responseStatus, minimumPayout, payoutRequest } = this.state;
    document.title = shareTitle;
    document.getElementsByTagName("META")[3].content = "Facebook description";
    document.getElementsByTagName("META")[4].content = shareTitle;
    document.getElementsByTagName("META")[5].content = "Welcome | Facebook affiliate sharing description!";

    return (
      <div className="dashboard">
        <div className="container-fluid">
          <SiteHeader userName={this.state.userName} />
          <div className="row">
            <div className="col-md-12">

              {responseStatus === true && responseMessage !== "" ?
                <h5 className="col-md-12 alert alert-success mt-1">{responseMessage}</h5>
                : ""}

              {responseStatus === false && responseMessage !== "" ?
                <h5 className="col-md-12 alert alert-danger">{responseMessage}</h5>
                : ""}
              <div className="d-tabs">
                <SiteTabs userRole={userRole} />
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home">
                    <div className="tab-body">
                      <h2>Affiliates Dashboard</h2>

                      {payoutReqModel.amount != null ?
                        <div className="alert alert-success">
                          You sent a payout request for <strong className="text text-danger">${payoutReqModel.amount}</strong> on <strong className="text text-danger">{payoutReqModel.requested_on}</strong>
                        </div>
                        : ''}

                      <ul>
                        <li><i className="btn btn-primary">Minimum Payout: ${minimumPayout}</i></li>
                        <li><i className="btn btn-warning">My Total: ${userTotal}</i></li>
                        {parseInt(userTotal) >= parseFloat(minimumPayout) && payoutRequest !== 1 ?
                        <li>
                          <select name="pay_method" className="form-control" onChange={this.onChangeMethod}>
                            <option value="">Select Method</option>
                            <option value="1">Paypal</option>
                            <option value="2">Pay Check</option>
                            <option value="3">Wire Transfer</option>
                          </select>
                        </li>
                        : ""}
                        
                        {parseInt(userTotal) >= parseFloat(minimumPayout) && payoutRequest !== 1 ?
                          <li id="reqBtn"><button className="btn btn-success" onClick={this.requestPayout} >Request Payout</button></li>
                        : ""}

                      </ul>
                      <p>Below is your affiliate code and a link, Share it to earn commissions!</p>
                      <div className="table-responsive">
                        <table className="table table-bordered" id="infoTable">
                          <tr>
                            <td className="hide-mobile">Affiliate Code</td>
                            <td>
                              <h6 className="visible-mobile">Affiliate Code</h6>
                              {affCode}
                            </td>
                          </tr>
                          {userRole == 2 ?
                            <tr>
                              <td className="hide-mobile">Signup Referral Link</td>
                              <td><br />
                                <h6 className="visible-mobile">Signup Referral Link</h6>
                                <span className="alert alert-info">{signUpLink}</span>
                                <CopyToClipboard text={signUpLink}
                                  onCopy={() => this.setState({  copied: true, copied1: false })}>
                                  <button className="btn btn-info ml-2">Copy</button>
                                </CopyToClipboard>
                                {copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                                <hr />
                                <h6>Share your Sign Up link</h6>
                                <FacebookShareButton
                                  url={signUpLink}
                                  quote={shareTitle}
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>

                                <TwitterShareButton
                                  url={signUpLink}
                                  title={shareTitle}
                                  className="Demo__some-network__share-button"
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>

                                <WhatsappShareButton
                                  url={signUpLink}
                                  title={shareTitle}
                                  separator=":: "
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                                <TumblrShareButton
                                  url={signUpLink}
                                  title={shareTitle}
                                  className="Demo__some-network__share-button"
                                >
                                  <TumblrIcon size={32} round />
                                </TumblrShareButton>
                                <LinkedinShareButton
                                  url={signUpLink}
                                  title={shareTitle}
                                  className="Demo__some-network__share-button">
                                  <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                                <EmailShareButton
                                  url={signUpLink}
                                  subject={shareTitle}
                                  body={shareTitle}
                                  className="Demo__some-network__share-button"
                                >
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                                <PinterestShareButton
                                  url={String(signUpLink)}
                                  media={`${String('https://dev.postalcrm.com/includes/signup-image.jpg')}`}
                                  description={shareOrderTitle}
                                  className="Demo__some-network__share-button">
                                  <PinterestIcon size={32} round />
                                </PinterestShareButton>

                              </td>
                            </tr>
                            : ''}
                          <tr>
                            <td className="hide-mobile">Referral Order Link</td>
                            <td><br />
                              <h6 className="visible-mobile">Referral Order Link</h6>
                              <span className="alert alert-primary">{orderLink}</span>
                              <CopyToClipboard text={orderLink}
                                onCopy={() => this.setState({ copied: false, copied1: true })}>
                                <button className="btn btn-info ml-2">Copy</button>
                              </CopyToClipboard>
                              {copied1 ? <span style={{ color: 'red' }}>Copied.</span> : null}
                              <hr />
                              <h6>Share your Order link</h6>
                              <FacebookShareButton
                                url={orderLink}
                                title={shareOrderTitle}
                                className="Demo__some-network__share-button"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>

                              <TwitterShareButton
                                url={orderLink}
                                title={shareOrderTitle}
                                className="Demo__some-network__share-button"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>

                              <WhatsappShareButton
                                url={orderLink}
                                title={shareOrderTitle}
                                separator=":: "
                                className="Demo__some-network__share-button"
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                              <TumblrShareButton
                                url={orderLink}
                                title={shareOrderTitle}
                                className="Demo__some-network__share-button"
                              >
                                <TumblrIcon size={32} round />
                              </TumblrShareButton>
                              <LinkedinShareButton
                                url={orderLink}
                                title={shareOrderTitle}
                                className="Demo__some-network__share-button">
                                <LinkedinIcon size={32} round />
                              </LinkedinShareButton>
                              <EmailShareButton
                                url={orderLink}
                                subject={shareOrderTitle}
                                body={shareOrderTitle}
                                className="Demo__some-network__share-button"
                              >
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                              <PinterestShareButton
                                  url={String(orderLink)}
                                  media={`${String('https://dev.postalcrm.com/includes/order-image.jpg')}`}
                                  description={shareOrderTitle}
                                  className="Demo__some-network__share-button">
                                  <PinterestIcon size={32} round />
                                </PinterestShareButton>

                            </td>
                          </tr>
                        </table>
                      </div>

                      <hr />
                      <AllOrders limit="5" />
                      {userRole == 2 ?
                        <hr />
                        : ""}
                      {userRole == 2 ?
                        <AllUsers limit="5" />
                        : ""}
                      <hr />
                      <h2>Paypal Settings</h2>

                      <label>Paypal Email</label>
                      <input
                        type="text"
                        className="form-control col-md-4"
                        id="paypalEmail"
                        required
                        value={(this.state.paypalEmail == 'null' ? "" : this.state.paypalEmail)}
                        onChange={this.onChangePaypal}
                        name="paypalEmail"
                      />
                      <br /><input onClick={this.savePayPalEmail} type="submit" value="Update" className="btn btn-success" />

                    </div>
                  </div>
                  <div className="tab-pane fade" id="all-orders" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="tab-body">
                      <AllOrders />

                    </div>
                  </div>
                  <div className="tab-pane fade" id="all-users" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="tab-body">
                      <AllUsers />

                    </div>
                  </div>
                  <div className="tab-pane fade" id="all-requests" role="tabpanel" aria-labelledby="requests-tab">
                    <div className="tab-body">
                      <AllRequests />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
