import React, { Component, useState, useEffect } from "react";
import CompanyList from "./CompanyList";
import axios from "axios";
import CompanyDetail from "./company-detail";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Pricing from "./pricing";
import TopNavBar from "./TopNavBar";
import Select from "react-select";

import AffiliateDataService from "../services/affiliate.service";
import "../assets/vendor/fonts/fontawesome.css";
import "../assets/vendor/fonts/tabler-icons.css";
import "../assets/vendor/fonts/flag-icons.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/css/demo.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/libs/node-waves/node-waves.css";
import "../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "../assets/vendor/libs/typeahead-js/typeahead.css";
import "../assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
import "../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";
import "../assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css";
import "../assets/vendor/css/pages/cards-advance.css";

export default function CompaniesHolder() {
  const [myCredits, setMyCredits] = useState(50);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMOMenuOpen, setIsMOMenuOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [countryFilter, setCountryFilter] = useState("");
  const [firmaFilter, setFirmaFilter] = useState("");
  const [industryFilter, setIndustryFilter] = useState("");
  const { id } = useParams();
  const savedFilters = localStorage.getItem("filtersQuery");
  const [pageNumber, setPageNumber] = useState("");
  const [allowNext, setAllowNext] = useState("");
  const [canVisitCompanyPage, setCanVisitCompanyPage] = useState("");
  const [planUpdateError, setPlanUpdateError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isAnnual, setIsAnnual] = useState(true);
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const toggleDuration = () => {
    setIsAnnual((prevIsAnnual) => !prevIsAnnual);
  };

  //for auto complete
  //const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  // end for auto complete

  let filterQuery = "";
  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const navigate = useNavigate();
  const queryParams = getQueryParams(window.location.href);

  function capitalizeString(inputString, type) {
    // Convert the entire string to lowercase
    const lowercaseString = inputString.toLowerCase();
    if (type == "domain") {
      return lowercaseString;
    } else {
      // Capitalize the first letter and concatenate it with the rest of the string
      return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
    }
  }



  const fetchAutoCompleteCompanies = (userToken, apiUrl) => {
    //for auto compleet
    if (firmaFilter && firmaFilter.length >= 3) {
      setIsLoading(1);
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(apiUrl + "/companies?filters[company][$contains]=" + firmaFilter, {
          headers,
        })
        .then((response) => {
          console.log("Suggestions: " + JSON.stringify(response.data.data));
          setSuggestions(response.data.data);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setSuggestions([]);
      //setIsLoading(0);
    }
    //end - for auto complete
    //setIsLoading(0);
  };

  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const fetchUserCredits = (userToken, apiUrl, userId) => {
    axios
      .get(
        apiUrl +
          "/credits-histories?populate=*&_sort=created_at:desc&_limit=1&filters[user][id][$eq]=" +
          userId +
          "&_limit=1",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.length === 0) {
          console.log("User credits history not found!");
        } else {
          const creditsLeft = res.data.data[0].attributes.creditsLeft;
          console.log("User credits history found!" + creditsLeft);
          setMyCredits(creditsLeft);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  useEffect(() => {
    //const queryParams = getQueryParams(window.location.href);
    //set plan update errror
    if (queryParams.package) {
      setPlanUpdateError(
        "You are not allowed to perform this action. Please upgrade your plan!"
      );
    }

    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = localStorage.getItem("userData");
    const userDataJSON = JSON.parse(userData);
    console.log("User ID: " + userDataJSON.id);
    //console.log("query " + localStorage.getItem('filtersQuery'));

    if (localStorage.getItem("filtersQuery")) {
      console.log("New api endpoint: " + apiUrl + "/companies" + savedFilters);
    }

    fetchAutoCompleteCompanies(userToken, apiUrl);

    //get page number
    const pn = queryParams.p ? parseInt(queryParams.p) : 1;
    setPageNumber(pn);
    console.log("Query is: " + filterQuery);

    fetchUserCredits(userToken, apiUrl, userDataJSON.id);
  }, [id, firmaFilter]);

  const handleMouseEnter = () => {
    setIsMOMenuOpen(true);
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMOMenuOpen(false);
    setIsMenuOpen(false);
  };

  // Conditionally assign the event handlers
  const menuProps =
    isMenuOpen && !isMOMenuOpen
      ? {}
      : {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        };

  const styleWithImportant = {
    maxHeight: "80vh",
    overflowY: "auto",
    paddingLeft: "5px !important",
  };

  return (
    <div className="light-style layout-navbar-fixed layout-menu-fixed">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <aside
            {...menuProps}
            id="layout-menu"
            className={`layout-menu menu-vertical menu bg-menu-theme ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="app-brand demo">
              <a href="index.html" className="app-brand-link">
                <span className="app-brand-logo demo">
                  <svg
                    width="32"
                    height="22"
                    viewBox="0 0 32 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                      fill="#7367F0"
                    ></path>
                    <path
                      opacity="0.06"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                      fill="#161616"
                    ></path>
                    <path
                      opacity="0.06"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                      fill="#161616"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                      fill="#7367F0"
                    ></path>
                  </svg>
                </span>
                {isMenuOpen ? (
                  <span className="app-brand-text demo menu-text fw-bold">
                    Scoring
                  </span>
                ) : (
                  ""
                )}
              </a>

              <a
                onClick={toggleMenu}
                href="#"
                className="layout-menu-toggle menu-link text-large ms-auto"
              >
                <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
              </a>
            </div>
            <div className="menu-inner-shadow"></div>
            <div
              className="filter-section p-2 rounded"
              style={styleWithImportant}
            >
              <div
                className={`accordion ${
                  isMenuOpen ? "" : "accordian-icons-only"
                }`}
              >
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading1">
                    <Link
                      to="/companies"
                      type="button"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-device-ipad-search"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Company Search" : ""}
                    </Link>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading2">
                    <Link
                      to="/domains"
                      type="button"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-world-search"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Domain Search" : ""}
                    </Link>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <i
                        className="ti ti-map-pin"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Locations" : ""}
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#collapsibleSection"
                    style={{}}
                  >
                    <div className="accordion-body"></div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading4">
                    <a
                      href="/bookmarks?t=companies"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-building"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "My Companies" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading5">
                    <a
                      href="/bookmarks?t=domains"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-world-www"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "My Domains" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading6">
                    <Link
                      to="/user-email-lists"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-users"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Enrichments" : ""}
                    </Link>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading7">
                    <Link to="/logout" className="accordion-button collapsed">
                      <i
                        className="ti ti-logout"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Logout" : ""}
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          </aside>

          <div
            className={`${
              isMenuOpen ? "layout-page" : "layout-page1"
            } custom-layout-padding`}
          >
            <TopNavBar
              toggleMenu={toggleMenu}
              myCredits={myCredits}
              isMenuOpen={isMenuOpen}
            ></TopNavBar>
            <div className="content-wrapper">
              <div className="flex-grow-1 container-p-y">
                {" "}
                <div className="row">
                  <div className="card">
                    <div className="pb-sm-5 pb-2 rounded-top">
                      <div className="container py-5">
                        {planUpdateError ? (
                          <h4 className="alert alert-danger text-center">
                            {planUpdateError}
                          </h4>
                        ) : (
                          ""
                        )}
                        <h2 className="text-center mb-2 mt-0 mt-md-4">
                          Pricing Plans
                        </h2>
                        <p className="text-center">
                          Get started with us - it's perfect for individuals and
                          teams. Choose a subscription plan that meets your
                          needs.
                        </p>
                        <div className="d-flex align-items-center justify-content-center flex-wrap gap-2 pb-5 pt-3 mb-0 mb-md-4">
                          <label className="switch switch-primary ms-3 ms-sm-0 mt-2">
                            <span className="switch-label">Monthly</span>
                            <input
                              type="checkbox"
                              className="switch-input price-duration-toggler"
                              checked={isAnnual}
                              onChange={toggleDuration}
                            />
                            <span className="switch-toggle-slider">
                              <span className="switch-on"></span>
                              <span className="switch-off"></span>
                            </span>
                            <span className="switch-label">Annual</span>
                          </label>
                          {isAnnual && (
                            <div className="mt-n5 ms-n5 d-none d-sm-block">
                              <i className="ti ti-corner-left-down ti-sm text-muted me-1 scaleX-n1-rtl"></i>
                              <span className="badge badge-sm bg-label-primary">
                                Save up to 10%
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="row mx-0 gy-3">
                          <Pricing isAnnual={isAnnual}></Pricing>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
