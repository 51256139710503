import React, { useState, useEffect } from "react";
import TooltipComp from "./tooltips";
import CommonFunctions from "../../common/functions";
import { Link } from "react-router-dom";

const GeneralInfo = ({ company, authenticated, openFreeAccModal }) => {
  const [showEmailsData, setShowEmailsData] = useState(false);
  const [showPhonesData, setShowPhonesData] = useState(false);
  const [showWebsitesData, setShowWebsitesData] = useState(false);

  useEffect(() => {
    console.log("phone data: " + localStorage.getItem("visible_phone_data"));
    setShowEmailsData(
      CommonFunctions.checkPermission("visible_emails_data", 0)
    );
    setShowPhonesData(CommonFunctions.checkPermission("visible_phone_data", 0));
    setShowWebsitesData(
      CommonFunctions.checkPermission("visible_websites_data", 0)
    );

    //console.log("permissions: " + showEmailsData + " - " + showPhonesData + " - " + showWebsitesData);
  }, []);

  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">General Information</h5>
            {/* <small className="text-muted">8.52k Social Visitors</small> */}
          </div>
        </div>
        <div className="card-body">
          <ul className="p-0 m-0">
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-success rounded p-2">
                <i className="ti ti-map ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Address</h6>
                <div className="d-flex">
                  {company.address !== "NULL" ? (
                    <TooltipComp
                      tooltipText={company.address}
                      visibleText={company.address}
                      tooltipWidth="300px"
                    />
                  ) : (
                    <TooltipComp
                      tooltipText="Unavailable data, please try again in some time!"
                      visibleText="Not Available"
                      tooltipWidth="300px"
                    />
                  )}
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-success rounded p-2">
                <i className="ti ti-link ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Fiscal Code</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {company.fiscal !== "NULL" ? (
                      company.fiscal
                    ) : (
                      <TooltipComp
                        tooltipText="Unavailable data, please try again in some time!"
                        visibleText="Not Available"
                        tooltipWidth="300px"
                      />
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-success rounded p-2">
                <i className="ti ti-alarm ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Inc. Year</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {company.year !== "NULL" ? (
                      company.year
                    ) : (
                      <TooltipComp
                        tooltipText="Unavailable data, please try again in some time!"
                        visibleText="Not Available"
                        tooltipWidth="300px"
                      />
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-success rounded p-2">
                <i className="ti ti-phone ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Phones</h6>
                <div className="d-flex">
                  {authenticated ? (
                    showPhonesData ? (
                      company.phones !== "NULL" ? (
                        <TooltipComp
                          tooltipText={company.phones}
                          visibleText={company.phones}
                          tooltipWidth="300px"
                        />
                      ) : (
                        <TooltipComp
                          tooltipText="Unavailable data, please try again in some time!"
                          visibleText="Not Available"
                          tooltipWidth="300px"
                        />
                      )
                    ) : (
                      <Link
                        className="btn btn-outline-danger btn-sm"
                        to="/plans"
                      >
                        Upgrade Plan
                      </Link>
                    )
                  ) : (
                    <a
                      onClick={openFreeAccModal}
                      className="btn btn-outline-danger waves-effect"
                      href="#"
                      style={{ display: "inline-block" }}
                    >
                      Get Free Account
                    </a>
                  )}{" "}
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-success rounded p-2">
                <i className="ti ti-inbox ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Emails</h6>
                <div className="d-flex">
                  {authenticated ? (
                    showEmailsData ? (
                      company.emails !== "NULL" ? (
                        <TooltipComp
                          tooltipText={company.emails}
                          visibleText={company.emails}
                          tooltipWidth="300px"
                        />
                      ) : (
                        <TooltipComp
                          tooltipText="Unavailable data, please try again in some time!"
                          visibleText="Not Available"
                          tooltipWidth="300px"
                        />
                      )
                    ) : (
                      <Link
                        className="btn btn-outline-danger btn-sm"
                        to="/plans"
                      >
                        Upgrade Plan
                      </Link>
                    )
                  ) : (
                    <a
                      onClick={openFreeAccModal}
                      className="btn btn-outline-danger waves-effect"
                      href="#"
                      style={{ display: "inline-block" }}
                    >
                      Get Free Account
                    </a>
                  )}{" "}
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-success rounded p-2">
                <i className="ti ti-globe ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Websites</h6>
                <div className="d-flex">
                  {authenticated ? (
                    showWebsitesData ? (
                      company.websites !== "NULL" ? (
                        <TooltipComp
                          tooltipText={company.websites}
                          visibleText={company.websites}
                          tooltipWidth="300px"
                        />
                      ) : (
                        <TooltipComp
                          tooltipText="Unavailable data, please try again in some time!"
                          visibleText="Not Available"
                          tooltipWidth="300px"
                        />
                      )
                    ) : (
                      <Link
                        className="btn btn-outline-danger btn-sm"
                        to="/plans"
                      >
                        Upgrade Plan
                      </Link>
                    )
                  ) : (
                    <a
                      onClick={openFreeAccModal}
                      className="btn btn-outline-danger waves-effect"
                      href="#"
                      style={{ display: "inline-block" }}
                    >
                      Get Free Account
                    </a>
                  )}{" "}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
