import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";

export default class AffiliateUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      affCode: localStorage.getItem('userAffiliateCode'),
      users: [],
      totalUsers: 0,
    };
  }

  componentDidMount() {
    this.retrieveUsers();
  }

  timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = date + ' ' + month + ' ' + year ;
    return time;
  }

  retrieveUsers() {
    AffiliateDataService.getReferredUsers(this.state.affCode)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            users: (this.props.limit !== '' ? response.data.users : response.data.users.slice(0, this.props.limit)),
            totalUsers: response.data.totalUsers,
          });
        }
        console.log("Users: " + JSON.stringify(response.data));
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { users, totalUsers } = this.state;
    return (
      <div>
        <h2>Reffered Users <strong className="float-right badge badge-warning"><i>{totalUsers} Users</i></strong></h2>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Affiliate Code</th>
                <th>UnPaid Orders / Total</th>
                <th>UnPaid Commission / Total</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => (
                  <tr>
                    <td>{user.userName}</td>
                    <td>{user.email}</td>
                    <td>{user.affCode}</td>
                    <td>{user.totalUnpaidOrders} / {user.totalOrders}</td>
                    <td>${user.totalUnPaidCommission} / ${user.totalCommission}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
