import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import dashLight from '../assets/img/front-pages/landing-page/hero-dashboard-light.png'
import heroElemLight from '../assets/img/front-pages/landing-page/hero-elements-light.png'
import joinCommArrow from '../assets/img/front-pages/icons/Join-community-arrow.png';
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/css/pages/front-page.css";
import "../assets/vendor/css/pages/front-page-landing.css";
// import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
// import "./assets/vendor/libs/typeahead-js/typeahead.css";
// import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/swiper/swiper.css";
import { Link } from "react-router-dom";
// import "./assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
// import "./assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";
// import "./assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css";
// import "./assets/vendor/css/pages/cards-advance.css";
//import "./assets/vendor/js/helpers.js";
//import "./assets/vendor/js/template-customizer.js";
//import "./assets/vendor/js/config.js";
//import './assets/vendor/libs/jquery/jquery.js';
//import './assets/vendor/libs/popper/popper.js';
//import './assets/vendor/js/bootstrap.js';
//import './assets/vendor/libs/node-waves/node-waves.js';
//import './assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js';
//import './assets/vendor/libs/hammer/hammer.js';
//import './assets/vendor/libs/i18n/i18n.js';
//import './assets/vendor/libs/typeahead-js/typeahead.js';
//import './assets/vendor/js/menu.js';

//import './assets/vendor/libs/apex-charts/apexcharts.js';
//import './assets/vendor/libs/swiper/swiper.js';
//import './assets/vendor/libs/datatables-bs5/datatables-bootstrap5.js';

//import './assets/js/main.js';

//import './assets/js/dashboards-analytics.js';

export default class AffiliateLanding extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        //const { userRole, copied, copied1, shareOrderTitle, shareTitle, payoutReqModel, signUpLink, orderLink, userTotal, affCode, responseMessage, responseStatus, minimumPayout, payoutRequest } = this.state;
        //document.title = shareTitle;

        return (
            <div className="light-style layout-navbar-fixed">
                <nav className="layout-navbar shadow-none py-0">
                    <div className="container">
                        <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4">
                            {/* Menu logo wrapper: Start */}
                            <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">
                                {/* Mobile menu toggle: Start */}
                                <button
                                    className="navbar-toggler border-0 px-0 me-2"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <i className="ti ti-menu-2 ti-sm align-middle"></i>
                                </button>
                                {/* Mobile menu toggle: End */}
                                <a href="landing-page.html" className="app-brand-link">
                                    <span className="app-brand-logo demo">
                                        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            {/* ... SVG paths and other code ... */}
                                        </svg>
                                    </span>
                                    <span className="app-brand-text demo menu-text fw-bold ms-2 ps-1">Scoring</span>
                                </a>
                            </div>
                            {/* Menu logo wrapper: End */}
                            {/* Menu wrapper: Start */}
                            <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
                                <button
                                    className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <i className="ti ti-x ti-sm"></i>
                                </button>
                                <ul className="navbar-nav me-auto">
                                    <li className="nav-item">
                                        <a className="nav-link fw-medium" aria-current="page" href="landing-page.html#landingHero">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link fw-medium" href="landing-page.html#landingFeatures">Features</a>
                                    </li>
                                    {/* ... Other menu items ... */}
                                </ul>
                            </div>
                            {/* Menu wrapper: End */}
                            {/* Toolbar: Start */}
                            <ul className="navbar-nav flex-row align-items-center ms-auto">
                                {/* Style Switcher */}
                                <li className="nav-item dropdown-style-switcher dropdown me-2 me-xl-0">
                                    <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                                        <i className="ti ti-sm"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-styles">
                                        <li>
                                            <a className="dropdown-item" href="javascript:void(0);" data-theme="light">
                                                <span className="align-middle"><i className="ti ti-sun me-2"></i>Light</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="javascript:void(0);" data-theme="dark">
                                                <span className="align-middle"><i className="ti ti-moon me-2"></i>Dark</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="javascript:void(0);" data-theme="system">
                                                <span className="align-middle"><i className="ti ti-device-desktop me-2"></i>System</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                {/* / Style Switcher */}
                                {/* navbar button: Start */}
                                <li>
                                    <Link to="/signin" className="btn btn-primary">
                                        <span className="tf-icons ti ti-login scaleX-n1-rtl me-md-1"></span>
                                        <span className="d-none d-md-block">Login/Register</span>
                                    </Link>
                                </li>
                                {/* navbar button: End */}
                            </ul>
                            {/* Toolbar: End */}
                        </div>
                    </div>
                </nav>

                <div data-bs-spy="scroll" className="scrollspy-example">
                    <section id="hero-animation">
                        <div id="landingHero" className="section-py landing-hero position-relative">
                            <div className="container">
                                <div className="hero-text-box text-center">
                                    <h1 className="text-primary hero-title display-6 fw-bold">One dashboard to manage all your businesses</h1>
                                    <h2 className="hero-sub-title h6 mb-4 pb-1">
                                        Production-ready & easy to use Admin Template<br className="d-none d-lg-block" />
                                        for Reliability and Customizability.
                                    </h2>
                                    <div className="landing-hero-btn d-inline-block position-relative">
                                        <span className="hero-btn-item position-absolute d-none d-md-flex text-heading">
                                            Join community
                                            <img
                                                src={joinCommArrow}
                                                alt="Join community arrow"
                                                className="scaleX-n1-rtl"
                                            />
                                        </span>
                                        <a href="#landingPricing" className="btn btn-primary btn-lg">Get early access</a>
                                    </div>
                                </div>
                                <div id="heroDashboardAnimation" className="hero-animation-img">
                                    <a href="../vertical-menu-template/dashboards-ecommerce.html" target="_blank">
                                        <div id="heroAnimationImg" className="position-relative hero-dashboard-img">
                                            <img
                                                src={dashLight}
                                                alt="hero dashboard"
                                                className="animation-img"
                                                data-app-light-img="front-pages/landing-page/hero-dashboard-light.png"
                                                data-app-dark-img="front-pages/landing-page/hero-dashboard-dark.png"
                                            />
                                            <img
                                                src={heroElemLight}
                                                alt="hero elements"
                                                className="position-absolute hero-elements-img animation-img top-0 start-0"
                                                data-app-light-img="front-pages/landing-page/hero-elements-light.png"
                                                data-app-dark-img="front-pages/landing-page/hero-elements-dark.png"
                                            />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="landing-hero-blank"></div>
                    </section>
                </div>
                <footer className="landing-footer bg-body footer-text">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row gx-0 gy-4 g-md-5">
                                <div className="col-lg-5">
                                    <a href="landing-page.html" className="app-brand-link mb-4">
                                        <span className="app-brand-logo demo">
                                            <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                {/* Path elements go here */}
                                            </svg>
                                        </span>
                                        <span className="app-brand-text demo footer-link fw-bold ms-2 ps-1">Scoring</span>
                                    </a>
                                    <p className="footer-text footer-logo-description mb-4">
                                        Most developer friendly & highly customisable Admin Dashboard Template.
                                    </p>
                                    <form className="footer-form">
                                        <label htmlFor="footer-email" className="small">Subscribe to newsletter</label>
                                        <div className="d-flex mt-1">
                                            <input
                                                type="email"
                                                className="form-control rounded-0 rounded-start-bottom rounded-start-top"
                                                id="footer-email"
                                                placeholder="Your email"
                                            />
                                            <button
                                                type="submit"
                                                className="btn btn-primary shadow-none rounded-0 rounded-end-bottom rounded-end-top"
                                            >
                                                Subscribe
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                    <h6 className="footer-title mb-4">Demos</h6>
                                    <ul className="list-unstyled">
                                        <li className="mb-3">
                                            <a href="../vertical-menu-template/" target="_blank" className="footer-link">Vertical Layout</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="../horizontal-menu-template/" target="_blank" className="footer-link">Horizontal Layout</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="../vertical-menu-template-bordered/" target="_blank" className="footer-link">Bordered Layout</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="../vertical-menu-template-semi-dark/" target="_blank" className="footer-link">Semi Dark Layout</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="../vertical-menu-template-dark/" target="_blank" className="footer-link">Dark Layout</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6">
                                    <h6 className="footer-title mb-4">Pages</h6>
                                    <ul className="list-unstyled">
                                        <li className="mb-3">
                                            <a href="pricing-page.html" className="footer-link">Pricing</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="payment-page.html" className="footer-link">Payment<span className="badge rounded bg-primary ms-2">New</span></a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="checkout-page.html" className="footer-link">Checkout</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="help-center-landing.html" className="footer-link">Help Center</a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="../vertical-menu-template/auth-login-cover.html" target="_blank" className="footer-link">Login/Register</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <h6 className="footer-title mb-4">Download our app</h6>
                                    <a href="javascript:void(0);" className="d-block footer-link mb-3 pb-2">
                                        <img src="../../assets/img/front-pages/landing-page/apple-icon.png" alt="apple icon" />
                                    </a>
                                    <a href="javascript:void(0);" className="d-block footer-link">
                                        <img src="../../assets/img/front-pages/landing-page/google-play-icon.png" alt="google play icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom py-3">
                        <div className="container d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">
                            <div className="mb-2 mb-md-0">
                                <span className="footer-text">
                                    ©
                                    <script>{`document.write(new Date().getFullYear());`}</script>
                                </span>
                                <a href="https://pixinvent.com" target="_blank" className="fw-medium text-white footer-link">Pixinvent,</a>
                                <span className="footer-text"> Made with ❤️ for a better web.</span>
                            </div>
                            <div>
                                <a href="https://github.com/pixinvent" className="footer-link me-3" target="_blank">
                                    <img
                                        src="../../assets/img/front-pages/icons/github-light.png"
                                        alt="github icon"
                                        data-app-light-img="front-pages/icons/github-light.png"
                                        data-app-dark-img="front-pages/icons/github-dark.png"
                                    />
                                </a>
                                <a href="https://www.facebook.com/pixinvents/" className="footer-link me-3" target="_blank">
                                    <img
                                        src="../../assets/img/front-pages/icons/facebook-light.png"
                                        alt="facebook icon"
                                        data-app-light-img="front-pages/icons/facebook-light.png"
                                        data-app-dark-img="front-pages/icons/facebook-dark.png"
                                    />
                                </a>
                                <a href="https://twitter.com/pixinvents" className="footer-link me-3" target="_blank">
                                    <img
                                        src="../../assets/img/front-pages/icons/twitter-light.png"
                                        alt="twitter icon"
                                        data-app-light-img="front-pages/icons/twitter-light.png"
                                        data-app-dark-img="front-pages/icons/twitter-dark.png"
                                    />
                                </a>
                                <a href="https://www.instagram.com/pixinvents/" className="footer-link" target="_blank">
                                    <img
                                        src="../../assets/img/front-pages/icons/instagram-light.png"
                                        alt="google icon"
                                        data-app-light-img="front-pages/icons/instagram-light.png"
                                        data-app-dark-img="front-pages/icons/instagram-dark.png"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer> 
            </div>
        );
    }
}
