import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios"; // Import Axios
import Flag from "react-flags-select";
//import 'react-flags-select/css/react-flags-select.css';
import getCountryCode from "./i18n-iso-countries";
import ReactCountryFlag from "react-country-flag";
import CommonFunctions from "../common/functions";
import i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function CompanyList({
  companies,
  pageNumber,
  allowNext,
  totalRecords,
  selectedRanges,
  selectedEmployeeRanges,
  selectedEbitRanges,
  selectedEbitdaRanges,
  selectedRevGrowthRanges,
  countryFilter,
  setCountryFilter,
  firmaFilter,
  setFirmaFilter,
  selectedIndustries,
  setSelectedIndustries,
  setSelectedRanges,
  setSelectedEmployeeRanges,
  setSelectedEbitRanges,
  setSelectedEbitdaRanges,
  setSelectedRevGrowthRanges,
  totalRows,
}) {
  const [canBm, setCanBm] = useState(false);

  //permission manager
  useEffect(() => {
    setCanBm(CommonFunctions.checkPermission("can_bookmark", 0));
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const [sortedCompanies, setSortedCompanies] = useState([...companies]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("company");
  const [showSpinner, setShowSpinner] = useState("");
  const [isIndsTagVisible, setIsIndsTagVisible] = useState(true);
  const [isRangeTagVisible, setIsRangeTagVisible] = useState(true);
  const [isRevGrowthTagVisible, setIsRevGrowthTagVisible] = useState(true);
  const [isEbitTagVisible, setIsEbitTagVisible] = useState(true);
  const [isEbitdaTagVisible, setIsEbitdaTagVisible] = useState(true);
  const [isEmpTagVisible, setIsEmpTagVisible] = useState(true);
  const itemsPerPage = 100;
  const selectedInds = localStorage.getItem("selectedIndustriesData");

  const clearSelectedInds = () => {
    setIsIndsTagVisible(false);
    localStorage.removeItem("selectedIndustriesData");
    setSelectedIndustries("");
    setIsIndsTagVisible(true);
  };

  const clearSelectedRanges = () => {
    setIsRangeTagVisible(false);
    setSelectedRanges("");
    setIsRangeTagVisible(true);
  };

  const clearSelectedRevGrowth = () => {
    setIsRevGrowthTagVisible(false);
    setSelectedRevGrowthRanges("");
    setIsRevGrowthTagVisible(true);
  };

  const clearSelectedEbit = () => {
    setIsEbitTagVisible(false);
    setSelectedEbitRanges("");
    setIsEbitTagVisible(true);
  };

  const clearSelectedEbitda = () => {
    setIsEbitdaTagVisible(false);
    setSelectedEbitdaRanges("");
    setIsEbitdaTagVisible(true);
  };

  const clearSelectedEmp = () => {
    setIsEmpTagVisible(false);
    setSelectedEmployeeRanges("");
    setIsEmpTagVisible(true);
  };

  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [bookmarkedCompanies, setBookmarkedCompanies] = useState([]);

  // Maintain a state to track bookmarked status for each company
  const [companyBookmarks, setCompanyBookmarks] = useState({});
  const [tableRows, setTableRows] = useState();

  const handleTableRowsChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setTableRows(newValue);
    console.log(newValue); // Log the new selected value

    localStorage.setItem("rowsCount", newValue);
    window.location.href = "/companies";
  };

  //render the filders
  const renderRanges = (ranges, type = "") =>
    ranges.map((range, index) => (
      <React.Fragment key={index}>
        <i className="badge rounded-pill bg-label-secondary text-primary">
          {type === "industry" ? range.value : range}
        </i>{" "}
      </React.Fragment>
    ));

  useEffect(() => {
    // Update the sortedCompanies and companyBookmarks whenever the companies prop changes
    setSortedCompanies([...companies]);
    const bookmarkStatus = {};
    companies.forEach((company) => {
      bookmarkStatus[company.id] = isCompanyBookmarked(company.id);
    });
    setCompanyBookmarks(bookmarkStatus);
    console.log("Firma Filter: " + firmaFilter);
  }, [companies]);

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(0);
    }, 7000);

    console.log("Industries are: " + selectedInds);
    // Fetch bookmarked companies on page load
    fetchBookmarkedCompanies();

    // Sort the data when sortColumn or sortOrder changes
    const sorted = [...sortedCompanies].sort((a, b) => {
      const colA = getSortableValue(a, sortColumn);
      const colB = getSortableValue(b, sortColumn);

      // Handle undefined or null values
      if (
        colA === undefined ||
        colB === undefined ||
        colA === null ||
        colB === null
      ) {
        if (colA === colB) {
          return 0; // Values are equal
        }
        if (colA === undefined || colA === null) {
          return 1; // Place rows with undefined/null values at the end
        }
        return -1; // Place rows with undefined/null values at the beginning
      }

      // Check if values are strings before using localeCompare
      if (typeof colA === "string" && typeof colB === "string") {
        return sortOrder === "asc"
          ? colA.localeCompare(colB)
          : colB.localeCompare(colA);
      }

      // If values are not strings, fallback to numeric comparison
      return sortOrder === "asc" ? colA - colB : colB - colA;
    });
    setSortedCompanies(sorted);
  }, [sortColumn, sortOrder]);

  const handleNextPage = () => {
    const nPage = parseInt(pageNumber + 1);
    window.location.href = "/companies?p=" + nPage;
  };

  const handlePrevPage = () => {
    const pPage = parseInt(pageNumber - 1);
    window.location.href = "/companies?p=" + pPage;
  };

  function formatNumber(number) {
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] || "";

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return `${formattedInteger}${decimalPart ? `.${decimalPart}` : ""}`;
  }

  const handleSort = (column) => {
    // Define the columns where sorting is allowed
    const allowedColumns = [
      "country",
      "name_industry",
      "company",
      "websites",
      "tunover_level_filter",
      "nr_sal_an5",
    ];

    if (allowedColumns.includes(column)) {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  const renderSortingArrow = (column) => {
    if (column === sortColumn) {
      if (sortOrder === "asc") {
        return <span>&uarr;</span>;
      } else {
        return <span>&darr;</span>;
      }
    }
    return null;
  };

  // Function to get the sortable value for a column
  const getSortableValue = (item, column) => {
    const value = item.attributes[column];

    if (column === "nr_sal_an5") {
      // Parse numeric columns as numbers for correct sorting
      const parsedValue = parseFloat(value);

      // Check if the parsing is successful and the result is a valid number
      if (!isNaN(parsedValue)) {
        return parsedValue;
      } else {
        // If parsing fails, return the original value (not a number)
        return value;
      }
    }

    return value;
  };
  const fetchBookmarkedCompanies = () => {
    console.log("i am in fetch function");
    // Send a GET request to fetch bookmarked companies for the user
    axios
      .get(
        apiUrl + `/companies-bookmarks?filters[user_id][$eq]=${userData.id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        //console.log("BM Companies are: " + JSON.stringify(response.data.data));
        // Extract the company IDs from the response and set them in state
        const bookmarkedCompanyIds = response.data.data.map(
          //console.log("Company id is: " + bookmark.attributes.company_id.data.id),
          (bookmark) => bookmark.attributes.company_id
        );
        //console.log("BM Companies are: " + JSON.stringify(bookmarkedCompanyIds));
        setBookmarkedCompanies(bookmarkedCompanyIds);
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  const isCompanyBookmarked = (companyId) => {
    // Check if companyId exists in bookmarkedCompanies
    if (bookmarkedCompanies.includes(companyId)) {
      // If found, return true
      return true;
    }

    // If not found, try converting companyId to string
    const companyIdAsString = companyId.toString();

    // Check if the converted companyId exists in bookmarkedCompanies
    if (bookmarkedCompanies.includes(companyIdAsString)) {
      // If found after conversion, return true
      return true;
    }

    // If still not found, return false
    return false;
  };

  const toggleBookmark = (companyId) => {
    if (isCompanyBookmarked(companyId)) {
      console.log("unbookmarking...");
      axios
        .get(
          apiUrl +
            `/companies-bookmarks?filters[user_id][$eq]=${userData.id}&filters[company_id][$eq]=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0].id);
            unbookmarkCompany(response.data.data[0].id, companyId);
          }
        })
        .catch((error) => {
          // Handle error if needed
        });
    } else {
      console.log("bookmarking...");
      // Bookmark the company
      bookmarkCompany(companyId);
    }

    // Immediately update the bookmark status in state
    setCompanyBookmarks((prevBookmarks) => ({
      ...prevBookmarks,
      [companyId]: !prevBookmarks[companyId],
    }));
  };

  const bookmarkCompany = (companyId) => {
    // Send a POST request to bookmark the company
    axios
      .post(
        apiUrl + "/companies-bookmarks",
        {
          data: {
            user_id: userData.id,
            company_id: companyId,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Update the bookmarkedCompanies state
        setBookmarkedCompanies([...bookmarkedCompanies, companyId.toString()]);
        setNotify("Company Bookmarked!");
        setNotifyClass("success");

        // Hide the notification after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          setNotify(""); // Clear the notification message
          setNotifyClass(""); // Clear the notification class
        }, 2000);
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  const unbookmarkCompany = (bookmarkId, companyId) => {
    // Send a DELETE request to unbookmark the company
    axios
      .delete(apiUrl + `/companies-bookmarks/${bookmarkId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        // Update the bookmarkedCompanies state after a successful delete
        setBookmarkedCompanies(
          bookmarkedCompanies.filter((id) => id !== companyId.toString())
        );
        setNotify("Company Unbookmarked!");
        setNotifyClass("danger");

        // Hide the notification after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          setNotify(""); // Clear the notification message
          setNotifyClass(""); // Clear the notification class
        }, 2000);
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  // const updateUrl = (newPageNumber) => {
  //   history.push(`${location.pathname}?p=${newPageNumber}`);
  // };

  const isMobileScreen = window.innerWidth <= 768;
  const totalPages = Math.ceil(totalRecords / totalRows);
  const startPage = Math.max(
    1,
    Math.min(
      pageNumber - (isMobileScreen ? 1 : 4),
      totalPages - (isMobileScreen ? 3 : 9)
    )
  );
  const endPage = Math.min(
    totalPages,
    Math.max(isMobileScreen ? 3 : 10, pageNumber + (isMobileScreen ? 2 : 5))
  );
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  return (
    <div className="card">
      <h6 className="mb-0 mt-3" style={{ lineHeight: "31px" }}>
        {countryFilter != null &&
          countryFilter !== "null" &&
          countryFilter.trim() !== "" && (
            <>
              <strong>Country:</strong>{" "}
              <span>
                <i className="badge rounded-pill bg-label-secondary text-primary">
                  {countryFilter}{" "}
                  <i
                    className="ti ti-trash text-danger ms-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => setCountryFilter("")}
                  >
                    {" "}
                  </i>
                </i>
              </span>
            </>
          )}{" "}
        {firmaFilter != null &&
          firmaFilter !== "null" &&
          firmaFilter.trim() !== "" && (
            <React.Fragment>
              <strong> Company:</strong>{" "}
              <i className="badge rounded-pill bg-label-secondary text-primary">
                {firmaFilter}
                <i
                  className="ti ti-trash text-danger ms-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => setFirmaFilter("")}
                >
                  {" "}
                </i>
              </i>
            </React.Fragment>
          )}{" "}
        {isIndsTagVisible && selectedInds && (
          <React.Fragment>
            <strong> Industry:</strong>{" "}
            <span>
              <i className="badge rounded-pill bg-label-secondary text-primary">
                {selectedInds}
                <i
                  className="ti ti-trash text-danger ms-1"
                  style={{ cursor: "pointer" }}
                  onClick={clearSelectedInds} // Call the function here
                ></i>
              </i>
            </span>
          </React.Fragment>
        )}
        {isRangeTagVisible && selectedRanges.length > 0 ? (
          <React.Fragment>
            <strong> Revenue:</strong> {renderRanges(selectedRanges)}
            <i
              className="ti ti-trash text-danger ms-1"
              style={{ cursor: "pointer" }}
              onClick={clearSelectedRanges} // Call the function here
            ></i>
          </React.Fragment>
        ) : null}
        {isRevGrowthTagVisible && selectedRevGrowthRanges.length > 0 ? (
          <React.Fragment>
            <strong> Revenue Growth (%):</strong>{" "}
            {renderRanges(selectedRevGrowthRanges)}
            <i
              className="ti ti-trash text-danger ms-1"
              style={{ cursor: "pointer" }}
              onClick={clearSelectedRevGrowth} // Call the function here
            ></i>
          </React.Fragment>
        ) : null}
        {isEbitTagVisible && selectedEbitRanges.length > 0 ? (
          <React.Fragment>
            <strong> Ebit (%):</strong> {renderRanges(selectedEbitRanges)}
            <i
              className="ti ti-trash text-danger ms-1"
              style={{ cursor: "pointer" }}
              onClick={clearSelectedEbit} // Call the function here
            ></i>
          </React.Fragment>
        ) : null}
        {isEbitdaTagVisible && selectedEbitdaRanges.length > 0 ? (
          <React.Fragment>
            <strong> Ebitda (%):</strong> {renderRanges(selectedEbitdaRanges)}
            <i
              className="ti ti-trash text-danger ms-1"
              style={{ cursor: "pointer" }}
              onClick={clearSelectedEbitda} // Call the function here
            ></i>
          </React.Fragment>
        ) : null}
        {isEmpTagVisible && selectedEmployeeRanges.length > 0 ? (
          <React.Fragment>
            <strong> No. of Employess:</strong>{" "}
            {renderRanges(selectedEmployeeRanges)}{" "}
            <i
              className="ti ti-trash text-danger ms-1"
              style={{ cursor: "pointer" }}
              onClick={clearSelectedEmp} // Call the function here
            ></i>
          </React.Fragment>
        ) : null}
      </h6>
      <h5
        className="card-header"
        style={{ paddingLeft: "0px", paddingBottom: "10px" }}
      >
        {/* {showSpinner !== 0 ? (
          <span
            className="fas fa-spinner fa-spin text-danger"
            style={{ marginRight: "5px" }}
          ></span>
        ) : (
          ""
        )} */}
        Companies List (<i>{totalRecords}</i> found!)
        <small style={{ float: "right" }}>
          Showing{" "}
          <i>
            {(pageNumber - 1) * 100} -{" "}
            {pageNumber === 1 && totalRecords <= totalRows
              ? totalRecords
              : pageNumber * totalRows}{" "}
            of {totalRecords}
          </i>
        </small>
      </h5>

      {notify !== "" ? (
        <div
          className="bs-toast toast fade show toast-error"
          style={{
            position: "fixed",
            top: "100px",
            right: "0px",
            zIndex: "10",
          }}
        >
          <div className={`toast-body`}>
            {notifyClass == "success" ? (
              <i className="ti ti-checks text-success"></i>
            ) : (
              <i className="ti ti-square-rounded-x text-danger"></i>
            )}{" "}
            <strong>{notify}</strong>
          </div>
        </div>
      ) : null}
      <div className="text-nowrap company-table">
        <small className="text-danger">
          <i>Click on column name to sort the table</i>
        </small>
        <table className="table table-hover table-bordered table-striped mt-0">
          <thead>
            <tr>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("country")}
                style={{ width: "10%" }}
              >
                Country {renderSortingArrow("country")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("name_industry")}
                style={{ width: "20%" }}
              >
                Industry {renderSortingArrow("name_industry")}
              </th>
              <th onClick={() => handleSort("company")}>
                Company {renderSortingArrow("company")}
              </th>
              <th onClick={() => handleSort("websites")}>
                Website {renderSortingArrow("websites")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("tunover_level_filter")}
                style={{ width: "10%" }}
              >
                Revenue {renderSortingArrow("tunover_level_filter")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("nr_sal_an5")}
                style={{ width: "10%" }}
              >
                Employees {renderSortingArrow("nr_sal_an5")}
              </th>
              <th style={{ width: "10%" }}>
                <span className="hide-tab-mob">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {sortedCompanies
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((company) => (
                <tr key={company.id}>
                  <td className="hide-tab-mob">
                    <span className="fw-medium hide-tab-mob">
                      <ReactCountryFlag
                        countryCode={getCountryCode(
                          company.attributes && company.attributes.country
                        )}
                        svg
                        style={{
                          width: "20px",
                          marginRight: "5px",
                          border: "1px solid #ccc",
                        }}
                      />
                      <span>
                        {i18nIsoCountries.getAlpha2Code(
                          company.attributes && company.attributes.country,
                          "en"
                        )}
                        {/* {company.attributes && company.attributes.country} */}
                      </span>
                    </span>
                  </td>
                  <td className="hide-tab-mob">
                    {company.attributes &&
                    company.attributes.name_industry &&
                    company.attributes.name_industry.length > 25
                      ? company.attributes.name_industry.slice(0, 25) + "..."
                      : company.attributes.name_industry}
                  </td>
                  <td className="hide-tab-mob">
                    <div
                      style={{
                        width: "25px",
                        height: "25px",
                        display: "inline-block",
                        marginRight: "5px",
                        overflow: "hidden",
                        float: "left",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={
                          company.attributes.logo_url
                            ? company.attributes.logo_url.split(";")[0]
                            : ""
                        }
                      />{" "}
                    </div>
                    <div className="truncate-text" style={{
    float: 'left',
    lineHeight: '25px',
    maxWidth: 'calc(100% - 150px)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }}>
                      {company.attributes && company.attributes.company}
                    </div>
                  </td>
                  <td className="visible-tab-mob">
                    <i style={{ marginRight: "5px" }}>
                      <img src={company.attributes.logo_url} />
                    </i>
                    {company.attributes &&
                    company.attributes.company &&
                    company.attributes.company.length > 30
                      ? company.attributes.company.slice(0, 30) + "..."
                      : company.attributes.company}
                  </td>
                  <td className="hide-tab-mob truncate-text">
                    {company.attributes &&
                      company.attributes.websites &&
                      company.attributes.websites.split(";")[0]}
                  </td>
                  <td className="hide-tab-mob">
                    <span
                      className={(() => {
                        const filterValue =
                          company.attributes &&
                          company.attributes.tunover_level_filter;
                        switch (filterValue) {
                          case "< 100K EUR":
                            return "badge bg-label-info me-1";
                          case "100 - 500K EUR":
                            return "badge bg-label-danger me-1";
                          case "0.5 - 1 Mn. EUR":
                            return "badge bg-label-warning me-1";
                          case "1 - 5 Mn. EUR":
                            return "badge bg-label-dark me-1";
                          case "5 - 10 Mn. EUR":
                            return "badge bg-label-secondary me-1";
                          case "10 - 25 Mn. EUR":
                            return "badge bg-label-success me-1";
                          case "25 - 50 Mn. EUR":
                            return "badge bg-label-secondary me-1";
                          case "50 - 100 Mn. EUR":
                            return "badge bg-info me-1";
                          case "100 - 500 Mn. EUR":
                            return "badge bg-label-primary me-1";
                          case "> 500 Mn. EUR":
                            return "badge bg-label-success me-1";
                        }
                      })()}
                    >
                      {company.attributes &&
                        company.attributes.tunover_level_filter}
                    </span>
                  </td>{" "}
                  <td className="hide-tab-mob text-right">
                    <span>
                      {company.attributes &&
                        formatNumber(company.attributes.nr_sal_an5)}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex">
                      <Link
                        style={{ width: "25px", height: "25px" }}
                        to={`/companies/${(
                          company.attributes.company?.toLowerCase() || ""
                        ).replace(/ /g, "-")}/${company.id}`}
                        className="btn btn-purple text-primary btn-icon"
                        title="View"
                      >
                        <i className="ti ti-eye"></i>
                      </Link>
                      {!canBm ? (
                        ""
                      ) : (
                        <button
                          style={{
                            marginLeft: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          onClick={() => toggleBookmark(company.id)}
                          title={
                            isCompanyBookmarked(company.id)
                              ? "Unbookmark"
                              : "Bookmark"
                          }
                          className={`btn ${
                            companyBookmarks[company.id]
                              ? "btn-primary"
                              : "btn-purple"
                          } btn-icon ${
                            companyBookmarks[company.id]
                              ? "text-white"
                              : "text-primary"
                          }`}
                        >
                          <i className={`ti ti-bookmark`}></i>
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="row mb-3">
        {isMobileScreen ? (
          ""
        ) : (
          <div className="col-md-6 float-left">
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>
            </select>
          </div>
        )}
        <div
          className={
            isMobileScreen
              ? "text-center"
              : "col-md-6 float-right-pagination float-right"
          }
        >
          {isMobileScreen && (
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>{" "}
            </select>
          )}

          {pageNumber == 1 ? (
            ""
          ) : (
            <button className="btn btn-primary" onClick={handlePrevPage}>
              &laquo; {isMobileScreen ? "" : "Prev"}
            </button>
          )}

          {pageNumbers.map((page) => (
            <a
              style={{ fontSize: "13px", marginRight: "2px" }}
              key={page}
              className={`btn rounded-pill btn-icon btn-label-primary waves-effect ${
                page === pageNumber ? "active" : ""
              }`}
              href={`/companies?p=${page}`}
            >
              {page}
            </a>
          ))}

          {allowNext ? (
            <button
              style={{ marginLeft: "13px" }}
              className="btn btn-primary"
              onClick={handleNextPage}
            >
              {isMobileScreen ? "" : "Next"} &raquo;
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyList;
