import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import SiteLogo from "../images/logo.png";
import { Link } from "react-router-dom";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.callResetPassword = this.callResetPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      passWord: "",
      token: "",
      c1: Math.floor(Math.random() * 10),
      c2: Math.floor(Math.random() * 10),
      captchaAns: "",
      loginErr: "",
      sent: 0,
    };
  }

  componentDidMount() {
    var userToken = this.getQueryVariable('token');
    if(userToken != ''){
      this.setState({
        token: userToken
      });
    }
    else {
      this.setState({
        loginErr: "Token missing!"
      });
    }
  }

  getQueryVariable(token) {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] == token) { return pair[1]; }
    }
    return (false);
  }

  // onChangeCaptcha(e) {
  //   this.setState({
  //     captchaAns: e.target.value
  //   });
  // }

  onChangePassword(e) {
    this.setState({
      passWord: e.target.value
    });
  }

  // onChangeEmail(e) {
  //   this.setState({
  //     email: e.target.value
  //   });
  // }

  callResetPassword() {
    this.setState({
      sent: ""
    });

    if(this.state.passWord === ""){
      this.setState({
        loginErr: "Password required!"
      });
      return false;
    }

    var data = {
      token: this.state.token,
      passWord: this.state.passWord,
    };

    AffiliateDataService.reset(data.token, data.passWord)
      .then(response => {
        this.setState({
          loginErr: "",
          sent: 0
        });
        //console.log(response.data.payoutReqModel.amount); return false;
        if (response.data.success === true) {
          this.setState({
            sent: 1
          });
        }
        else {
          this.setState({
            loginErr: response.data.message
          });
        }
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { sent, loginErr } = this.state;
    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
              <div className="login-boxed">
                <div className="site-logo">
                  <img src={SiteLogo} alt="logo" className="img-fluid" />
                </div>
                <div className="login-box">
                  <div>
                    <h5>Reset Your Password</h5><hr/>
                    {sent === 1 ? (<h6 className="alert alert-success">Updated Successfully! <small>Check email inbox!</small></h6>) : ""}
                    {loginErr !== "" ? <h5 className="text text-danger">{loginErr}</h5> : ""}
                  </div>
                  {sent === 0 ? 
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="passWord"
                      required
                      value={this.state.passWord}
                      onChange={this.onChangePassword}
                      name="passWord"
                    />
                  </div>
                  : ''}
                  {/* <div>
                    <label>Captcha</label>
                    <div className="border border-primary bg bg-dark text text-warning p-1 mb-1">
                      <strong>{c1}</strong> + <strong>{c2}</strong>
                    </div>
                    <input
                      type="text"
                      maxLength={2}
                      className="form-control"
                      id="captchaAns"
                      required
                      value={captchaAns}
                      onChange={this.onChangeCaptcha}
                      name="captchaAns"
                    />
                  </div> */}
                  {sent === 0 ? 
                  <input onClick={this.callResetPassword} type="submit" name="login" value="Submit" />
                  : "" }
                  <div className="su-div">
                    <p><Link to="/signin">Login</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
