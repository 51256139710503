import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const TurnOverChart = ({ company }) => { 

  const valOne = (company.ca_an1 / 1000000);
  const fValOne = Math.round(valOne * 10) / 10;

  const valTwo = (company.ca_an2 / 1000000);
  const fValTwo = Math.round(valTwo * 10) / 10;

  const valThree = (company.ca_an3 / 1000000);
  const fValThree = Math.round(valThree * 10) / 10;

  const valFour = (company.ca_an4 / 1000000);
  const fValFour = Math.round(valFour * 10) / 10;

  const valFive = (company.ca_an5 / 1000000);
  const fValFive = Math.round(valFive * 10) / 10;

  
  const chartOptions = {
    chart: {
        type: 'column',
        height: 200,
    },
    legend: {
        enabled: false, // Hide the legends
      },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span> Turnover: <b>{point.y} Mn. EUR</b><br/>',
    },
    xAxis: {
      categories: ['2018', '2019', '2020', '2021', '2022'],
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: [
      {
        data: [fValOne, fValTwo, fValThree, fValFour, fValFive],
        color: 'red',
      },
     ],
  };
  
  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">Turnover (Mn. EUR)</h5>
          </div>
        </div>
        <div className="card-body pt-2">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}

export default TurnOverChart;
