import React, { useState, useEffect } from "react";
import axios from "axios";
import AffiliateDataService from "../../services/affiliate.service";

const Bookmark = ({ companyId }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState("");
  const [userId, setUserId] = useState("");

  const CompanyBookmarked = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("data " + JSON.stringify(res.data));
        if (res.data.data.length === 0) {
          console.log("Not bookmarked");
          setIsBookmarked(0);
        } else {
          console.log("Bookmarked");
          setIsBookmarked(1);
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  // Check if the company is bookmarked when the component mounts
  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserId(userData.id);
    CompanyBookmarked(
      userToken,
      apiUrl +
        "/companies-bookmarks?populate=*&filters[user_id][$eq]=" +
        userData.id +
        "&filters[company_id][$eq]=" +
        companyId +
        "&_limit=1"
    );
  }, [companyId]);

  const handleBookmarkToggle = async () => {
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      if (!isBookmarked) {
        // If not bookmarked, add to bookmarks
        const userToken = localStorage.getItem("userToken");
        const apiUrl = AffiliateDataService.baseURL;
        axios
          .post(
            apiUrl + "/companies-bookmarks",
            {
              data: {
                user_id: userId,
                company_id: companyId,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Handle success.
            console.log(JSON.stringify(response));
            setIsBookmarked(1);
            setBookmarkId(response.data.data.id);
          })
          .catch((error) => {
            console.log(
              "An error occurred:",
              error.response.data.error.message
            );
          });
      } else {
        axios
          .get(
            apiUrl +
              `/companies-bookmarks?filters[user_id][$eq]=${userData.id}&filters[company_id][$eq]=${companyId}`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.data.length > 0) {
              console.log(response.data.data[0].id);
              axios.delete(apiUrl + `/companies-bookmarks/${response.data.data[0].id}`, {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                  "Content-Type": "application/json",
                },
              });
              setIsBookmarked(0);
              setBookmarkId("");
            }
          })
          .catch((error) => {
            // Handle error if needed
          });

        // const userToken = localStorage.getItem("userToken");
        // const apiUrl = AffiliateDataService.baseURL;
      }
    } catch (error) {
      console.error("Error toggling bookmark:", error);
    }
  };

  return (
    <button
      onClick={handleBookmarkToggle}
      className={`btn ${isBookmarked ? "btn-primary" : "btn-info"}`}
    >
      <i className="ti ti-bookmark"></i>{" "}
      {isBookmarked ? "Unbookmark" : "Bookmark"}
    </button>
  );
};

export default Bookmark;
