import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios"; // Import Axios
import getCountryCode from "./i18n-iso-countries";
import ReactCountryFlag from "react-country-flag";
import i18nIsoCountries from 'i18n-iso-countries';
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));


function BookmarkDomainsList({
  domainsBookmarks,
  pageNumber,
  allowNext,
  totalRecords,
  totalRows
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("domain");
  const itemsPerPage = 100;
  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [domainsDetails, setDomainsDetails] = useState([]);
  const [tableRows, setTableRows] = useState(() => parseInt(localStorage.getItem("rowsCount"), 10) || 20);
  const handleTableRowsChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setTableRows(newValue);
    //console.log(newValue); // Log the new selected value

    localStorage.setItem("rowsCount", newValue);
    window.location.href = "/bookmarks?t=domains";
  };

  const [bookmarkedDomains, setBookmarkedDomains] = useState([]);

  const handleNextPage = () => {
    const nPage = parseInt(pageNumber + 1);
    window.location.href = "/bookmarks?t=domains&p=" + nPage;
  };

  const handlePrevPage = () => {
    const pPage = parseInt(pageNumber - 1);
    window.location.href = "/bookmarks?t=domains&p=" + pPage;
  };

  const handleSort = (column) => {
    // Define the columns where sorting is allowed
    const allowedColumns = ["web", "category", "title", "country", "size", "backlinks"];
  
    if (allowedColumns.includes(column)) {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
  
      // Sort the data array based on sortColumn and sortOrder
      const sortedDomainsDetails = [...domainsDetails].sort((a, b) => {
        const aValue = a.attributes && a.attributes[column];
        const bValue = b.attributes && b.attributes[column];
  
        // Convert values to lowercase for case-insensitive sorting
        const aLower = typeof aValue === "string" ? aValue.toLowerCase() : aValue;
        const bLower = typeof bValue === "string" ? bValue.toLowerCase() : bValue;
  
        if (sortOrder === "asc") {
          return aLower > bLower ? 1 : -1;
        } else {
          return aLower < bLower ? 1 : -1;
        }
      });
  
      // Update the state with sorted data
      setDomainsDetails(sortedDomainsDetails);
    }
  };
  
  const handleDeleteDomainBookmark = (domainId) => {
    // You can optionally display a confirmation dialog here
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this domain bookmark?"
    );

    if (confirmDelete) {
      // Send a DELETE request to your API
      axios.delete(`${apiUrl}/domain-bookmarks/${domainId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const updatedDomains = bookmarkedDomains.filter(
        (domain) => domain.id !== domainId
      );
      setBookmarkedDomains(updatedDomains);
      // Optionally, you can display a success message
      setNotify("Domain bookmark deleted successfully.");
      setNotifyClass("success");

      setTimeout(() => {
        window.location.href = "/bookmarks?t=domains&p=1";
      }, 1000);
    }
  };

  const renderSortingArrow = (column) => {
    if (column === sortColumn) {
      if (sortOrder === "asc") {
        return <span>&uarr;</span>;
      } else {
        return <span>&darr;</span>;
      }
    }
    return null;
  };

  function properDate(dateVal) {
    // Create a Date object from the date string
    const date = new Date(dateVal);

    // Format the date and time
    return date.toLocaleDateString(); // Format as date
  }

  // Assuming you have a function to make the API call to get company details
  const getDomainDetails = async (domainId) => {
    try {
      const response = await axios.get(`${apiUrl}/domains/${domainId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`, // Include your actual authentication token here
        },
      });
      //console.log("CD is: " + JSON.stringify(response.data));

      return response.data.data;
    } catch (error) {
      console.error("Error fetching domain details:", error);
      return null;
    }
  };

  const renderDomainDetails = async () => {
    const domainsDetailsPromises = domainsBookmarks
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map(async (domain) => {
        const domainDetails = await getDomainDetails(
          domain.attributes.domain_id
        );
        return (
          <tr key={domain.id}>
                   <td>
                    <span className="fw-medium ">
                      <i style={{marginRight:"5px"}}><img style={{width:"25px", height:"25px"}} src={domainDetails.attributes.favicon ? domainDetails.attributes.favicon.split(';')[0] : ''}/></i>
                      {domainDetails.attributes && domainDetails.attributes.web}
                    </span>
                  </td>
            <td className="hide-tab-mob truncate-text">
              {domainDetails.attributes &&
              domainDetails.attributes.category}
            </td>
            <td className="hide-tab-mob truncate-text">
              {domainDetails.attributes &&
              domainDetails.attributes.title}
            </td>
            <td className="hide-tab-mob">
              <ReactCountryFlag
                countryCode={getCountryCode(
                  domainDetails.attributes && domainDetails.attributes.country
                )}
                svg
                style={{ width: "20px", marginRight: "5px" }}
              />
              <span className="me-1 ">
              {i18nIsoCountries.getAlpha2Code(domainDetails.attributes && domainDetails.attributes.country, 'en')}
              </span>
            </td>
            <td className="hide-tab-mob">
              {domainDetails.attributes &&
              domainDetails.attributes.crawled_pages === "Giant Website" ? (
                <span className="badge bg-label-success me-1 ">
                  {domainDetails.attributes &&
                    domainDetails.attributes.crawled_pages}
                </span>
              ) : (
                ""
              )}
              {domainDetails.attributes &&
              domainDetails.attributes.crawled_pages ===
                "Very Large Websites" ? (
                <span className="badge bg-label-primary me-1 ">
                  {domainDetails.attributes &&
                    domainDetails.attributes.crawled_pages}
                </span>
              ) : (
                ""
              )}
              {domainDetails.attributes &&
              domainDetails.attributes.crawled_pages === "Large Websites" ? (
                <span className="badge bg-label-info me-1 ">
                  {domainDetails.attributes &&
                    domainDetails.attributes.crawled_pages}
                </span>
              ) : (
                ""
              )}
              {domainDetails.attributes &&
              domainDetails.attributes.crawled_pages === "Medium Websites" ? (
                <span className="badge bg-label-warning me-1 ">
                  {domainDetails.attributes &&
                    domainDetails.attributes.crawled_pages}
                </span>
              ) : (
                ""
              )}
              {domainDetails.attributes &&
              domainDetails.attributes.crawled_pages === "Small Websites" ? (
                <span className="badge bg-label-danger me-1 ">
                  {domainDetails.attributes &&
                    domainDetails.attributes.crawled_pages}
                </span>
              ) : (
                ""
              )}
            </td>
            <td className="hide-tab-mob text-right">
              <span className="me-1 text-right">
                {domainDetails.attributes && domainDetails.attributes.backlinks}
              </span>
            </td>
            <td>
              <div className="d-flex">
                <Link
                  to={`/domains/${domainDetails.id}`}
                  className=""
                  title="View"
                >
                  <i className="ti ti-eye"></i>
                </Link>
                <button
                  style={{
                    marginLeft: "10px",
                    width: "25px",
                    height: "25px",
                  }}
                  className={`btn btn-icon`}
                >
                  <i
                    onClick={() => handleDeleteDomainBookmark(domain.id)}
                    style={{ cursor: "pointer" }}
                    className="text-danger ti ti-trash"
                  ></i>
                </button>
              </div>
            </td>
          </tr>
        );
      });

    const resolvedDomainsDetails = await Promise.all(domainsDetailsPromises);
    setDomainsDetails(resolvedDomainsDetails);
  };

  // Use useEffect to trigger the rendering when the component mounts or when the relevant dependencies change
  useEffect(() => {
    renderDomainDetails();
  }, [currentPage, itemsPerPage, domainsBookmarks]);

  const isMobileScreen = window.innerWidth <= 768;
  const totalPages = Math.ceil(totalRecords / totalRows);
  const startPage = Math.max(1, Math.min(pageNumber - (isMobileScreen ? 1 : 4), totalPages - (isMobileScreen ? 3 : 9)));
  const endPage = Math.min(
    totalPages,
    Math.max(isMobileScreen ? 3 : 10, pageNumber + (isMobileScreen ? 2 : 5))
  );
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  return (
    <div>
      <h5 style={{ paddingLeft: "5px" }}>
        Bookmarked Domains List (<i>{totalRecords}</i> found!)
      </h5>
      {notify !== "" ? (
        <div
          className={`w-50 alert alert-${notifyClass}`}
          style={{ position: "fixed", top: "80px", right: "0px", zIndex: "10" }}
        >
          {notify}
        </div>
      ) : null}
      <div className="text-nowrap company-table">
      <small className="text-danger"><i>Click on column name to sort the table</i></small>
        <table className="table table-hover table-bordered table-striped">
          <thead>
            <tr>
              <th
                className=""
                onClick={() => handleSort("web")}
                style={{ width: "10%" }}
              >
                Domain {renderSortingArrow("web")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("category")}
              >
                Category {renderSortingArrow("category")}
              </th>
              <th className="hide-tab-mob" onClick={() => handleSort("title")}>
                Title {renderSortingArrow("title")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("country")}
                style={{ width: "10%" }}
              >
                Country {renderSortingArrow("country")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("size")}
                style={{ width: "10%" }}
              >
                Size {renderSortingArrow("size")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("backlinks")}
                style={{ width: "10%" }}
              >
                Backlinks {renderSortingArrow("backlinks")}
              </th>
              <th style={{ width: "10%" }}><span className="hide-tab-mob">Actions</span></th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {domainsDetails && domainsDetails.length > 0 ? (
              domainsDetails
            ) : (""
            )}
          </tbody>
        </table>
      </div>
      <div className="row mb-3">
        {isMobileScreen ? (
          ""
        ) : (
          <div className="col-md-6 float-left">
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
              }}
            >
              <option value={20} defaultValue={totalRows === 20}>
                20
              </option>
              <option value={50} defaultValue={totalRows === 50}>
                50
              </option>
              <option value={75} defaultValue={totalRows === 75}>
                75
              </option>
              <option value={100} defaultValue={totalRows === 100}>
                100
              </option>
            </select>
          </div>
        )}
        <div className={isMobileScreen ? "text-center" : "col-md-6 float-right-pagination float-right"}>
          {isMobileScreen && (
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
                marginRight: "15px"
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>{" "}
            </select>
          )}

          {pageNumber == 1 ? (
            ""
          ) : (
            <button className="btn btn-primary" onClick={handlePrevPage}>
              &laquo; {isMobileScreen ? "" : "Prev"}
            </button>
          )}

          {pageNumbers.map((page) => (
            <a
              style={{ fontSize: "13px", marginRight: "2px" }}
              key={page}
              className={`btn rounded-pill btn-icon btn-label-primary waves-effect ${
                page === pageNumber ? "active" : ""
              }`}
              href={`/bookmarks?t=domains&p=${page}`}
            >
              {page}
            </a>
          ))}

          {allowNext ? (
            <button
              style={{ marginLeft: "13px" }}
              className="btn btn-primary"
              onClick={handleNextPage}
            >
              {isMobileScreen ? "" : "Next"} &raquo;
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default BookmarkDomainsList;
