import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";

export default class AffiliatePayoutRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      affCode: localStorage.getItem('userAffiliateCode'),
      payoutRequests: [],
      totalReqs: 0,
    };
  }

  componentDidMount() {
    this.retrieveRequests();
  }

  retrieveRequests() {
    AffiliateDataService.getAllPayoutRequests(this.state.affCode)
      .then(response => {
        if (response.data.success === true) {
          console.log("Total Reqs: " + response.data.totalReqs);
          this.setState({
            payoutRequests: response.data.payoutRequests,
            totalReqs: response.data.totalReqs,
          });
        }
        console.log("Requests: " + response.data.payoutRequests.length);
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { payoutRequests, totalReqs} = this.state;
    return (
      <div>
        <h2>All Payout Requests <strong className="float-right badge badge-warning"><i>{totalReqs} Payout Requests</i></strong></h2>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Requested On</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Payment Method</th>
              </tr>
            </thead>
            <tbody>
              {payoutRequests &&
                payoutRequests.map((pReq, index) => (
                  <tr>
                    <td>{pReq.requested_on}</td>
                    <td>${pReq.amount}</td>
                    <td>{pReq.status}</td>
                    <td>{pReq.method}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
