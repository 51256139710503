import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';


import SiteLogin from "./components/login";
import ForgotPassword from "./components/forgot";
import ResetPassword from "./components/reset";
import SiteLogout from "./components/logout";
import SiteRegister from "./components/register";
import SiteRegisterPro from "./components/register_pro";
import AffiliateDashboard from "./components/dashboard";
import AffiliateLanding from "./components/landing";
import CompaniesHolder from "./components/companies";
import PricingPlans from "./components/plans";
import SiteForgotPassword from "./components/forgot-password";
import SiteResetPassword from "./components/reset-password";
import DomainsHolder from "./components/domains";
import BookmarksHolder from "./components/bookmarks";
import EmailListHolder from "./components/email-lists";
import EmailListManagement from "./components/user-email-lists";
import UserProfile from "./components/profile";

class App extends Component {
  render() {
    return (
    <Routes>
      <Route path="/" element={<AffiliateLanding/>} />
      <Route path="/plans" element={<PricingPlans/>} />
      <Route path="/profile" element={<UserProfile/>} />
      <Route path="/companies" element={<CompaniesHolder/>} />
      <Route path="/bookmarks" element={<BookmarksHolder/>} />
      <Route path="/companies/:name/:id" element={<CompaniesHolder/>} />
      <Route path="/domains" element={<DomainsHolder/>} />
      <Route path="/domains/:id" element={<DomainsHolder/>} />
      <Route path="/signin" element={<SiteLogin/>} />
      <Route path="/reset-password" element={<SiteResetPassword/>} />
      <Route path="/forgot-password" element={<SiteForgotPassword/>} />
      <Route path="/register" element={<SiteRegisterPro/>} />
      <Route path="/sign-up" element={<SiteRegister/>} />
      <Route path="/dashboard" element={<AffiliateDashboard/>} />
      <Route path="/logout" element={<SiteLogout/>} />
      <Route path="/email-list/:id" element={<EmailListHolder/>} />
      <Route path="/user-email-lists" element={<EmailListManagement/>} />
    </Routes>
);
  }
}

export default App;
