import React, { useState, useEffect } from "react";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios";
import { Link } from "react-router-dom";

function TopNavBar({ toggleMenu, myCredits, isMenuOpen, authenticated, appendClicked }) {
  const [creditsBalance, setCreditsBalance] = useState(
    localStorage.getItem("userCredits")
  );
  const [userAvatarUrl, setUserAvatarUrl] = useState("");
  const [userProfileData, setUserProfileData] = useState([]);
  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const queryParams = getQueryParams(window.location.href);
  const pageName = queryParams.p ? parseInt(queryParams.p) : 1;

  useEffect(() => {
    if (localStorage.getItem("authenticated") !== "true") {
      window.location.href = "/signin";
      return false;
    }

    if (authenticated || localStorage.getItem("authenticated")) {
      const apiUrl = AffiliateDataService.baseURL;
      const userToken = localStorage.getItem("userToken");
      const userData = localStorage.getItem("userData");
      const userDataJSON = JSON.parse(userData);
      setUserProfileData(userDataJSON);

      axios
        .get(`${apiUrl}/users/${userDataJSON.id}?populate=*`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const newAvatarUrl =
            "https://bck.scoring.ro" + response.data.avatar.url;
          //console.log("New avatar: " + newAvatarUrl);
          setUserAvatarUrl(newAvatarUrl);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [authenticated]);

  return (
    <nav
      className={`layout-navbar navbar navbar-expand-xl navbar-detached bg-navbar-theme ${
        isMenuOpen ? "menu-open" : "menu-not-open"
      }`}
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a
          onClick={toggleMenu}
          href="#"
          className="click-class nav-item nav-link px-0 me-xl-4"
        >
          <i className="ti ti-menu-2 ti-sm"></i>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center"></div>
        {authenticated || localStorage.getItem("authenticated") ? (
          <div className="navbar-collapse" id="navbarNav">
            <ul className="navbar-nav" style={{ zIndex: "1", width: "100%" }}>
              <li
                className="nav-item active hide-tab-mob"
                style={{ width: "10%" }}
              >
                <Link className="nav-link" to="/companies">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li
                className="nav-item dropdown hide-tab-mob"
                style={{ width: "10%" }}
              ></li>
              <li
                style={{ width: "70%", textAlign: "right", marginTop: "10px" }}
              >
                <strong>{appendClicked ? myCredits : creditsBalance } </strong>&nbsp;<i>credits left!</i>
              </li>
              <li
                className="hide-tab-mob nav-item navbar-dropdown dropdown-user dropdown"
                style={{ float: "right", width: "10%", textAlign: "right" }}
              >
                <a
                  className="nav-link dropdown-toggle hide-arrow"
                  href=""
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ float: "right" }}
                >
                  <div className="avatar avatar-online">
                    <img
                      src={userAvatarUrl}
                      alt=""
                      className="h-auto rounded-circle"
                    />
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      className="dropdown-item"
                      href="pages-account-settings-account.html"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar avatar-online">
                            <img
                              src={userAvatarUrl}
                              alt=""
                              className="h-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          {authenticated ||
                            (localStorage.getItem("authenticated") &&
                              userProfileData &&
                              userProfileData.username && (
                                <span className="fw-medium d-block">
                                  {userProfileData.username.charAt(0).toUpperCase() + userProfileData.username.slice(1)}
                                </span>
                              ))}{" "}
                          <small className="text-muted">User</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      <i className="ti ti-user-check me-2 ti-sm"></i>
                      <span className="align-middle">My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/bookmarks">
                      <i className="ti ti-settings me-2 ti-sm"></i>
                      <span className="align-middle">My Bookmarks</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/user-email-lists">
                      <i className="ti ti-settings me-2 ti-sm"></i>
                      <span className="align-middle">My Email Lists</span>
                    </Link>
                  </li>

                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/logout">
                      <i className="ti ti-logout me-2 ti-sm"></i>
                      <span className="align-middle">Log Out</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
}

export default TopNavBar;
