import React, { useState, useEffect } from "react";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios"; // Import Axios

function UserEmailsListTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedEmailLists, setSortedEmailLists] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("domain");
  const itemsPerPage = 100;
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [bookmarkedDomains, setBookmarkedDomains] = useState([]);
  const [listTitle, setListTitle] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedListTitle, setEditedListTitle] = useState("");
  const [editedListId, setEditedListId] = useState("");
  const [emailsListCount, setEmailsListCount] = useState([]);
  const [emailsList, setEmailsList] = useState([]);
  const [pageNumber, setPageNumber] = useState("");
  const [allowNext, setAllowNext] = useState("");

  const fetchEmailsList = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          console.log("No Companies Bookmarks found!");
        } else {
          setEmailsListCount(res.data.meta.pagination.total);
          setEmailsList(res.data.data);
          //console.log(JSON.stringify(res.data.data));
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  const fetchEmailsListNextPage = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("next Page results " + res.data.length);
        if (res.data.data.length > 0) {
          //console.log("Next page data : 1");
          setAllowNext(1);
        } else {
          //console.log("Next page data : 0");
          setAllowNext(0);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  useEffect(() => {
    // Update the sortedDomains whenever the userEmailLists prop changes
    setSortedEmailLists([...emailsList]);

    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = localStorage.getItem("userData");
    const userDataJSON = JSON.parse(userData);

    //get page number
    const queryParams = getQueryParams(window.location.href);
    const pn = queryParams.p ? parseInt(queryParams.p) : 1;
    setPageNumber(pn);

    fetchEmailsList(
      userToken,
      apiUrl +
        "/user-email-lists?filters[user_id][$eq]=" +
        userDataJSON.id +
        "&pagination[page]=" +
        pn +
        "&pagination[pageSize]=50"
    );

    fetchEmailsListNextPage(
      userToken,
      apiUrl +
        "/user-email-lists?filters[user][id][$eq]=" +
        userDataJSON.id +
        "&pagination[page]=" +
        parseInt(pn + 1) +
        "&pagination[pageSize]=50"
    );

  }, [emailsList]);

  useEffect(() => {
    // Sort the data when sortColumn or sortOrder changes
    const sorted = [...sortedEmailLists].sort((a, b) => {
      const colA = getSortableValue(a, sortColumn);
      const colB = getSortableValue(b, sortColumn);

      if (typeof colA === "number" && typeof colB === "number") {
        // If both values are numbers, compare them as numbers
        return sortOrder === "asc" ? colA - colB : colB - colA;
      } else {
        // If either value is not a number, compare them as strings
        if (sortOrder === "asc") {
          return colA.toString().localeCompare(colB.toString());
        } else {
          return colB.toString().localeCompare(colA.toString());
        }
      }
    });
    setSortedEmailLists(sorted);
  }, [sortColumn, sortOrder]);

  const handleNextPage = () => {
    const nPage = parseInt(pageNumber + 1);
    window.location.href = "/user-email-lists?p=" + nPage;
  };

  const handlePrevPage = () => {
    const pPage = parseInt(pageNumber - 1);
    window.location.href = "/user-email-lists?p=" + pPage;
  };

  const handleSort = (column) => {
    // Define the columns where sorting is allowed
    const allowedColumns = ["list_title"];

    if (allowedColumns.includes(column)) {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  const renderSortingArrow = (column) => {
    if (column === sortColumn) {
      if (sortOrder === "asc") {
        return <span>&uarr;</span>;
      } else {
        return <span>&darr;</span>;
      }
    }
    return null;
  };

  // Function to get the sortable value for a column
  const getSortableValue = (item, column) => {
    if (item && item.attributes && item.attributes[column] !== undefined) {
      const columnValue = item.attributes[column];
      return columnValue.toString();
    }

    // Handle the case where item.attributes or item.attributes[column] is undefined
    return "";
  };

  const handleDeleteEmailList = (listId) => {
    // You can optionally display a confirmation dialog here
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this email list?"
    );

    if (confirmDelete) {
      // Send a DELETE request to your API
      axios.delete(`${apiUrl}/user-email-lists/${listId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // Optionally, you can display a success message
      setNotify("Email list deleted successfully.");
      setNotifyClass("success");

      //get page number
      const queryParams = getQueryParams(window.location.href);
      const pn = queryParams.p ? parseInt(queryParams.p) : 1;

      setTimeout(() => {
        if (pn == 1) {
          window.location.href = "/user-email-lists";
        } else {
          window.location.href = "/user-email-lists?p=" + pn;
        }
      }, 1000);
    }
  };

  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send a POST request to create a new email list
    axios
      .post(
        apiUrl + "/user-email-lists",
        {
          data: {
            list_title: listTitle,
            user_id: userData.id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the successful creation of the email list here if needed
        //console.log("Email list created:", response.data);
        setNotify("Email List created successfully!");
        setNotifyClass("success");
        // Clear the form and hide it
        setListTitle("");
        setIsFormVisible(false);

        setTimeout(() => {
          window.location.href = "/user-email-lists";
        }, 1000);
      })
      .catch((error) => {
        setNotify("Error creating email list:" + error);
        setNotifyClass("danger");
        console.error("Error creating email list:", error);
      });
  };

  const handleEditList = (listId, listTitle) => {
    // Set the edit mode to true and populate the editedListTitle state
    setIsEditing(true);
    setEditedListTitle(listTitle);
    setEditedListId(listId);
  };

  const handleSaveEdit = () => {
    // Send a PUT request to update the list title
    //const listId = /* Get the list ID of the currently edited list */;

    axios
      .put(
        `${apiUrl}/user-email-lists/${editedListId}`,
        {
          data: {
            list_title: editedListTitle,
            user_id: userData.id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the successful update of the list here if needed
        //console.log("Email list updated:", response.data);
        setNotify("Email List updated successfully!");
        setNotifyClass("success");
        // Clear the editedListTitle and exit edit mode
        setEditedListTitle("");
        setEditedListId("");
        setIsEditing(false);

        setTimeout(() => {
          window.location.href = "/user-email-lists";
        }, 1000);
      })
      .catch((error) => {
        setNotify("Error updating email list:" + error);
        setNotifyClass("danger");
        console.error("Error updating email list:", error);
      });
  };
  const isMobileScreen = window.innerWidth <= 768;

  return (
    <div className="p-0">
      {isFormVisible || isEditing ? (
        <div className="card mb-4">
          <div className="card-body">
            {isFormVisible ? (
              <form onSubmit={handleSubmit}>
                <h4>Add New List</h4>
                <div className="form-group mb-4">
                  <label htmlFor="listTitle">List Title</label>
                  <input
                    type="text"
                    id="listTitle"
                    className="form-control w-50"
                    value={listTitle}
                    onChange={(e) => setListTitle(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-sm btn-primary">
                  Save
                </button>
              </form>
            ) : (
              ""
            )}

            {isEditing ? (
              // Edit Form
              <div>
                <h4>Edit List</h4>
                <div className="form-group mb-4">
                  <label htmlFor="editedListTitle">List Title</label>
                  <input
                    type="text"
                    id="editedListTitle"
                    className="form-control w-50"
                    value={editedListTitle}
                    onChange={(e) => setEditedListTitle(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={() => handleSaveEdit(true)}
                >
                  Save
                </button>
                &nbsp;
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    setEditedListTitle("");
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="card">
        <div className="container">
          <h5 className="card-header pl-0">
            My Email Lists (<i>{emailsListCount}</i> found!)
            {isFormVisible ? (
              ""
            ) : (
              <button
                style={{ float: "right" }}
                className="btn btn-sm btn-primary mb-4"
                onClick={() => setIsFormVisible(true)}
                title="Add new email list"
              >
                {isMobileScreen ? " + " : "Add New List"}
              </button>
            )}
          </h5>
          {notify !== "" ? (
            // Notification
            <div
              className={`w-50 alert alert-${notifyClass}`}
              style={{
                position: "fixed",
                top: "80px",
                right: "0px",
                zIndex: "10",
              }}
            >
              {notify}
            </div>
          ) : null}
          <div className="text-nowrap company-table container">
            <table className="table table-borderless table-hover table-striped p-2">
              {/* <thead>
                <tr>
                  <th className="" onClick={() => handleSort("list_title")}>
                    List Title {renderSortingArrow("list_title")}
                  </th>
                  <th></th>
                </tr>
              </thead> */}
              <tbody>
                {sortedEmailLists
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((list) => (
                    <tr key={list.id}>
                      <td className="">
                        <span className="fw-medium">
                          {list.attributes && list.attributes.list_title}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex">
                          <a
                            href={`/email-list/${list.id}`} // Update with your actual URL
                            className="waves-effect"
                          >
                          <i
                            style={{ cursor: "pointer" }}
                            className="text-primary ti ti-eye"
                          ></i>
                          </a>
                          <i
                            onClick={() =>
                              handleEditList(
                                list.id,
                                list.attributes.list_title
                              )
                            }
                            style={{ cursor: "pointer" }}
                            className="text-primary ti ti-pencil"
                          ></i>
                          <i
                            onClick={() => handleDeleteEmailList(list.id)}
                            style={{ cursor: "pointer" }}
                            className="text-danger ti ti-trash"
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="float-right-pagination">
            {pageNumber == 1 ? (
              ""
            ) : (
              <button className="btn btn-primary" onClick={handlePrevPage}>
                &laquo; Prev
              </button>
            )}
            {allowNext ? (
              <button className="btn btn-primary" onClick={handleNextPage}>
                Next &raquo;
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEmailsListTable;
