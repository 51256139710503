import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios"; // Import Axios
import getCountryCode from "./i18n-iso-countries";
import ReactCountryFlag from "react-country-flag";
import i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function BookmarkCompaniesList({
  companiesBookmarks,
  pageNumber,
  allowNext,
  totalRecords,
  totalRows,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("company");
  const itemsPerPage = 100;
  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [companiesDetails, setCompaniesDetails] = useState([]);
  const [bookmarkedCompanies, setBookmarkedCompanies] = useState([]);
  const [tableRows, setTableRows] = useState(
    () => parseInt(localStorage.getItem("rowsCount"), 10) || 20
  );

  const handleTableRowsChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setTableRows(newValue);
    localStorage.setItem("rowsCount", newValue);
    window.location.href = "/bookmarks";
  };

  const handleNextPage = () => {
    const nPage = parseInt(pageNumber + 1);
    window.location.href = "/bookmarks?t=companies&p=" + nPage;
  };

  const handlePrevPage = () => {
    const pPage = parseInt(pageNumber - 1);
    window.location.href = "/bookmarks?t=companies&p=" + pPage;
  };

  const handleSort = (column) => {
    const allowedColumns = [
      "country",
      "name_industry",
      "company",
      "tunover_level_filter",
      "nr_sal_an5",
    ];

    if (allowedColumns.includes(column)) {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }

      const sortedCompaniesDetails = [...companiesDetails].sort((a, b) => {
        const aValue = a.attributes && a.attributes[column];
        const bValue = b.attributes && b.attributes[column];

        const aLower =
          typeof aValue === "string" ? aValue.toLowerCase() : aValue;
        const bLower =
          typeof bValue === "string" ? bValue.toLowerCase() : bValue;

        if (sortOrder === "asc") {
          return aLower > bLower ? 1 : -1;
        } else {
          return aLower < bLower ? 1 : -1;
        }
      });

      setCompaniesDetails(sortedCompaniesDetails);
    }
  };

  const handleDeleteCompanyBookmark = (companyId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this company bookmark?"
    );

    if (confirmDelete) {
      axios.delete(`${apiUrl}/companies-bookmarks/${companyId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const updatedCompanies = bookmarkedCompanies.filter(
        (company) => company.id !== companyId
      );
      setBookmarkedCompanies(updatedCompanies);
      setNotify("Company bookmark deleted successfully.");
      setNotifyClass("success");

      setTimeout(() => {
        window.location.href = "/bookmarks?t=companies&p=1";
      }, 1000);
    }
  };

  const renderSortingArrow = (column) => {
    if (column === sortColumn) {
      if (sortOrder === "asc") {
        return <span>&uarr;</span>;
      } else {
        return <span>&darr;</span>;
      }
    }
    return null;
  };

  function properDate(dateVal) {
    const date = new Date(dateVal);
    return date.toLocaleDateString();
  }

  const getCompanyDetails = async (companyId) => {
    try {
      const response = await axios.get(`${apiUrl}/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching company details:", error);
      return null;
    }
  };

  const renderCompanyDetails = async () => {
    const companiesDetailsPromises = companiesBookmarks
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map(async (bm) => {
        const companyDetails = await getCompanyDetails(
          bm.attributes.company_id
        );
        return (
          <tr key={bm.id}>
            <td className="hide-tab-mob">
              <ReactCountryFlag
                countryCode={getCountryCode(
                  companyDetails.attributes && companyDetails.attributes.country
                )}
                svg
                style={{ width: "20px", marginRight: "5px" }}
              />
              <span className="fw-medium hide-tab-mob">
                {i18nIsoCountries.getAlpha2Code(
                  companyDetails &&
                    companyDetails.attributes &&
                    companyDetails.attributes.country,
                  "en"
                )}
              </span>
            </td>
            <td className="hide-tab-mob truncate-text">
              {companyDetails &&
                companyDetails.attributes &&
                companyDetails.attributes.name_industry}
            </td>
            <td className="truncate-text">
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  display: "inline-block",
                  marginRight: "5px",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={companyDetails.attributes.logo_url ? companyDetails.attributes.logo_url.split(';')[0] : ''}
                />
              </div>
              <div style={{ float: "left", lineHeight: "25px" }}>
                {companyDetails &&
                  companyDetails.attributes &&
                  companyDetails.attributes.company}
              </div>
            </td>
            <td className="hide-tab-mob">
              <span
                className={(() => {
                  const filterValue =
                    companyDetails.attributes &&
                    companyDetails.attributes.tunover_level_filter;
                  switch (filterValue) {
                    case "< 100K EUR":
                      return "badge bg-label-info me-1";
                    case "100 - 500K EUR":
                      return "badge bg-label-danger me-1";
                    case "0.5 - 1 Mn. EUR":
                      return "badge bg-label-warning me-1";
                    case "1 - 5 Mn. EUR":
                      return "badge bg-label-dark me-1";
                    case "5 - 10 Mn. EUR":
                      return "badge bg-label-secondary me-1";
                    case "10 - 25 Mn. EUR":
                      return "badge bg-label-success me-1";
                    case "25 - 50 Mn. EUR":
                      return "badge bg-label-secondary me-1";
                    case "50 - 100 Mn. EUR":
                      return "badge bg-info me-1";
                    case "100 - 500 Mn. EUR":
                      return "badge bg-label-primary me-1";
                    case "> 500 Mn. EUR":
                      return "badge bg-label-success me-1";
                  }
                })()}
              >
                {companyDetails.attributes &&
                  companyDetails.attributes.tunover_level_filter}
              </span>
            </td>{" "}
            <td className="hide-tab-mob text-right">
              <span>
                {companyDetails.attributes &&
                  companyDetails.attributes.nr_sal_an5}
              </span>
            </td>
            <td>
              <div className="d-flex">
                <Link
                  style={{ width: "25px", height: "25px" }}
                  to={`/companies/${(
                    companyDetails.attributes.company?.toLowerCase() || ""
                  ).replace(/ /g, "-")}/${companyDetails.id}`}
                  className="btn btn-purple text-primary btn-icon"
                  title="View"
                >
                  <i className="ti ti-eye"></i>
                </Link>
                <button
                  style={{
                    marginLeft: "10px",
                    width: "25px",
                    height: "25px",
                  }}
                  className={`btn btn-icon`}
                >
                  <i
                    onClick={() => handleDeleteCompanyBookmark(bm.id)}
                    style={{ cursor: "pointer" }}
                    className="text-danger ti ti-trash"
                  ></i>
                </button>
              </div>
            </td>{" "}
          </tr>
        );
      });

    const resolvedCompaniesDetails = await Promise.all(
      companiesDetailsPromises
    );
    setCompaniesDetails(resolvedCompaniesDetails);
  };

  useEffect(() => {
    renderCompanyDetails();
  }, [currentPage, itemsPerPage, companiesBookmarks]);

  const isMobileScreen = window.innerWidth <= 768;
  const totalPages = Math.ceil(totalRecords / totalRows);
  const startPage = Math.max(
    1,
    Math.min(
      pageNumber - (isMobileScreen ? 1 : 4),
      totalPages - (isMobileScreen ? 3 : 9)
    )
  );
  const endPage = Math.min(
    totalPages,
    Math.max(isMobileScreen ? 3 : 10, pageNumber + (isMobileScreen ? 2 : 5))
  );
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  return (
    <div>
      <h5 style={{ paddingLeft: "5px" }}>
        Bookmarked Companies List (<i>{totalRecords}</i> found!)
      </h5>
      {notify !== "" ? (
        <div
          className={`w-50 alert alert-${notifyClass}`}
          style={{ position: "fixed", top: "80px", right: "0px", zIndex: "10" }}
        >
          {notify}
        </div>
      ) : null}
      <div className="text-nowrap company-table">
        <small className="text-danger">
          <i>Click on column name to sort the table</i>
        </small>
        <table className="table table-hover table-bordered table-striped">
          <thead>
            <tr>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("country")}
                style={{ width: "10%" }}
              >
                Country {renderSortingArrow("country")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("name_industry")}
                style={{ width: "20%" }}
              >
                Industry {renderSortingArrow("name_industry")}
              </th>
              <th onClick={() => handleSort("company")}>
                Company {renderSortingArrow("company")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("tunover_level_filter")}
                style={{ width: "10%" }}
              >
                Revenue {renderSortingArrow("tunover_level_filter")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("nr_sal_an5")}
                style={{ width: "10%" }}
              >
                Employees {renderSortingArrow("nr_sal_an5")}
              </th>
              <th style={{ width: "10%" }}>
                <span className="hide-tab-mob">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {companiesDetails && companiesDetails.length > 0
              ? companiesDetails
              : ""
                // <tr>
                //   <th colSpan="6" className="text-danger"><strong>No bookmarks found!</strong></th>
                // </tr>
            }
          </tbody>
        </table>
      </div>
      <div className="row mb-3">
        {isMobileScreen ? (
          ""
        ) : (
          <div className="col-md-6 float-left">
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>
            </select>
          </div>
        )}
        <div
          className={
            isMobileScreen
              ? "text-center"
              : "col-md-6 float-right-pagination float-right"
          }
        >
          {isMobileScreen && (
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>
            </select>
          )}

          {pageNumber == 1 ? (
            ""
          ) : (
            <button className="btn btn-primary" onClick={handlePrevPage}>
              &laquo; {isMobileScreen ? "" : "Prev"}
            </button>
          )}

          {pageNumbers.map((page) => (
            <a
              style={{ fontSize: "13px", marginRight: "2px" }}
              key={page}
              className={`btn rounded-pill btn-icon btn-label-primary waves-effect ${
                page === pageNumber ? "active" : ""
              }`}
              href={`/bookmarks?t=companies&p=${page}`}
            >
              {page}
            </a>
          ))}

          {allowNext ? (
            <button
              style={{ marginLeft: "13px" }}
              className="btn btn-primary"
              onClick={handleNextPage}
            >
              {isMobileScreen ? "" : "Next"} &raquo;
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default BookmarkCompaniesList;
