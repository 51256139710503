import React, { useState } from 'react';

const DomainStructure = ({ domain }) => {
  const [showMore, setShowMore] = useState(false);
  const maxCardHeight = showMore ? '100%' : '320px';
  // Check if domain.linked_domains exists and is a non-empty string
  if (
    !domain.linked_domains ||
    typeof domain.linked_domains !== "string" ||
    domain.linked_domains.trim() === ""
  ) {
    return null; // Render nothing if linked_domains is not valid
  }
  const linkedDomains = domain.linked_domains
    .split(",")
    .map((linkedDomain, index) => (
      <li className="d-flex mb-3" key={index}>
        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
          <div className="me-2">
            <h6 className="mb-0">
              <i className="ti ti-link ti-sm"></i> &nbsp; &nbsp; &nbsp;
              {linkedDomain.trim()}
            </h6>
          </div>
        </div>
      </li>
    ));

  return (
    <div className="row mb-4">
      <h4 className="py-3 mb-4">Domain Structure</h4>
      <div className="col-md-9">
        <div className="row">
          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-external-link ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">
                  {domain.outgoing_links_on_page}
                </h5>
                <span>Outgoing Links</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-link ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">
                  {domain.internal_links_on_page}
                </h5>
                <span>Internal Links</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-layers-linked ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">
                  {domain.external_links_on_page}
                </h5>
                <span>External Links</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-photo-plus ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">{domain.total_images}</h5>
                <span>Total Images</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-arrow-autofit-width ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">{domain.images_on_page}</h5>
                <span>Images on Page</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-code ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">{domain.html_size_on_page}</h5>
                <span>HTML size on page</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-file-code ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">
                  {domain.text_html_ratio_on_page}
                </h5>
                <span>Text/HTML ratio</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-6 mb-4">
            <div className="card h-100">
              <div className="card-body text-center dom-str">
                <div className="badge rounded-pill p-2 bg-label-danger mb-2">
                  <i className="ti ti-list-details ti-sm"></i>
                </div>
                <h5 className="card-title mb-2">{domain.words_on_page}</h5>
                <span>Words on page</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card" style={{ height: maxCardHeight }}>
          <div className="card-header d-flex justify-content-between pb-0">
            <div className="card-title mb-0">
              <h5 className="m-0 me-2">Linked Domains</h5>
            </div>
          </div>
          <div className="card-body pb-0 p-3">
            <ul className="p-0 m-0">{showMore ? linkedDomains : linkedDomains.slice(0, 5)}</ul>
          </div>
          <div className="card-footer">
          {linkedDomains.length > 5 && (
            <button style={{background:"none", border:"0px", fontWeight:"bold"}} onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default DomainStructure;
