import axios from 'axios';

class CommonFunctions {
  static fetchProfileAvatar(apiUrl, userId, userToken) {
    axios
      .get(`${apiUrl}/users/${userId}?populate=*`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const newAvatarUrl = "https://bck.scoring.ro" + response.data.avatar.url;
        console.log("New avatar: " + newAvatarUrl);
        return newAvatarUrl;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }

  static checkPermission(param, move) {
    // Replace this with your actual permission-checking logic
    // For example, check the parameter in local storage for permission
    const storedPermission = localStorage.getItem(param);
    console.log("1 - perm for " + param + " is: " + storedPermission);
    if (storedPermission === "true" || storedPermission === true) {
      // User has permission
      console.log("2 - perm for " + param + " is: " + storedPermission);
      return true;
    } else {
      // User doesn't have permission, redirect to plans page
      if (move === 1) {
        console.log("3 - perm for " + param + " is: " + storedPermission);
        window.location.href = "/plans";
      }
      return false;
    }
  }
}

export default CommonFunctions;
