import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import SiteLogo from "../images/logo.png";
import { Link } from "react-router-dom";

export default class SiteRegisterPro extends Component {
  constructor(props) {
    super(props);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeReffBy = this.onChangeReffBy.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
    this.onChangeUserPhone = this.onChangeUserPhone.bind(this);
    this.saveRegisterDetails = this.saveRegisterDetails.bind(this);
    this.onChangeCaptcha = this.onChangeCaptcha.bind(this);

    this.state = {
      userName: "",
      reffBy: "",
      userEmail: "",
      userPhone: "",
      passWord: "",
      c1: Math.floor(Math.random() * 10),
      c2: Math.floor(Math.random() * 10),
      captchaAns: "",
      loginErr: "",
      userRole: 2
    };
  }
  onChangeCaptcha(e) {
    this.setState({
      captchaAns: e.target.value
    });
  }

  onChangeReffBy(e) {
    this.setState({
      reffBy: e.target.value
    });
  }
  onChangeUsername(e) {
    this.setState({
      userName: e.target.value
    });
  }
  onChangeUserEmail(e) {
    this.setState({
      userEmail: e.target.value
    });
  }
  onChangeUserPhone(e) {
    const formattedPhoneNumber = this.formatPhoneNumber(e.target.value);
    this.setState({
      userPhone: formattedPhoneNumber
    });
  }

  onChangePassword(e) {
    this.setState({
      passWord: e.target.value
    });
  }

  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  // validateReffByCode(refBy) {
  //   AffiliateDataService.validateReffByCode(refBy)
  //     .then(response => {
  //       if (response.data.success !== true) {
  //         this.setState({
  //           loginErr: response.data.message,
  //         });
  //         return false;
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // }

  saveRegisterDetails() {
    //validate form
    //validate reff by code
    // if (this.state.reffBy !== "") {
    //   AffiliateDataService.validateReffByCode(this.state.reffBy)
    //     .then(response => {
    //       console.log("Ref By res: " + response.data.success);
    //       if (response.data.success === false) {
    //         this.setState({
    //           loginErr: response.data.message,
    //         });
    //         return false;
    //       }
    //     });
    // }
    //return false;
    if (this.state.userName === "") {
      this.setState({
        loginErr: "Username required!"
      });
      return false;
    }
    // if (this.state.userEmail === "") {
    //   this.setState({
    //     loginErr: "Email required!"
    //   });
    //   return false;
    // }

    //var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.userEmail.trim() === ""){
      this.setState({
        loginErr: "Invalid Email!"
      });
      return false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.userEmail)){
      this.setState({
        loginErr: "Invalid Email!"
      });
      return false;
    }

    if (this.state.userPhone.trim() === "") {
      this.setState({
        loginErr: "Phone required!"
      });
      return false;
    }

    if (this.state.passWord === "") {
      this.setState({
        loginErr: "Password required!"
      });
      return false;
    }
    if (this.state.passWord.length < 8) {
      this.setState({
        loginErr: "Password must have atleast 8 charcters!"
      });
      return false;
    }
    if (this.state.userName.length < 3) {
      this.setState({
        loginErr: "Username must be of minimum three characters!"
      });
      return false;
    }

    var expectedAns = (this.state.c1 + this.state.c2);
    if (expectedAns != this.state.captchaAns) {
      this.setState({
        loginErr: "Wrong Captcha Answer!"
      });
      return false;
    }

    var data = {
      userName: this.state.userName,
      userEmail: this.state.userEmail,
      userPhone: this.state.userPhone,
      passWord: this.state.passWord,
      reffBy: this.state.reffBy,
      userRole: this.state.userRole,
    };

    AffiliateDataService.register(data.userName, data.userEmail, data.userPhone, data.passWord, data.reffBy, data.userRole)
      .then(response => {
        this.setState({
          userName: response.userName,
          passWord: response.passWord,
          userEmail: response.userEmail,
          userPhone: response.userPhone,
          reffBy: response.reffBy,
          submitted: true
        });
        //console.log(response.data.success)
        if (response.data.success === true) {
          localStorage.setItem("registered", true);
          window.location.href = "signin";
        }
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { loginErr, captchaAns, c1, c2 } = this.state;
    return (
      <div className="login signup" style={{ paddingTop: "3%" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div className="login-boxed">
                <div className="site-logo">
                  <img src={SiteLogo} alt="logo" className="img-fluid" />
                </div>
                <div className="login-box">
                  {loginErr !== "" ? <h5 className="text text-danger">{loginErr}</h5> : ""}
                  <div className="row">
                    <div className="col-md-6">
                      <label>Username</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        required
                        value={this.state.userName}
                        onChange={this.onChangeUsername}
                        name="userName"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userEmail"
                        required
                        value={this.state.userEmail}
                        onChange={this.onChangeUserEmail}
                        name="userEmail"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Phone #</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userPhone"
                        required
                        value={this.state.userPhone}
                        onChange={this.onChangeUserPhone}
                        name="userPhone"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="passWord"
                        required
                        value={this.state.passWord}
                        onChange={this.onChangePassword}
                        name="passWord"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Captcha</label>
                      <div className="border border-primary bg bg-dark text text-warning p-1 mb-1">
                        <strong>{c1}</strong> + <strong>{c2}</strong>
                      </div>
                      </div>
                      <div className="col-md-6">
                      <label></label>
                      <input
                        type="text"
                        maxLength={2}
                        className="form-control"
                        id="captchaAns"
                        required
                        value={captchaAns}
                        onChange={this.onChangeCaptcha}
                        name="captchaAns"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-xs-12 offset-md-4">
                      <input onClick={this.saveRegisterDetails} type="submit" name="login" value="Register Now" />
                    </div>
                  </div>
                  <div className="su-div">
                    <p>Already a member? <Link to="/signin">Login</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
