import React, { Component, useState, useEffect } from "react";
import CompanyList from "./CompanyList";
import axios from "axios";
import CompanyDetail from "./company-detail";
import { useParams, useNavigate, Link } from "react-router-dom"; // Import useParams
import TopNavBar from "./TopNavBar";
import Select from "react-select";
import CommonFunctions from "../common/functions";
import AffiliateDataService from "../services/affiliate.service";
import "../assets/vendor/fonts/fontawesome.css";
import "../assets/vendor/fonts/tabler-icons.css";
import "../assets/vendor/fonts/flag-icons.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/css/demo.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/libs/node-waves/node-waves.css";
import "../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "../assets/vendor/libs/typeahead-js/typeahead.css";
import "../assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
import "../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";
import "../assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css";
import "../assets/vendor/css/pages/cards-advance.css";

export default function CompaniesHolder() {
  const [permViewComProfile, setPermViewComProfile] = useState(true);
  const [finHealthChart, setFinHealthChart] = useState(true);
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated")
  );
  const [userSelPlan, setUserSelPlan] = useState(
    localStorage.getItem("userSelPlanId")
  );

  useEffect(() => {
    if (authenticated) {
      // console.log(
      //   "selecte plan of user: " + localStorage.getItem("userSelPlanId")
      // );
      CommonFunctions.checkPermission("can_access_company_list", 1);
      // setPermViewComProfile(
      //   CommonFunctions.checkPermission("view_company_profile", 0)
      // );
      setFinHealthChart(
        CommonFunctions.checkPermission("view_financial_health_chart_cp", 0)
      );
    }
  }, [userSelPlan]);

  const [myCredits, setMyCredits] = useState(50);
  //const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth >= 1200);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companiesCount, setCompaniesCount] = useState([]);
  const [countryFilter, setCountryFilter] = useState(
    localStorage.getItem("countryFilter") === null ||
      localStorage.getItem("countryFilter") === "null"
      ? ""
      : localStorage.getItem("countryFilter")
  );
  const [firmaFilter, setFirmaFilter] = useState(
    localStorage.getItem("firmaFilter") === null ||
      localStorage.getItem("firmaFilter") === "null"
      ? ""
      : localStorage.getItem("firmaFilter")
  );
  const [industryFilter, setIndustryFilter] = useState(
    localStorage.getItem("industryFilter") === null ||
      localStorage.getItem("industryFilter") === "null"
      ? ""
      : localStorage.getItem("industryFilter")
  );
  const { id } = useParams();
  if (!authenticated && id === undefined) {
    window.location.href = "/signin";
  }

  const savedFilters = localStorage.getItem("filtersQuery");
  const [pageNumber, setPageNumber] = useState("");
  const [allowNext, setAllowNext] = useState("");
  const [isLoading, setIsLoading] = useState("");
  let filterQuery = "";
  const [isMOMenuOpen, setIsMOMenuOpen] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  //for auto complete
  //const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  // end for auto complete
  const [totalRows, setTotalRows] = useState(() => {
    const storedRowsCount = localStorage.getItem("rowsCount");
    return storedRowsCount ? parseInt(storedRowsCount, 10) : 100;
  });

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const handleCountryFilterChange = (newFilter) => {
    console.log("i am here to remove country filter");
    localStorage.removeItem("countryFilter");
    localStorage.setItem("countryFilter", "");
    setCountryFilter(newFilter);
    handleFilters("ctry");
  };

  const handleFirmaFilterChange = (newFilter) => {
    setFirmaFilter(newFilter);
    localStorage.removeItem("firmaFilter");
    handleFilters("fir");
  };
  const handleIndsFilterChange = (newFilter) => {
    setSelectedIndustries(newFilter);
    handleFilters("ind");
  };

  const handleRangeFilterChange = (newFilter) => {
    setSelectedRanges(newFilter);
    handleFilters("ind");
  };

  const handleEmpFilterChange = (newFilter) => {
    setSelectedEmployeeRanges(newFilter);
    handleFilters("emp");
  };

  const handleEbitFilterChange = (newFilter) => {
    setSelectedEbitRanges(newFilter);
    handleFilters("ebit");
  };

  const handleEbitdaFilterChange = (newFilter) => {
    setSelectedEbitdaRanges(newFilter);
    handleFilters("ebitda");
  };

  const handleRevGrowthFilterChange = (newFilter) => {
    setSelectedRevGrowthRanges(newFilter);
    handleFilters("revgrowth");
  };

  const handleSelectIndChange = (selectedValues) => {
    setSelectedIndustries(selectedValues);
    const selectedValueArray = selectedValues.map((item) => item.value);
    localStorage.setItem("selectedIndustriesData", selectedValueArray);
  };

  function capitalizeString(inputString, type) {
    // Convert the entire string to lowercase
    const lowercaseString = inputString.toLowerCase();
    if (type === "domain") {
      return lowercaseString;
    } else if (type === "company") {
      return inputString.toUpperCase();
    } else {
      // Capitalize the first letter and concatenate it with the rest of the string
      return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
    }
  }

  const onChangeCountryFilter = (e) => {
    setCountryFilter(e.target.value);
    if (countryFilter && countryFilter.length >= 1) {
      setIsLoading(1);
      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const countryFilterFinal = capitalizeString(countryFilter, "country");

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(
          apiUrl +
            "/companies?fields[0]=country&filters[country][$contains]=" +
            countryFilterFinal,
          {
            headers,
          }
        )
        .then((response) => {
          const uniqueCountries = new Set();
          const responseData = response.data.data;
          responseData.forEach((item) => {
            const country = item.attributes.country;
            uniqueCountries.add(country);
          });

          const uniqueCountryArray = Array.from(uniqueCountries);

          setCountrySuggestions(uniqueCountryArray);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setCountrySuggestions([]);
      //setIsLoading(0);
    }
  };

  const onChangeFirmaFilter = (e) => {
    setFirmaFilter(e.target.value);
  };

  const onChangeIndFilter = (e) => {
    setIndustryFilter(e.target.value);
  };

  const resetFilters = () => {
    setCountryFilter(""); // Reset country filter
    setIndustryFilter(""); // Reset domain filter
    setFirmaFilter(""); // Reset category filter
    setSelectedEbitRanges([]); // Reset size range filter
    setSelectedEbitdaRanges([]); // Reset domain authority filter
    setSelectedEmployeeRanges([]);
    setSelectedRanges([]);
    setSelectedRevGrowthRanges([]);

    // Clear selected filter values from localStorage
    localStorage.removeItem("countryFilter");
    localStorage.removeItem("firmaFilter");
    localStorage.removeItem("selectedIndustriesData");
    localStorage.removeItem("selectedRanges");
    localStorage.removeItem("selectedEbitRanges");
    localStorage.removeItem("selectedEbitdaRanges");
    localStorage.removeItem("selectedEmployeeRanges");
    localStorage.removeItem("selectedRevGrowthRanges");

    localStorage.removeItem("filtersQuery");

    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;

    fetchCompanies(
      userToken,
      apiUrl + "/companies?pagination[page]=1&pagination[pageSize]=" + totalRows
    );

    fetchCompaniesNextPage(
      userToken,
      apiUrl + "/companies?pagination[page]=2&pagination[pageSize]=" + totalRows
    );
  };

  const handleFilters = async (tag = "") => {
    //check permission for filter companies
    const hasFilterPermission = await CommonFunctions.checkPermission(
      "filter_compnaies",
      0
    );

    if (!hasFilterPermission) {
      window.location.href = "/plans";
      return false;
    }

    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const filters = [];
    localStorage.setItem("filtersQuery", "");

    if (
      countryFilter !== "" ||
      countryFilter !== null ||
      countryFilter !== "null"
    ) {
      if (tag === "ctry") {
      } else {
        filters.push(`filters[country][$contains]=${countryFilter}`);
      }
    }
    if (firmaFilter !== "" || firmaFilter !== null || firmaFilter !== "null") {
      if (tag === "fir") {
      } else {
        filters.push(`filters[company][$contains]=${firmaFilter}`);
      }
    }
    // if (industryFilter) {
    //   filters.push(`filters[name_industry][$contains]=${industryFilter}`);
    // }

    if (tag === "ctry") {
    } else {
      localStorage.setItem("countryFilter", countryFilter);
    }
    if (tag === "fir") {
    } else {
      localStorage.setItem("firmaFilter", firmaFilter);
    }

    if (selectedIndustries && selectedIndustries.length > 0) {
      if (Array.isArray(selectedIndustries)) {
        // If selectedIndustries is an array
        selectedIndustries.forEach((range, index) => {
          filters.push(`filters[name_industry][$in][${index}]=${range.value}`);
        });
      } else if (typeof selectedIndustries === "string") {
        // If selectedIndustries is a string, assume it's comma-separated values
        const industriesArray = selectedIndustries.split(",");

        if (tag === "ind") {
        } else {
          industriesArray.forEach((industry, index) => {
            filters.push(
              `filters[name_industry][$in][${index}]=${industry.trim()}`
            );
          });
        }
      }
    } else {
      const selectedIndustriesData = localStorage.getItem(
        "selectedIndustriesData"
      );

      if (selectedIndustriesData) {
        const industriesArray = selectedIndustriesData.split(",");

        industriesArray.forEach((industry, index) => {
          filters.push(
            `filters[name_industry][$in][${index}]=${industry.trim()}`
          );
        });
      }
    }

    if (selectedRanges.length > 0 && tag!== "range") {
      selectedRanges.forEach((range, index) => {
        filters.push(`filters[tunover_level_filter][$in][${index}]=${range}`);
      });
    }

    if (selectedRevGrowthRanges.length > 0 && tag!== "revgrowth") {
      const rgArray = createArray(selectedRevGrowthRanges);

      if (rgArray.length === 1) {
        filters.push(
          `filters[turnover_filter][$${rgArray[0][1]}]=${rgArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        rgArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${
              range[4]
            }][turnover_filter][$${range[1]}]=${range[0].replace(
              /[ %<>]/g,
              ""
            )}`
          );
        });
      }
    }

    //ebit range handler
    if (selectedEbitRanges.length > 0 && tag!== "ebit") {
      const ebitArray = createArray(selectedEbitRanges);

      if (ebitArray.length === 1) {
        filters.push(
          `filters[mpn_an5][$${ebitArray[0][1]}]=${ebitArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        ebitArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][mpn_an5][$${
              range[1]
            }]=${range[0].replace(/[ %<>]/g, "")}`
          );
        });
      }
    }

    if (selectedEbitdaRanges.length > 0 && tag!== "ebitda") {
      const ebitdaArray = createArray(selectedEbitdaRanges);

      if (ebitdaArray.length === 1) {
        filters.push(
          `filters[EBITDA_an5][$${
            ebitdaArray[0][1]
          }]=${ebitdaArray[0][0].replace(/[ %<>]/g, "")}`
        );
      } else {
        ebitdaArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][EBITDA_an5][$${
              range[1]
            }]=${range[0].replace(/[ %<>]/g, "")}`
          );
        });
      }
    }

    function createArray(dataRange) {
      const resArray = [];

      let orVal = -1;
      dataRange.forEach((value) => {
        orVal = parseInt(orVal + 1);
        //console.log("Or value: " + orVal);
        if (value.includes("-")) {
          const [min, max] = value.split("-");
          resArray.push([min, "gte", "$and", orVal, 0]);
          resArray.push([max, "lte", "$and", orVal, 1]);
        } else if (value.includes(">")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "gt", "$and", orVal, 0]);
        } else if (value.includes("<")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "lt", "$and", orVal, 0]);
        }
      });

      return resArray;
    }

    // employee filter handler
    if (selectedEmployeeRanges.length > 0 && tag!== "emp") {
      const empArray = createArray(selectedEmployeeRanges);

      if (empArray.length === 1) {
        filters.push(
          `filters[nr_sal_an5][$${empArray[0][1]}]=${empArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        empArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][nr_sal_an5][$${
              range[1]
            }]=${range[0].replace(/[ %<>]/g, "")}`
          );
        });
      }
    }

    filterQuery = filters.length > 0 ? "?" + filters.join("&") : "";
    localStorage.setItem("filtersQuery", filterQuery);
    //console.log("Filter Query is: " + filterQuery);

    if (id) {
      window.location.href = "/companies";
    }

    const queryParams = getQueryParams(window.location.href);
    const pn = queryParams.p ? parseInt(queryParams.p) : 1;
    setPageNumber(pn);

    //if user is on page 2 or 3 etc and press filter button again, he should be redirected back to domains page.
    if (pn > 0) {
      window.location.href = "/companies";
    }

    fetchCompanies(
      userToken,
      apiUrl +
        "/companies" +
        filterQuery +
        (filterQuery.trim() === "" ? "?" : "&") +
        "pagination[page]=1&pagination[pageSize]=" +
        totalRows
    );

    fetchCompaniesNextPage(
      userToken,
      apiUrl +
        "/companies" +
        filterQuery +
        (filterQuery.trim() === "" ? "?" : "&") +
        "pagination[page]=2&pagination[pageSize]=" +
        totalRows
    );
  };

  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const fetchUserCredits = (userToken, apiUrl, userId) => {
    const script = document.createElement("script");
    script.src = "http://localhost:1337/plugins/strapi-stripe/static/stripe.js";
    script.async = true;
    document.head.appendChild(script);

    axios
      .get(
        apiUrl +
          "/credits-histories?populate=*&_sort=created_at:desc&_limit=1&filters[user][id][$eq]=" +
          userId +
          "&_limit=1",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.length === 0) {
          console.log("User credits history not found!");
        } else {
          const creditsLeft = res.data.data[0].attributes.creditsLeft;
          console.log("User credits history found!" + creditsLeft);
          setMyCredits(creditsLeft);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  const fetchCompanies = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          console.log("No Companies found!");
        } else {
          setCompaniesCount(res.data.meta.pagination.total);
          setCompanies(res.data.data);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  const fetchCompaniesNextPage = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("next Page results " + res.data.length);
        if (res.data.data.length > 0) {
          console.log("Next page data : 1");
          setAllowNext(1);
        } else {
          console.log("Next page data : 0");
          setAllowNext(0);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  const fetchAutoCompleteCompanies = (userToken, apiUrl) => {
    //for auto compleet
    if (firmaFilter && firmaFilter.length >= 3) {
      setIsLoading(1);
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };
      const firmaFilterFinal = capitalizeString(firmaFilter, "company");

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(
          apiUrl + "/companies?filters[company][$contains]=" + firmaFilterFinal,
          {
            headers,
          }
        )
        .then((response) => {
          //console.log("Suggestions: " + JSON.stringify(response.data.data));
          setSuggestions(response.data.data);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setSuggestions([]);
      //setIsLoading(0);
    }
    //end - for auto complete
    //setIsLoading(0);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      const can_access_company_list = localStorage.getItem(
        "can_access_company_list"
      );

      setSelectedIndustries(localStorage.getItem("selectedIndustriesData"));
      //console.log("Selected Industries: " + JSON.stringify(selectedIndustries));
      // if(!can_access_company_list){
      //   navigate('/plans?package=na');
      // }

      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      const userData = localStorage.getItem("userData");
      const userDataJSON = JSON.parse(userData);
      //console.log("User ID: " + userDataJSON.id);
      //console.log("query " + localStorage.getItem('filtersQuery'));

      if (localStorage.getItem("filtersQuery")) {
        // console.log(
        //   "New api endpoint: " + apiUrl + "/companies" + savedFilters
        // );
      }

      fetchAutoCompleteCompanies(userToken, apiUrl);

      //get page number
      const queryParams = getQueryParams(window.location.href);
      const pn = queryParams.p ? parseInt(queryParams.p) : 1;
      const coll = queryParams.c ? parseInt(queryParams.c) : 0;
      setPageNumber(pn);
      //("Query is: " + savedFilters);
      fetchUserCredits(userToken, apiUrl, userDataJSON.id);

      if (savedFilters === null || savedFilters === "") {
        fetchCompanies(
          userToken,
          apiUrl +
            "/companies?pagination[page]=" +
            pn +
            "&pagination[pageSize]=" +
            totalRows
        );
        fetchCompaniesNextPage(
          userToken,
          apiUrl +
            "/companies?pagination[page]=" +
            parseInt(pn + 1) +
            "&pagination[pageSize]=" +
            totalRows
        );
      } else {
        fetchCompanies(
          userToken,
          apiUrl +
            "/companies" +
            savedFilters +
            (savedFilters == null ? "?" : "&") +
            "pagination[page]=" +
            pn +
            "&pagination[pageSize]=" +
            totalRows
        );
        fetchCompaniesNextPage(
          userToken,
          apiUrl +
            "/companies" +
            savedFilters +
            (savedFilters == null ? "?" : "&") +
            "pagination[page]=" +
            parseInt(pn + 1) +
            "&pagination[pageSize]=" +
            totalRows
        );
      }
    }
  }, [id, firmaFilter]);

  //for auto complete
  const handleInputChange = (event) => {
    setFirmaFilter(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setFirmaFilter(suggestion);
    setSuggestions([]); // Clear suggestions when an item is selected
  };
  // end - for auto complete

  const ranges = [
    "< 100K EUR",
    "100 - 500K EUR",
    "0.5 - 1 Mn. EUR",
    "1 - 5 Mn. EUR",
    "5 - 10 Mn. EUR",
    "10 - 25 Mn. EUR",
    "25 - 50 Mn. EUR",
    "50 - 100 Mn. EUR",
    "100 - 500 Mn. EUR",
    "> 500 Mn. EUR",
  ];

  const revGrowthRanges = [
    "< -15%",
    "-15% - 0%",
    "0% - 25%",
    "25% - 50%",
    "> 50%",
  ];

  const ebitRanges = ["< 0%", "0% - 10%", "10% - 25%", "25% - 50%", "> 50%"];

  const ebitdaRanges = ["< 0%", "0% - 10%", "10% - 25%", "25% - 50%", "> 50%"];

  const employeeRanges = [
    "0 - 10",
    "10 - 50",
    "50 - 100",
    "100 - 500",
    "500 - 1000",
    "> 1000",
  ];

  // Load the selected filter values from localStorage, if available
  const initialSelectedRanges = JSON.parse(
    localStorage.getItem("selectedRanges") || "[]"
  );

  // const initialSelectedIndustries = JSON.parse(
  //   localStorage.getItem("selectedIndustries") || "[]"
  // );

  const initialSelectedEbitRanges = JSON.parse(
    localStorage.getItem("selectedEbitRanges") || "[]"
  );

  const initialSelectedEbitdaRanges = JSON.parse(
    localStorage.getItem("selectedEbitdaRanges") || "[]"
  );

  const initialSelectedEmployeeRanges = JSON.parse(
    localStorage.getItem("selectedEmployeeRanges") || "[]"
  );

  const initialSelectedRevGrowthRanges = JSON.parse(
    localStorage.getItem("selectedRevGrowthRanges") || "[]"
  );

  const [selectedRanges, setSelectedRanges] = useState(initialSelectedRanges);
  const [selectedRevGrowthRanges, setSelectedRevGrowthRanges] = useState(
    initialSelectedRevGrowthRanges
  );
  const [selectedEbitRanges, setSelectedEbitRanges] = useState(
    initialSelectedEbitRanges
  );
  const [selectedEbitdaRanges, setSelectedEbitdaRanges] = useState(
    initialSelectedEbitdaRanges
  );
  const [selectedEmployeeRanges, setSelectedEmployeeRanges] = useState(
    initialSelectedEmployeeRanges
  );

  // Function to handle checkbox changes
  const handleRangeChange = (range) => {
    //console.log("range: " + range);
    if (selectedRanges.includes(range)) {
      setSelectedRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedRanges((prevSelectedRanges) => [...prevSelectedRanges, range]);
    }
    // console.log("Selected range is: " + JSON.stringify(selectedRanges));
    // // Save the selected filter values to localStorage
    // localStorage.setItem("selectedRanges", JSON.stringify(selectedRanges));
  };

  const handleEbitRangeChange = (range) => {
    if (selectedEbitRanges.includes(range)) {
      setSelectedEbitRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedEbitRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleEbitdaRangeChange = (range) => {
    if (selectedEbitdaRanges.includes(range)) {
      setSelectedEbitdaRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedEbitdaRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleRevGrowthRangeChange = (range) => {
    if (selectedRevGrowthRanges.includes(range)) {
      setSelectedRevGrowthRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedRevGrowthRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleEmpRange = (range) => {
    if (selectedEmployeeRanges.includes(range)) {
      setSelectedEmployeeRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedEmployeeRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  useEffect(() => {
    // console.log(
    //   "Selected range is: " + JSON.stringify(selectedRevGrowthRanges)
    // );
    localStorage.setItem("selectedRanges", JSON.stringify(selectedRanges));
    //localStorage.setItem("selectedIndustries", JSON.stringify(selectedIndustries));

    localStorage.setItem(
      "selectedEmployeeRanges",
      JSON.stringify(selectedEmployeeRanges)
    );

    localStorage.setItem(
      "selectedRevGrowthRanges",
      JSON.stringify(selectedRevGrowthRanges)
    );

    localStorage.setItem(
      "selectedEbitdaRanges",
      JSON.stringify(selectedEbitdaRanges)
    );

    localStorage.setItem(
      "selectedEbitRanges",
      JSON.stringify(selectedEbitRanges)
    );
  }, [
    selectedRanges,
    selectedEmployeeRanges,
    selectedEbitRanges,
    selectedEbitdaRanges,
    selectedRevGrowthRanges,
    //selectedIndustries
  ]);

  const handleCountrySuggestionClick = (suggestion) => {
    setCountryFilter(suggestion);
    setCountrySuggestions([]); // Clear suggestions when an item is selected
  };
  //domain search filters starts
  const [domainCountryFilter, setDomainCountryFilter] = useState(
    localStorage.getItem("domainCountryFilter")
  );
  const [domainFilter, setDomainFilter] = useState(
    localStorage.getItem("domainFilter")
  );
  const [categoryFilter, setCategoryFilter] = useState(
    localStorage.getItem("categoryFilter")
  );
  const [domainSuggestions, setDomainSuggestions] = useState([]);
  const [domainCountrySuggestions, setDomainCountrySuggestions] = useState([]);
  const onChangeDomainCountryFilter = (e) => {
    setDomainCountryFilter(e.target.value);
    if (domainCountryFilter && domainCountryFilter.length >= 1) {
      setIsLoading(1);
      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const domainCountryFilterFinal = capitalizeString(
        domainCountryFilter,
        "country"
      );

      // Make an authorized GET request to fetch autocomplete domainSuggestions
      axios
        .get(
          apiUrl +
            "/domains?fields[0]=country&filters[country][$contains]=" +
            domainCountryFilterFinal,
          {
            headers,
          }
        )
        .then((response) => {
          const responseData = response.data.data;
          const uniqueCountries = new Set();
          responseData.forEach((item) => {
            const country = item.attributes.country;
            uniqueCountries.add(country);
          });

          const uniqueCountryArray = Array.from(uniqueCountries);

          setDomainCountrySuggestions(uniqueCountryArray);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setDomainCountrySuggestions([]);
      //setIsLoading(0);
    }
  };

  const onChangeDomainFilter = (e) => {
    setDomainFilter(e.target.value);
    if (domainFilter && domainFilter.length >= 1) {
      setIsLoading(1);
      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const domainFilterFinal = capitalizeString(domainFilter, "domain");

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(apiUrl + "/domains?filters[web][$contains]=" + domainFilterFinal, {
          headers,
        })
        .then((response) => {
          //console.log("Suggestions: " + JSON.stringify(response.data.data));
          setDomainSuggestions(response.data.data);
          setIsLoading(0);
          //console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setDomainSuggestions([]);
      //setIsLoading(0);
    }
  };

  const onChangeCategoryFilter = (e) => {
    setCategoryFilter(e.target.value);
  };

  // Function to reset filters
  const resetDomainFilters = () => {
    setDomainCountryFilter(""); // Reset country filter
    setDomainFilter(""); // Reset domain filter
    setCategoryFilter(""); // Reset category filter
    setSelectedSizeRanges([]); // Reset size range filter
    setSelectedDomainAuthRanges([]); // Reset domain authority filter

    // Clear selected filter values from localStorage
    localStorage.removeItem("selectedSizeRanges");
    localStorage.removeItem("selectedDomainAuthRanges");
    localStorage.removeItem("filtersQuery");

    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
  };

  const handleDomainFilters = () => {
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const filters = [];
    localStorage.setItem("filtersQuery", "");

    if (domainCountryFilter) {
      filters.push(`filters[country][$contains]=${domainCountryFilter}`);
    }
    if (domainFilter) {
      filters.push(`filters[web][$contains]=${domainFilter}`);
    }
    if (categoryFilter) {
      filters.push(`filters[category][$contains]=${categoryFilter}`);
    }

    localStorage.setItem("domainCountryFilter", domainCountryFilter);
    localStorage.setItem("domainFilter", domainFilter);
    localStorage.setItem("categoryFilter", categoryFilter);

    if (selectedSizeRanges.length > 0) {
      const sizeArray = createArray(selectedSizeRanges);

      if (sizeArray.length === 1) {
        const sizeVal =
          sizeArray[0][0] +
          (sizeArray[0][0] == "Giant" ? " Website" : " Websites");
        filters.push(`filters[crawled_pages][$${sizeArray[0][1]}]=${sizeVal}`);
      } else {
        sizeArray.forEach((range, index) => {
          const sizeVal =
            range[0] + (range[0] === "Giant" ? " Website" : " Websites");
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][crawled_pages][$${range[1]}]=${sizeVal}`
          );
        });
      }
    }

    //ebit range handler
    if (selectedDomainAuthRanges.length > 0) {
      const daArray = createArray(selectedDomainAuthRanges);

      if (daArray.length === 1) {
        filters.push(
          `filters[domain_authority][$${daArray[0][1]}]=${daArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        daArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${
              range[4]
            }][domain_authority][$${range[1]}]=${range[0].replace(
              /[ %<>]/g,
              ""
            )}`
          );
        });
      }
    }

    function createArray(dataRange) {
      const resArray = [];

      let orVal = -1;
      dataRange.forEach((value) => {
        orVal = parseInt(orVal + 1);
        //console.log("Or value: " + orVal);
        if (value.includes("-")) {
          const [min, max] = value.split("-");
          resArray.push([min, "gte", "$and", orVal, 0]);
          resArray.push([max, "lte", "$and", orVal, 1]);
        } else if (value.includes(">")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "gt", "$and", orVal, 0]);
        } else if (value.includes("<")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "lt", "$and", orVal, 0]);
        } else {
          const val = value.replace(/[%<>]/g, "", orVal, 0);
          resArray.push([val, "eq", "$and", orVal, 0]);
        }
      });

      return resArray;
    }

    filterQuery = filters.length > 0 ? "?" + filters.join("&") : "";
    localStorage.setItem("filtersQuery", filterQuery);

    window.location.href = "/domains";
  };
  const sizeRanges = ["Small", "Medium Sized", "Large", "Very Large", "Giant"];

  //const domainAuthRanges = ["Low", "Moderate", "Intermediate", "High", "Very high"];
  const domainAuthRanges = [
    { key: "Low", value: "0-20" },
    { key: "Moderate", value: "21-40" },
    { key: "Intermediate", value: "41-60" },
    { key: "High", value: "61-80" },
    { key: "Very high", value: ">80" },
  ];

  // Load the selected filter values from localStorage, if available
  const initialSelectedSizeRanges = JSON.parse(
    localStorage.getItem("selectedSizeRanges") || "[]"
  );
  const initialSelectedDomainAuthRanges = JSON.parse(
    localStorage.getItem("selectedDomainAuthRanges") || "[]"
  );

  const [selectedSizeRanges, setSelectedSizeRanges] = useState(
    initialSelectedSizeRanges
  );
  const [selectedDomainAuthRanges, setSelectedDomainAuthRanges] = useState(
    initialSelectedDomainAuthRanges
  );

  // Function to handle checkbox changes
  const handleSizeRangeChange = (range) => {
    if (selectedSizeRanges.includes(range)) {
      setSelectedSizeRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedSizeRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleDomainAuthRange = (range) => {
    if (selectedDomainAuthRanges.includes(range)) {
      setSelectedDomainAuthRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedDomainAuthRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleDomainSuggestionClick = (suggestion) => {
    setDomainFilter(suggestion);
    setDomainSuggestions([]); // Clear suggestions when an item is selected
  };

  const handleDomainCountrySuggestionClick = (suggestion) => {
    setDomainCountryFilter(suggestion);
    setDomainCountrySuggestions([]); // Clear suggestions when an item is selected
  };

  // end - for auto complete
  useEffect(() => {
    // /console.log("selected range: " + JSON.stringify(selectedDomainAuthRanges));
    // Save the selected filter values to localStorage
    localStorage.setItem(
      "selectedSizeRanges",
      JSON.stringify(selectedSizeRanges)
    );

    // Save the selected filter values to localStorage
    localStorage.setItem(
      "selectedDomainAuthRanges",
      JSON.stringify(selectedDomainAuthRanges)
    );
  }, [selectedSizeRanges, selectedDomainAuthRanges]);
  //domain search filters ends

  const handleMouseEnter = () => {
    setIsMOMenuOpen(true);
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMOMenuOpen(false);
    setIsMenuOpen(false);
  };

  // Conditionally assign the event handlers
  const menuProps =
    isMenuOpen && !isMOMenuOpen
      ? {}
      : {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        };

  useEffect(() => {
    if (!isMenuOpen) {
      const asideElementsWithShowClass =
        document.querySelectorAll("aside .show");
      asideElementsWithShowClass.forEach((element) => {
        element.classList.remove("show");
      });
    }
  }, [isMenuOpen]);

  const styleWithImportant = {
    maxHeight: "80vh",
    overflowY: "auto",
    paddingLeft: "5px !important",
  };
  const layoutClass = authenticated
    ? "layout-navbar-fixed layout-menu-fixed"
    : "";

  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <div className={`light-style  ${layoutClass}`}>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {authenticated ? (
            <aside
              {...menuProps}
              id="layout-menu"
              className={`layout-menu menu-vertical menu bg-menu-theme ${
                isMenuOpen ? "open" : ""
              }`}
            >
              <div className="app-brand demo">
                <a href="index.html" className="app-brand-link">
                  <span className="app-brand-logo demo">
                    <svg
                      width="32"
                      height="22"
                      viewBox="0 0 32 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                        fill="#7367F0"
                      ></path>
                      <path
                        opacity="0.06"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                        fill="#161616"
                      ></path>
                      <path
                        opacity="0.06"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                        fill="#161616"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                        fill="#7367F0"
                      ></path>
                    </svg>
                  </span>
                  {isMenuOpen ? (
                    <span className="app-brand-text demo menu-text fw-bold">
                      Scoring
                    </span>
                  ) : (
                    ""
                  )}
                </a>

                <a
                  onClick={toggleMenu}
                  href="#"
                  className="layout-menu-toggle menu-link text-large ms-auto"
                >
                  <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
                </a>
              </div>
              <div className="menu-inner-shadow"></div>
              <div
                className="filter-section p-2 rounded"
                style={styleWithImportant}
              >
                <div
                  className={`accordion ${
                    isMenuOpen ? "" : "accordian-icons-only"
                  }`}
                >
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading1">
                      {userSelPlan > 0 && !id ? (
                        <button
                          type="button"
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          <i
                            className="ti ti-device-ipad-search"
                            style={{
                              marginRight: "5px",
                              fontSize: "25px",
                              lineHeight: "20px",
                            }}
                          ></i>{" "}
                          {isMenuOpen ? "Company Search" : ""}
                        </button>
                      ) : (
                        <Link
                          to="/companies"
                          type="button"
                          className="accordion-button collapsed"
                        >
                          <i
                            className="ti ti-device-ipad-search"
                            style={{
                              marginRight: "5px",
                              fontSize: "25px",
                              lineHeight: "20px",
                            }}
                          ></i>{" "}
                          {isMenuOpen ? "Company Search" : ""}
                        </Link>
                      )}
                    </h2>
                    <div
                      id="collapse1"
                      className={`accordion-collapse collapse ${
                        !isMenuOpen ? "show" : ""
                      }`}
                      aria-labelledby="heading1"
                      data-bs-parent="#collapsibleSection"
                      style={{}}
                    >
                      <div className="accordion-body">
                        <div className="form-group">
                          <div className="accordion">
                            <div className="card accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingCountry"
                              >
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseCountry"
                                  aria-expanded="false"
                                  aria-controls="collapseCountry"
                                >
                                  Country
                                </button>
                              </h2>
                              <div
                                id="collapseCountry"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingCountry"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      <input
                                        type="text"
                                        id="countryFilter"
                                        className="form-control mb-2"
                                        placeholder="Country"
                                        value={countryFilter}
                                        onChange={onChangeCountryFilter}
                                      />
                                      {isLoading === 1 ? (
                                        <span className="fas fa-spinner fa-spin"></span>
                                      ) : (
                                        ""
                                      )}
                                      <div className="list-group">
                                        {countrySuggestions.map(
                                          (suggestion, index) => (
                                            <a
                                              href="#"
                                              className="list-group-item list-group-item-action"
                                              key={index}
                                              onClick={() =>
                                                handleCountrySuggestionClick(
                                                  suggestion
                                                )
                                              }
                                            >
                                              {suggestion}
                                            </a>
                                          )
                                        )}
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingCompany"
                              >
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseCompnay"
                                  aria-expanded="false"
                                  aria-controls="collapseCompnay"
                                >
                                  Company
                                </button>
                              </h2>
                              <div
                                id="collapseCompnay"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingCompany"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      {/* <input
                              type="text"
                              id="firmaFilter"
                              className="form-control mb-2"
                              placeholder="Firma"
                              value={firmaFilter}
                              onChange={onChangeFirmaFilter}
                            /> */}
                                      <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Firma"
                                        value={firmaFilter}
                                        onChange={handleInputChange}
                                      />
                                      {isLoading === 1 ? (
                                        <span className="fas fa-spinner fa-spin"></span>
                                      ) : (
                                        ""
                                      )}
                                      <div className="list-group">
                                        {suggestions.map(
                                          (suggestion, index) => (
                                            <a
                                              href="#"
                                              className="list-group-item list-group-item-action"
                                              key={index}
                                              onClick={() =>
                                                handleSuggestionClick(
                                                  suggestion.attributes.company
                                                )
                                              }
                                            >
                                              {suggestion.attributes.company}
                                            </a>
                                          )
                                        )}
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingIndustry"
                              >
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseIndustry"
                                  aria-expanded="false"
                                  aria-controls="collapseIndustry"
                                >
                                  Industry
                                </button>
                              </h2>
                              <div
                                id="collapseIndustry"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingIndustry"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      <Select
                                        isMulti // Enable multi-select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        id="industrySelect"
                                        options={[
                                          {
                                            value: "Shows and entertainment",
                                            label: "Shows and entertainment",
                                          },
                                          {
                                            value: "Industrial activities",
                                            label: "Industrial activities",
                                          },
                                          {
                                            value: "Public administration",
                                            label: "Public administration",
                                          },
                                          {
                                            value: "Agriculture",
                                            label: "Agriculture",
                                          },
                                          {
                                            value: "Craft activities",
                                            label: "Craft activities",
                                          },
                                          {
                                            value: "Retail trade",
                                            label: "Retail trade",
                                          },
                                          {
                                            value: "Wholesale trade",
                                            label: "Wholesale trade",
                                          },
                                          {
                                            value: "Electrical equipment",
                                            label: "Electrical equipment",
                                          },
                                          {
                                            value: "Energy",
                                            label: "Energy",
                                          },
                                          {
                                            value: "Real estate",
                                            label: "Real estate",
                                          },
                                          {
                                            value: "Mining and quarrying",
                                            label: "Mining and quarrying",
                                          },
                                          {
                                            value: "Food industry",
                                            label: "Food industry",
                                          },
                                          {
                                            value: "Automotive industry",
                                            label: "Automotive industry",
                                          },
                                          {
                                            value: "Beverages",
                                            label: "Beverages",
                                          },
                                          {
                                            value: "Construction",
                                            label: "Construction",
                                          },
                                          {
                                            value: "Education",
                                            label: "Education",
                                          },
                                          {
                                            value: "IT",
                                            label: "IT",
                                          },
                                          {
                                            value: "Wood and derived products",
                                            label: "Wood and derived products",
                                          },
                                        ]}
                                        onChange={handleSelectIndChange}
                                        value={selectedIndustries}
                                      />
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingRevenue"
                              >
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseRevenue"
                                  aria-expanded="false"
                                  aria-controls="collapseRevenue"
                                >
                                  Revenue
                                </button>
                              </h2>
                              <div
                                id="collapseRevenue"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingRevenue"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div
                                  className="accordion-body"
                                  style={{ paddingTop: "12px" }}
                                >
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      {ranges.map((range) => (
                                        <div key={range} className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={range}
                                            value={range}
                                            checked={selectedRanges.includes(
                                              range
                                            )}
                                            onChange={() =>
                                              handleRangeChange(range)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={range}
                                          >
                                            {range}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2 className="accordion-header" id="headingRos">
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseRos"
                                  aria-expanded="false"
                                  aria-controls="collapseRos"
                                >
                                  Revenue Growth (%)
                                </button>
                              </h2>
                              <div
                                id="collapseRos"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingRos"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div
                                  className="accordion-body"
                                  style={{ paddingTop: "12px" }}
                                >
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      {revGrowthRanges.map((range) => (
                                        <div key={range} className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={range}
                                            value={range}
                                            checked={selectedRevGrowthRanges.includes(
                                              range
                                            )}
                                            onChange={() =>
                                              handleRevGrowthRangeChange(range)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={range}
                                          >
                                            {range}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2 className="accordion-header" id="headingEbit">
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseEbit"
                                  aria-expanded="false"
                                  aria-controls="collapseEbit"
                                >
                                  EBIT (%)
                                </button>
                              </h2>
                              <div
                                id="collapseEbit"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEbit"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div
                                  className="accordion-body"
                                  style={{ paddingTop: "12px" }}
                                >
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      {ebitRanges.map((range) => (
                                        <div key={range} className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={range}
                                            value={range}
                                            checked={selectedEbitRanges.includes(
                                              range
                                            )}
                                            onChange={() =>
                                              handleEbitRangeChange(range)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={range}
                                          >
                                            {range}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2 className="accordion-header" id="headingEmp">
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseEmp"
                                  aria-expanded="false"
                                  aria-controls="collapseEmp"
                                >
                                  EBITDA (%)
                                </button>
                              </h2>
                              <div
                                id="collapseEmp"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEmp"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div
                                  className="accordion-body"
                                  style={{ paddingTop: "12px" }}
                                >
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      {ebitdaRanges.map((range) => (
                                        <div key={range} className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={range}
                                            value={range}
                                            checked={selectedEbitdaRanges.includes(
                                              range
                                            )}
                                            onChange={() =>
                                              handleEbitdaRangeChange(range)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={range}
                                          >
                                            {range}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="card accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingEbitda"
                              >
                                <button
                                  type="button"
                                  className="accordion-button collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseEbitda"
                                  aria-expanded="false"
                                  aria-controls="collapseEbitda"
                                >
                                  No. Of Employees
                                </button>
                              </h2>
                              <div
                                id="collapseEbitda"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEbitda"
                                data-bs-parent="#collapsibleSection"
                                style={{}}
                              >
                                <div
                                  className="accordion-body"
                                  style={{ paddingTop: "12px" }}
                                >
                                  <div className="row">
                                    <div className="col-md mb-md-0 mb-2">
                                      {employeeRanges.map((range) => (
                                        <div key={range} className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={range}
                                            value={range}
                                            checked={selectedEmployeeRanges.includes(
                                              range
                                            )}
                                            onChange={() =>
                                              handleEmpRange(range)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={range}
                                          >
                                            {range}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <button
                          id="filterButton"
                          className="btn btn-primary btn-block"
                          style={{ marginRight: "2px", width: "49%" }}
                          onClick={handleFilters}
                        >
                          Filter
                        </button>
                        <button
                          className={`btn ${
                            isMouseOver ? "btn-secondary" : "btn-danger"
                          } btn-block w-50`}
                          onClick={resetFilters}
                          onMouseOver={() => setIsMouseOver(true)}
                          onMouseOut={() => setIsMouseOver(false)}
                        >
                          Reset
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading2">
                      <Link
                        to="/domains"
                        type="button"
                        className="accordion-button collapsed"
                      >
                        <i
                          className="ti ti-world-search"
                          style={{
                            marginRight: "5px",
                            fontSize: "25px",
                            lineHeight: "20px",
                          }}
                        ></i>{" "}
                        {isMenuOpen ? "Domain Search" : ""}
                      </Link>
                    </h2>
                  </div>
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading3">
                      <button
                        type="button"
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        <i
                          className="ti ti-map-pin"
                          style={{
                            marginRight: "5px",
                            fontSize: "25px",
                            lineHeight: "20px",
                          }}
                        ></i>{" "}
                        {isMenuOpen ? "Locations" : ""}
                      </button>
                    </h2>
                  </div>
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading4">
                      <Link
                        to="/bookmarks"
                        className="accordion-button collapsed"
                      >
                        <i
                          className="ti ti-building"
                          style={{
                            marginRight: "5px",
                            fontSize: "25px",
                            lineHeight: "20px",
                          }}
                        ></i>{" "}
                        {isMenuOpen ? "My Companies" : ""}
                      </Link>
                    </h2>
                  </div>
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <a
                        href="/bookmarks?t=domains"
                        className="accordion-button collapsed"
                      >
                        <i
                          className="ti ti-world-www"
                          style={{
                            marginRight: "5px",
                            fontSize: "25px",
                            lineHeight: "20px",
                          }}
                        ></i>{" "}
                        {isMenuOpen ? "My Domains" : ""}
                      </a>
                    </h2>
                  </div>
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <Link
                        to="/user-email-lists"
                        className="accordion-button collapsed"
                      >
                        <i
                          className="ti ti-users"
                          style={{
                            marginRight: "5px",
                            fontSize: "25px",
                            lineHeight: "20px",
                          }}
                        ></i>{" "}
                        {isMenuOpen ? "Enrichments" : ""}
                      </Link>
                    </h2>
                  </div>
                  <div className="card accordion-item">
                    <h2 className="accordion-header" id="heading7">
                      <Link to="/logout" className="accordion-button collapsed">
                        <i
                          className="ti ti-logout"
                          style={{
                            marginRight: "5px",
                            fontSize: "25px",
                            lineHeight: "20px",
                          }}
                        ></i>{" "}
                        {isMenuOpen ? "Logout" : ""}
                      </Link>
                    </h2>
                  </div>
                </div>
              </div>
            </aside>
          ) : (
            ""
          )}
          <div
            className={`${
              isMenuOpen ? "layout-page" : "layout-page1"
            } custom-layout-padding`}
          >
            {authenticated ? (
              <TopNavBar
                toggleMenu={toggleMenu}
                myCredits={myCredits}
                isMenuOpen={isMenuOpen}
                authenticated={authenticated}
              ></TopNavBar>
            ) : (
              ""
            )}
            <div className="content-wrapper">
              <div className="flex-grow-1 container-p-y">
                <div className="row">
                  {id ? (
                    <CompanyDetail
                      permViewComProfile={permViewComProfile}
                      finHealthChart={finHealthChart}
                      authenticated={authenticated}
                    />
                  ) : (
                    <CompanyList
                      pageNumber={pageNumber}
                      allowNext={allowNext}
                      companies={companies}
                      totalRecords={companiesCount}
                      selectedRanges={selectedRanges}
                      selectedEmployeeRanges={selectedEmployeeRanges}
                      selectedEbitRanges={selectedEbitRanges}
                      selectedEbitdaRanges={selectedEbitdaRanges}
                      selectedRevGrowthRanges={selectedRevGrowthRanges}
                      countryFilter={countryFilter}
                      firmaFilter={firmaFilter}
                      selectedIndustries={selectedIndustries}
                      totalRows={totalRows}
                      setCountryFilter={handleCountryFilterChange}
                      setFirmaFilter={handleFirmaFilterChange}
                      setSelectedIndustries={handleIndsFilterChange}
                      setSelectedRanges={handleRangeFilterChange}
                      setSelectedEmployeeRanges={handleEmpFilterChange}
                      setSelectedEbitRanges={handleEbitFilterChange}
                      setSelectedEbitdaRanges={handleEbitdaFilterChange}
                      setSelectedRevGrowthRanges={handleRevGrowthFilterChange}
                    ></CompanyList>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
