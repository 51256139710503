import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import SiteLogo from "../images/logo.png";

export default class SiteLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      passWord: "",
      authenticated: false,
      registered: false
    };
  }

  componentDidMount() {
    localStorage.clear();
    window.location.href= "signin";
  }

  render() {
    return;
  }
}
