import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import AffiliateDataService from "../services/affiliate.service";
import axios from "axios"; // Import Axios
import Flag from "react-flags-select";
//import 'react-flags-select/css/react-flags-select.css';
import getCountryCode from "./i18n-iso-countries";
import ReactCountryFlag from "react-country-flag";
import CommonFunctions from "../common/functions";
import i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function DomainList({
  domains,
  pageNumber,
  allowNext,
  totalRecords,
  onRowsChange,
  totalRows,
}) {
  const [canBm, setCanBm] = useState(false);

  //permission manager
  useEffect(() => {
    setCanBm(CommonFunctions.checkPermission("can_bookmark", 0));
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortedDomains, setSortedDomains] = useState([...domains]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("domain");
  const itemsPerPage = 100;
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [bookmarkedDomains, setBookmarkedDomains] = useState([]);
  const [showSpinner, setShowSpinner] = useState("");
  const [tableRows, setTableRows] = useState();

  const handleTableRowsChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setTableRows(newValue);
    onRowsChange(newValue);
    console.log(newValue); // Log the new selected value

    localStorage.setItem("rowsCountDomains", newValue);
    window.location.href = "/domains";
  };

  // Maintain a state to track bookmarked status for each domain
  const [domainBookmarks, setDomainBookmarks] = useState({});

  useEffect(() => {
    // Update the sortedDomains whenever the domains prop changes
    setSortedDomains([...domains]);
    const bookmarkStatus = {};
    domains.forEach((domain) => {
      bookmarkStatus[domain.id] = isDomainBookmarked(domain.id);
    });
    setDomainBookmarks(bookmarkStatus);
  }, [domains]);

  useEffect(() => {
    setTimeout(() => {
      setShowSpinner(0);
    }, 7000);

    // Fetch bookmarked companies on page load
    fetchBookmarkedDomains();

    // Sort the data when sortColumn or sortOrder changes
    const sorted = [...sortedDomains].sort((a, b) => {
      const colA = getSortableValue(a, sortColumn);
      const colB = getSortableValue(b, sortColumn);

      if (typeof colA === "number" && typeof colB === "number") {
        // If both values are numbers, compare them as numbers
        return sortOrder === "asc" ? colA - colB : colB - colA;
      } else {
        // If either value is not a number, compare them as strings
        if (sortOrder === "asc") {
          return colA.toString().localeCompare(colB.toString());
        } else {
          return colB.toString().localeCompare(colA.toString());
        }
      }
    });
    setSortedDomains(sorted);
  }, [sortColumn, sortOrder]);

  const handleNextPage = () => {
    const nPage = parseInt(pageNumber + 1);
    window.location.href = "/domains?p=" + nPage;
  };

  const handlePrevPage = () => {
    const pPage = parseInt(pageNumber - 1);
    window.location.href = "/domains?p=" + pPage;
  };

  const handleSort = (column) => {

    // Define the columns where sorting is allowed
    const allowedColumns = [
      "web",
      "category",
      "title",
      "country",
      "size",
      "backlinks",
    ];

    if (allowedColumns.includes(column)) {
      if (column === sortColumn) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        console.log(sortOrder);
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  const renderSortingArrow = (column) => {
    if (column === sortColumn) {
      if (sortOrder === "asc") {
        return <span>&uarr;</span>;
      } else {
        return <span>&darr;</span>;
      }
    }
    return null;
  };

  // Function to get the sortable value for a column
  const getSortableValue = (item, column) => {
    if (item && item.attributes && item.attributes[column] !== undefined) {
      const columnValue = item.attributes[column];

      if (column === "backlinks") {
        // Parse numeric columns as numbers for correct sorting
        const backlinksValue = parseFloat(columnValue);
        return isNaN(backlinksValue) ? 0 : backlinksValue;
      }

      // if (column === "size") {
      //   // Convert size to lowercase for consistent sorting
      //   return columnValue.toLowerCase();
      // }

      return columnValue.toString();
    }

    // Handle the case where item.attributes or item.attributes[column] is undefined
    return "";
  };

  const fetchBookmarkedDomains = () => {
    //console.log("i am in fetch function");
    // Send a GET request to fetch bookmarked companies for the user
    axios
      .get(apiUrl + `/domain-bookmarks?filters[user_id][$eq]=${userData.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        // Extract the domain IDs from the response and set them in state
        const bookmarkedDomainIds = response.data.data.map(
          (bookmark) => bookmark.attributes.domain_id
        );
        setBookmarkedDomains(bookmarkedDomainIds);
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  const isDomainBookmarked = (domainId) => {
    // Check if companyId exists in bookmarkedCompanies
    if (bookmarkedDomains.includes(domainId)) {
      // If found, return true
      return true;
    }

    // If not found, try converting companyId to string
    const domainIdAsString = domainId.toString();

    // Check if the converted companyId exists in bookmarkedCompanies
    if (bookmarkedDomains.includes(domainIdAsString)) {
      // If found after conversion, return true
      return true;
    }

    // If still not found, return false
    return false;
  };

  const toggleBookmark = (domainId) => {
    if (isDomainBookmarked(domainId)) {
      console.log("unbookmarking...");
      axios
        .get(
          apiUrl +
            `/domain-bookmarks?filters[user_id][$eq]=${userData.id}&filters[domain_id][$eq]=${domainId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0].id);
            unbookmarkDomain(response.data.data[0].id, domainId);
          }
        })
        .catch((error) => {
          // Handle error if needed
        });
    } else {
      console.log("bookmarking..." + domainId);
      // Bookmark the domain
      bookmarkDomain(domainId);
    }

    // Immediately update the bookmark status in state
    setDomainBookmarks((prevBookmarks) => ({
      ...prevBookmarks,
      [domainId]: !prevBookmarks[domainId],
    }));
  };

  const bookmarkDomain = (domainId) => {
    // Send a POST request to bookmark the domain
    axios
      .post(
        apiUrl + "/domain-bookmarks",
        {
          data: {
            user_id: userData.id,
            domain_id: domainId,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Update the bookmarkedDomains state
        setBookmarkedDomains([...bookmarkedDomains, domainId.toString()]);
        setNotify("Domain Bookmarked!");
        setNotifyClass("success");

        // Hide the notification after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          setNotify(""); // Clear the notification message
          setNotifyClass(""); // Clear the notification class
        }, 3000);
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  const unbookmarkDomain = (bookmarkId, domainId) => {
    // Send a DELETE request to unbookmark the domain
    axios
      .delete(apiUrl + `/domain-bookmarks/${bookmarkId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        // Update the bookmarkedDomains state after a successful delete
        setBookmarkedDomains(
          bookmarkedDomains.filter((id) => id !== domainId.toString())
        );
        setNotify("Domain Unbookmarked!");
        setNotifyClass("danger");

        // Hide the notification after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          setNotify(""); // Clear the notification message
          setNotifyClass(""); // Clear the notification class
        }, 3000);
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  const isMobileScreen = window.innerWidth <= 768;
  const totalPages = Math.ceil(totalRecords / totalRows);
  const startPage = Math.max(
    1,
    Math.min(
      pageNumber - (isMobileScreen ? 1 : 4),
      totalPages - (isMobileScreen ? 3 : 9)
    )
  );
  const endPage = Math.min(
    totalPages,
    Math.max(isMobileScreen ? 3 : 10, pageNumber + (isMobileScreen ? 2 : 5))
  );
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  function formatNumber(number) {
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] || "";

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return `${formattedInteger}${decimalPart ? `.${decimalPart}` : ""}`;
  }

  return (
    <div className="card">
      <h5
        className="card-header"
        style={{ paddingLeft: "0px", paddingBottom: "10px" }}
      >
        {/* {showSpinner !== 0 ? (
          <span
            className="fas fa-spinner fa-spin text-danger"
            style={{ marginRight: "5px" }}
          ></span>
        ) : (
          ""
        )} */}
        Domains List (<i>{totalRecords}</i> found!)
        <small style={{ float: "right" }}>
          Showing{" "}
          <i>
            {(pageNumber - 1) * 100} -{" "}
            {pageNumber === 1 && totalRecords <= totalRows
              ? totalRecords
              : pageNumber * totalRows}{" "}
            of {totalRecords}
          </i>
        </small>
      </h5>
      {notify !== "" ? (
        <div
          className="bs-toast toast fade show toast-error"
          style={{
            position: "fixed",
            top: "100px",
            right: "0px",
            zIndex: "10",
          }}
        >
          <div className={`toast-body`}>
            {notifyClass == "success" ? (
              <i className="ti ti-checks text-success"></i>
            ) : (
              <i className="ti ti-square-rounded-x text-danger"></i>
            )}{" "}
            <strong>{notify}</strong>
          </div>
        </div>
      ) : null}
      <div className="text-nowrap company-table">
        <small className="text-danger">
          <i>Click on column name to sort the table</i>
        </small>
        <table className="table table-hover table-bordered table-striped">
          <thead>
            <tr>
              <th onClick={() => handleSort("web")} style={{ width: "10%" }}>
                Domain {renderSortingArrow("web")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("category")}
              >
                Category {renderSortingArrow("category")}
              </th>
              <th className="hide-tab-mob" onClick={() => handleSort("title")}>
                Title {renderSortingArrow("title")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("country")}
                style={{ width: "10%" }}
              >
                Country {renderSortingArrow("country")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("size")}
                style={{ width: "10%" }}
              >
                Size {renderSortingArrow("size")}
              </th>
              <th
                className="hide-tab-mob"
                onClick={() => handleSort("backlinks")}
                style={{ width: "10%" }}
              >
                Backlinks {renderSortingArrow("backlinks")}
              </th>
              <th style={{ width: "10%" }}>
                <span className="hide-tab-mob">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {sortedDomains
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((domain) => (
                <tr key={domain.id}>
                  <td>
                    <span className="fw-medium ">
                      <i style={{marginRight:"5px"}}><img style={{width:"25px", height:"25px"}} src={domain.attributes.favicon ? domain.attributes.favicon.split(';')[0] : ''}/></i>
                      {domain.attributes && domain.attributes.web}
                    </span>
                  </td>
                  <td className="hide-tab-mob truncate-text">
                    {domain.attributes && domain.attributes.category}
                  </td>
                  <td className="hide-tab-mob truncate-text">
                    {domain.attributes && domain.attributes.title}
                  </td>
                  <td className="hide-tab-mob">
                    <span className="me-1 ">
                      <ReactCountryFlag
                        countryCode={getCountryCode(
                          domain.attributes && domain.attributes.country
                        )}
                        svg
                        style={{ width: "20px", marginRight: "5px" }}
                      />
                      {i18nIsoCountries.getAlpha2Code(
                        domain.attributes && domain.attributes.country,
                        "en"
                      )}
                      {/* {domain.attributes && domain.attributes.country} */}
                    </span>
                  </td>
                  <td className="hide-tab-mob">
                    {domain.attributes &&
                    domain.attributes.crawled_pages === "Giant" ? (
                      <span className="badge bg-label-success me-1 ">
                        {domain.attributes && domain.attributes.crawled_pages}
                      </span>
                    ) : (
                      ""
                    )}
                    {domain.attributes &&
                    domain.attributes.crawled_pages ===
                      "Very Large" ? (
                      <span className="badge bg-label-primary me-1 ">
                        {domain.attributes && domain.attributes.crawled_pages}
                      </span>
                    ) : (
                      ""
                    )}
                    {domain.attributes &&
                    domain.attributes.crawled_pages === "Large" ? (
                      <span className="badge bg-label-info me-1 ">
                        {domain.attributes && domain.attributes.crawled_pages}
                      </span>
                    ) : (
                      ""
                    )}
                    {domain.attributes &&
                    domain.attributes.crawled_pages === "Medium Sized" ? (
                      <span className="badge bg-label-warning me-1 ">
                        {domain.attributes && domain.attributes.crawled_pages}
                      </span>
                    ) : (
                      ""
                    )}
                    {domain.attributes &&
                    domain.attributes.crawled_pages === "Small" ? (
                      <span className="badge bg-label-danger me-1 ">
                        {domain.attributes && domain.attributes.crawled_pages}
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="hide-tab-mob text-right">
                    <span className="me-1">
                      {domain.attributes &&
                        formatNumber(domain.attributes.backlinks)}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex">
                      <Link
                        style={{ width: "25px", height: "25px" }}
                        to={`/domains/${domain.id}`}
                        className="btn btn-purple text-primary btn-icon"
                        title="View"
                      >
                        <i className="ti ti-eye"></i>
                      </Link>
                      {!canBm ? (
                        ""
                      ) : (
                        <button
                          style={{
                            marginLeft: "10px",
                            width: "25px",
                            height: "25px",
                          }}
                          onClick={() => toggleBookmark(domain.id)}
                          title={
                            isDomainBookmarked(domain.id)
                              ? "Unbookmark"
                              : "Bookmark"
                          }
                          className={`btn ${
                            domainBookmarks[domain.id]
                              ? "btn-primary"
                              : "btn-purple"
                          } btn-icon ${
                            domainBookmarks[domain.id]
                              ? "text-white"
                              : "text-secondary"
                          }`}
                        >
                          <i className={`ti ti-bookmark`}></i>
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="row mb-3">
        {isMobileScreen ? (
          ""
        ) : (
          <div className="col-md-6 float-left">
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>
            </select>
          </div>
        )}
        <div
          className={
            isMobileScreen
              ? "text-center"
              : "col-md-6 float-right-pagination float-right"
          }
        >
          {isMobileScreen && (
            <select
              id="dropdown"
              value={tableRows}
              onChange={handleTableRowsChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <option value={20} selected={totalRows === 20}>
                20
              </option>
              <option value={50} selected={totalRows === 50}>
                50
              </option>
              <option value={75} selected={totalRows === 75}>
                75
              </option>
              <option value={100} selected={totalRows === 100}>
                100
              </option>{" "}
            </select>
          )}

          {pageNumber == 1 ? (
            ""
          ) : (
            <button className="btn btn-primary" onClick={handlePrevPage}>
              &laquo; {isMobileScreen ? "" : "Prev"}
            </button>
          )}

          {pageNumbers.map((page) => (
            <a
              style={{ fontSize: "13px", marginRight: "2px" }}
              key={page}
              className={`btn rounded-pill btn-icon btn-label-primary waves-effect ${
                page === pageNumber ? "active" : ""
              }`}
              href={`/domains?p=${page}`}
            >
              {page}
            </a>
          ))}

          {allowNext ? (
            <button
              style={{ marginLeft: "13px" }}
              className="btn btn-primary"
              onClick={handleNextPage}
            >
              {isMobileScreen ? "" : "Next"} &raquo;
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default DomainList;
