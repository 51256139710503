import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import SiteLogo from "../images/logo.png";
import authImage from "../assets/img/illustrations/auth-login-illustration-light.png";
import bgShapeImageLight from "../assets/img/illustrations/bg-shape-image-light.png";
import axios from "axios";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/css/pages/page-auth.css";
import "../assets/vendor/libs/@form-validation/umd/styles/index.min.css";
import "../assets/vendor/css/pages/front-page-landing.css";
// import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
// import "./assets/vendor/libs/typeahead-js/typeahead.css";
// import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/swiper/swiper.css";
import { Link, json } from "react-router-dom";
export default class SiteLogin extends Component {
  constructor(props) {
    super(props);
    //this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.saveLoginDetails = this.saveLoginDetails.bind(this);
    this.fetchPermissions = this.fetchPermissions.bind(this);
    this.state = {
      userName: "",
      passWord: "",
      c1: Math.floor(Math.random() * 10),
      c2: Math.floor(Math.random() * 10),
      captchaAns: "",
      loginErr: "",
      registered: localStorage.getItem("registered"),
      activated: false,
      authenticated: localStorage.getItem("authenticated"),
      myCredits: 50,
      showPassword: false,
      newReg: 0,
      isButtonDisabled: false,
    };
  }
  componentDidMount() {
    //console.log("Registered " + this.state.registered);
    //var verifyAffId = this.getQueryVariable("verify");
    // if (verifyAffId != "") {
    //   this.activateAffiliate(verifyAffId);
    // }
    if (this.state.authenticated === "true") {
      window.location = "companies";
      return false;
    }
  }
  async fetchPermissions(userToken, apiUrl, selectedPlan, loggedUserId) {
    try {
      //console.log("Permissions for user plan: " + selectedPlan);
      const response = await axios.get(apiUrl + "/plan-permissions?populate=*", {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });
  
      const jsonResponse = response.data.data;
      //console.log(JSON.stringify(jsonResponse));
  
      // loop the data
      jsonResponse.forEach((item) => {
        const permissionTitle = item.attributes.permission_title;
        const plansData = item.attributes.plans.data;
        let isUserPlanAvailable = false; // Initialize as false
  
        plansData.forEach((planItem) => {
          console.log("planItem id is " + planItem.id + " & selectedPlan is " + selectedPlan)
          if (planItem.id == selectedPlan) {
            console.log("planItem id is " + planItem.id)
            isUserPlanAvailable = true; // Set to true if the plan is found
          }
        });
  
        // Do something with the permissions for each plan
        if (isUserPlanAvailable) {
          console.log(`PermissionTrue: ${permissionTitle} - true`);
          localStorage.setItem(`${permissionTitle}`, true);
        } else {
          console.log(`PermissionFalse: ${permissionTitle} - false`);
          localStorage.setItem(`${permissionTitle}`, false);
        }
      });
    } catch (error) {
      // Handle error.
      console.error("Error fetching permissions:", error);
    }
  }
  async fetchUserCurrentPlan(userToken, apiUrl, userPlanId) {
    try {
      const response = await axios.get(apiUrl + "/membership-plans?filters[id][$eq]=" + userPlanId, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });
  
      const jsonResponse = response.data.data;
      //console.log("My plan is : " + JSON.stringify(jsonResponse));
      localStorage.setItem("userCurrentPlanData", JSON.stringify(jsonResponse[0].attributes));
      localStorage.setItem("planViewCountLimit", jsonResponse[0].attributes.views_limit);
    } catch (error) {
      // Handle error.
      console.error("Error fetching current plan:", error);
    }
  }
  
  async fetchUserPlan(userToken, apiUrl, loggedUserId) {
    try {
      const response = await axios.get(
        apiUrl + "/scorers?filters[user_id][$eq]=" + loggedUserId,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const jsonResponse = response.data.data;
      localStorage.setItem("userMemData", JSON.stringify(jsonResponse[0].attributes));
      localStorage.setItem("userMembershipId", JSON.stringify(jsonResponse[0].id));
      localStorage.setItem("userPlanId", jsonResponse[0].id);
      localStorage.setItem("userSelPlanId", jsonResponse[0].attributes.plan_id);
      localStorage.setItem("viewCount", jsonResponse[0].attributes.views_limit);
      localStorage.setItem("viewCountStartDate", jsonResponse[0].attributes.views_limit_start_date);
      localStorage.setItem("userCredits", jsonResponse[0].attributes.credits);
      
      await this.fetchUserCurrentPlan(
        userToken,
        apiUrl,
        jsonResponse[0].attributes.plan_id,
      );
      
      await this.fetchPermissions(
        userToken,
        apiUrl,
        jsonResponse[0].attributes.plan_id,
        loggedUserId
      );
    } catch (error) {
      // Handle error.
      console.error("An error occurred in fetchUserPlan:", error);
    }
  }
  getQueryVariable(ref) {
    var query = window.location.search.substring(1);
    //console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    //console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      //console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == ref) {
        return pair[1];
      }
    }
    return false;
  }
  onChangeUsername(e) {
    this.setState({
      userName: e.target.value,
    });
  }
  onChangePassword(e) {
    this.setState({
      passWord: e.target.value,
    });
  }
  async saveLoginDetails() {
    this.setState({
      registered: "",
      isButtonDisabled: true
    });
    if (this.state.userName === "") {
      this.setState({
        loginErr: "Username required!",
      });
      return false;
    }
    if (this.state.passWord === "") {
      this.setState({
        loginErr: "Password required!",
      });
      return false;
    }
    var data = {
      userName: this.state.userName,
      passWord: this.state.passWord,
    };
    const apiUrl = AffiliateDataService.baseURL;
    try {
      // Request API.
      const response = await axios.post(apiUrl + "/auth/local", {
        identifier: data.userName,
        password: data.passWord,
      });
      // Handle non verified user
      if (response.data.user.confirmed === false) {
        this.setState({
          loginErr: "Please verify your email address!",
        });
        return false;
      }

      // Handle non verified user
      if (response.data.user.confirmed === false) {
        this.setState({
          loginErr: "Please verify your email address!",
        });
        return false;
      }
      
      // Handle success.
      if (response.data.jwt !== null) {
        // check if the free plan is created already or not
        const scorers = await axios.get(apiUrl + "/scorers?filters[user_id][$eq]=" + response.data.user.id, {
          headers: {
            Authorization: `Bearer ${response.data.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        if (scorers.data.data.length === 0) {
          // create free plan
          const freePlan = await axios.post(
            apiUrl + '/scorers',
            {
              data: {
                user_id: response.data.user.id,
                plan_id: 1,
                txn_id:Math.floor(Math.random() * 1000000).toString().padStart(6, '0'),
                txn_amount: 0,
                status: 'active'
              },
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.jwt}`,
                'Content-Type': 'application/json',
              },
            }
          )
          if (freePlan.data.id) {
            console.log('Free plan created successfully');
          }
        }
        this.setState({
          userName: response.userName,
          passWord: response.passWord,
          submitted: true,
        });
        localStorage.setItem("authenticated", true);
        localStorage.setItem("userName", response.data.user.username);
        localStorage.setItem("userToken", response.data.jwt);
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        //console.log("User Data : " + JSON.stringify(response.data));
        // Get user membership plan
        await this.fetchUserPlan(response.data.jwt, apiUrl, response.data.user.id);
        // Redirect to dashboard
        setTimeout(() => {
          window.location.href = "companies";
        }, 1000);
      } else {
        this.setState({
          loginErr: response.data.error.message,
          isButtonDisabled: false
        });
      }
    } catch (error) {
      // Handle error.
      this.setState({
        loginErr: error.response.data.error.message,
      });
      console.log("An error occurred:", error.response.data.error.message);
    }
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  render() {
    const { activated, registered, loginErr, captchaAns, c1, c2, newReg } = this.state;
    return (
      <div className="light-style customizer-hide">
        <div className="authentication-wrapper authentication-cover authentication-bg">
          <div className="authentication-inner row">
            <div className="d-none d-lg-flex col-lg-7 p-0">
              <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                <img
                  src={authImage}
                  alt="auth-login-cover"
                  className="img-fluid my-5 auth-illustration"
                  data-app-light-img="illustrations/auth-login-illustration-light.png"
                  data-app-dark-img="illustrations/auth-login-illustration-dark.png"
                />
                <img
                  src={bgShapeImageLight}
                  alt="auth-login-cover"
                  className="platform-bg"
                  data-app-light-img="illustrations/bg-shape-image-light.png"
                  data-app-dark-img="illustrations/bg-shape-image-dark.png"
                />
              </div>
            </div>
            <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <div className="app-brand mb-4">
                  <a href="index.html" className="app-brand-link gap-2">
                    <span className="app-brand-logo demo">
                      <svg
                        width="32"
                        height="22"
                        viewBox="0 0 32 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      ></svg>
                    </span>
                  </a>
                </div>
                <h3 className="mb-1 fw-bold">Welcome to Scoring! 👋</h3>
                {newReg ? (
                  <div className="alert alert-success">Your account has been created successfully! Sign in to continue...</div>
                ) : (
                  ""
                )}
                {loginErr !== "" ? (
                  <h5 className="text text-danger">{loginErr}</h5>
                ) : (
                  ""
                )}
                <p className="mb-4">
                  Please sign in to your account and start the adventure
                </p>
                {/* <form id="formAuthentication" className="mb-3" action="index.html" method="POST"> */}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email or Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    required
                    value={this.state.userName}
                    onChange={this.onChangeUsername}
                    name="userName"
                    autoFocus
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Link to="/forgot-password">
                      <small>Forgot Password?</small>
                    </Link>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control"
                      id="passWord"
                      required
                      value={this.state.passWord}
                      onChange={this.onChangePassword}
                      name="passWord"
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={this.togglePasswordVisibility}
                    >
                      <i
                        className={`ti ${
                          this.state.showPassword ? "ti-eye" : "ti-eye-off"
                        }`}
                      ></i>
                    </span>{" "}
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember-me"
                    />
                    <label className="form-check-label" htmlFor="remember-me">
                      {" "}
                      Remember Me{" "}
                    </label>
                  </div>
                </div>
                <button
                  onClick={this.saveLoginDetails}
                  className="btn btn-primary d-grid w-100"
                  disabled={this.state.isButtonDisabled}
                >
                  Sign in
                </button>
                {/* </form> */}
                <p className="text-center mt-3">
                  <span>New on our platform?</span>
                  <Link to="/sign-up">
                    <span>Create an account</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}