import React from "react";
import RankChart from "../domains/chart";
import OPRank from "../domains/op";
import MajesticChart from "../domains/majestic";

const DomainReputation = ({ domain }) => {

  return (
    <div className="row">
      <h4 className="py-3 mb-4">
        Domain Reputation
      </h4>
      <RankChart
        domain={domain}
      ></RankChart>
      <OPRank domain={domain}></OPRank>
      <MajesticChart domain={domain}></MajesticChart>
      
    </div>
  );
};

export default DomainReputation;
