import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import SiteLogo from "../images/logo.png";
import authImage from "../assets/img/illustrations/auth-login-illustration-light.png";
import bgShapeImageLight from "../assets/img/illustrations/bg-shape-image-light.png";
import axios from "axios";

import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/css/pages/page-auth.css";
import "../assets/vendor/libs/@form-validation/umd/styles/index.min.css";
import "../assets/vendor/css/pages/front-page-landing.css";
// import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
// import "./assets/vendor/libs/typeahead-js/typeahead.css";
// import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/swiper/swiper.css";
import { Link } from "react-router-dom";

export default class SiteResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);

    this.state = {
      passWord: "",
      token: "",
      myCredits: 50,
      loginErr:"",
      loginSuccess:""
    };
  }

  componentDidMount() {
    var userToken = this.getQueryVariable('code');
    if(userToken != ''){
      this.setState({
        token: userToken
      });
    }
    else {
      this.setState({
        loginErr: "Token missing!"
      });
    }
  }

  getQueryVariable(ref) {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] == ref) { return pair[1]; }
    }
    return (false);
  }

  onChangePassword(e) {
    this.setState({
      passWord: e.target.value
    });
  }

  updatePassword() {

    if (this.state.passWord === "") {
      this.setState({
        loginErr: "Password required!"
      });
      return false;
    }

    const apiUrl = AffiliateDataService.baseURL;
    // Request API.
    axios
    .post(apiUrl + '/auth/reset-password', {
      code: this.state.token, // code contained in the reset link of step 3.
      password: this.state.passWord,
      passwordConfirmation: this.state.passWord,
    })
    .then(response => {
      console.log("Your user's password has been reset.");
      this.setState({
        loginSuccess: "Password has been updated successfully!"
      });
      window.location.href="/signin"
    })
    .catch(error => {
      console.log('An error occurred:', error.response);
      this.setState({
        loginErr: 'An error occurred:' + error.response
      });
    });
  }

  render() {
    const { loginErr, loginSuccess } = this.state;
    return (
      <div className="light-style customizer-hide">
        <div className="authentication-wrapper authentication-cover authentication-bg">
          <div className="authentication-inner row">
            <div className="d-none d-lg-flex col-lg-7 p-0">
              <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                <img
                  src={authImage}
                  alt="auth-login-cover"
                  className="img-fluid my-5 auth-illustration"
                  data-app-light-img="illustrations/auth-login-illustration-light.png"
                  data-app-dark-img="illustrations/auth-login-illustration-dark.png"
                />

                <img
                  src={bgShapeImageLight}
                  alt="auth-login-cover"
                  className="platform-bg"
                  data-app-light-img="illustrations/bg-shape-image-light.png"
                  data-app-dark-img="illustrations/bg-shape-image-dark.png"
                />
              </div>
            </div>
            <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <div className="app-brand mb-4">
                  <a href="index.html" className="app-brand-link gap-2">
                    <span className="app-brand-logo demo">
                      <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      </svg>
                    </span>
                  </a>
                </div>
                <h3 className="mb-1 fw-bold">Reset Your Password?</h3>
                {loginErr !== "" ? <h5 className="text text-danger">{loginErr}</h5> : ""}
                {loginSuccess !== "" ? <h5 className="text text-success">{loginSuccess}</h5> : ""}

                {/* <form id="formAuthentication" className="mb-3" action="index.html" method="POST"> */}
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">New Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="passWord"
                    required
                    value={this.state.passWord}
                    onChange={this.onChangePassword}
                    name="passWord"
                    autoFocus
                  />
                </div>
                <button onClick={this.updatePassword} className="btn btn-primary d-grid w-100">Submit</button>
                {/* </form> */}

                <p className="text-center mt-3">
                  <span>Already have an account?</span>
                  <Link to="/signin">
                    <span>Sign In</span>
                  </Link>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
