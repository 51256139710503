import React, { Component } from "react";
import SiteLogo from "../images/logo.png";
import { Link } from "react-router-dom";

export default class SiteHeader extends Component {
  render() {
    return (
      <div className="row align-items-center">
        <div className="col-md-2">
          <div className="logo">
            <a href="#"><img src={SiteLogo} alt="logo" className="img-fluid" /></a>
          </div>
        </div>
        <div className="col-md-10">
          <div className="top-bar">
            <h1>Welcome, {this.props.userName}</h1>
            <ul>
              <li><Link to="/logout">Logout</Link></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
