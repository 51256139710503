import React, { Component } from "react";

export default class SiteTabs extends Component {
  render() {
    return (
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Dashboard</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#all-orders" role="tab" aria-controls="profile" aria-selected="false">All Orders</a>
        </li>
        { this.props.userRole == 2 ? 
        <li className="nav-item">
          <a className="nav-link" id="contact-tab" data-toggle="tab" href="#all-users" role="tab" aria-controls="contact" aria-selected="false">All Users</a>
        </li>
        : "" }
        <li className="nav-item">
          <a className="nav-link" id="requests-tab" data-toggle="tab" href="#all-requests" role="tab" aria-controls="requests" aria-selected="false">All Payout Requests</a>
        </li>
      </ul>
    );
  }
}
