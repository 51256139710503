import React from "react";
import TooltipComp from "./tooltips";

const FinancialIndicators = ({ company, authenticated, openFreeAccModal }) => {
  const valOne = (company.ca_an5 / 1000000);
  const revVal = Math.round(valOne * 10) / 10;

  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">Financial Indicators</h5>
            {/* <small className="text-muted">8.52k Social Visitors</small> */}
          </div>
        </div>
        <div className="card-body">
          <ul className="p-0 m-0">
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-info rounded p-2">
                <i className="ti ti-mail ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Year</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {company.ref_year !== "NULL" ? (
                      company.ref_year
                    ) : (
                      <TooltipComp
                        tooltipText="Unavailable data, please try again in some time!"
                        visibleText="Not Available"
                        tooltipWidth="300px"
                      />
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-info rounded p-2">
                <i className="ti ti-link ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Revenue</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {company.ca_an5 > company.ca_an4 ? (
                      <i className="ti ti-arrow-up text-success ti-sm"></i>
                    ) : (
                      <i className="ti ti-arrow-down text-danger ti-sm"></i>
                    )}
                    {revVal + " (Mn. EUR)"}{" "}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-info rounded p-2">
                <i className="ti ti-click ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Employees</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {company.nr_sal_an5 > company.nr_sal_an4 ? (
                      <i className="ti ti-arrow-up text-success ti-sm"></i>
                    ) : (
                      <i className="ti ti-arrow-down text-danger ti-sm"></i>
                    )}
                    {company.nr_sal_an5}{" "}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-info rounded p-2">
                <i className="ti ti-click ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">ROS</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {authenticated ? (
                      company.mpn_an5 > company.mpn_an4 ? (
                        <i className="ti ti-arrow-up text-success ti-sm"></i>
                      ) : (
                        <i className="ti ti-arrow-down text-danger ti-sm"></i>
                      )
                    ) : (
                      <a
                        onClick={openFreeAccModal}
                        className="btn btn-outline-danger waves-effect"
                        href="#"
                        style={{ display: "inline-block" }}
                      >
                        Get Free Account
                      </a>
                    )}
                    {authenticated ? company.mpn_an5 + "%" : ""}{" "}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-info rounded p-2">
                <i className="ti ti-click ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">ROA</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {authenticated ? (
                      company.ROA_an5 > company.ROA_an4 ? (
                        <i className="ti ti-arrow-up text-success ti-sm"></i>
                      ) : (
                        <i className="ti ti-arrow-down text-danger ti-sm"></i>
                      )
                    ) : (
                      <a
                        onClick={openFreeAccModal}
                        className="btn btn-outline-danger waves-effect"
                        href="#"
                        style={{ display: "inline-block" }}
                      >
                        Get Free Account
                      </a>
                    )}
                    {authenticated ? company.ROA_an5 + "%" : ""}{" "}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4 pb-1 d-flex justify-content-between align-items-center">
              <div className="badge bg-label-info rounded p-2">
                <i className="ti ti-click ti-sm"></i>
              </div>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                <h6 className="mb-0 ms-3">Receivables Turnover</h6>
                <div className="d-flex">
                  <p className="mb-0 fw-medium">
                    {authenticated ? (
                      company.rot_st_an5 > company.rot_st_an4 ? (
                        <i className="ti ti-arrow-up text-success ti-sm"></i>
                      ) : (
                        <i className="ti ti-arrow-down text-danger ti-sm"></i>
                      )
                    ) : (
                      <a
                        onClick={openFreeAccModal}
                        className="btn btn-outline-danger waves-effect"
                        href="#"
                        style={{ display: "inline-block" }}
                      >
                        Get Free Account
                      </a>
                    )}
                    {authenticated ? company.rot_st_an4 + " Days" : ""}{" "}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FinancialIndicators;
