import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const IndeptnessChart = ({ company, authenticated, openFreeAccModal }) => {
  const chartOptions = {
    chart: {
      type: "column",
      height: 200,
    },
    legend: {
      enabled: false, // Hide the legends
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> Indeptness: <b>{point.y} (%)</b><br/>',
    },
    xAxis: {
      categories: ["2018", "2019", "2020", "2021", "2022"],
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    series: [
      {
        data: [
          parseInt(company.gr_ind_an1),
          parseInt(company.gr_ind_an2),
          parseInt(company.gr_ind_an3),
          parseInt(company.gr_ind_an4),
          parseInt(company.gr_ind_an5),
        ],
        color: "green",
      },
    ],
  };

  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card" style={{ height: "305px" }}>
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">Indebtedness (%)</h5>
          </div>
        </div>
        <div className="card-body pt-2 text-center">
          {authenticated ? (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          ) : (
            <a onClick={openFreeAccModal}
            className="btn btn-outline-danger waves-effect"
            href="#"
            style={{ display: "inline-block", marginTop:"13%" }}
          >
            Get Free Account
          </a>

          )}
        </div>
      </div>
    </div>
  );
};

export default IndeptnessChart;
