import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

const TechnicalInfo = ({ domain }) => {
  const [showMore, setShowMore] = useState(false);
  const maxTextLength = 25; // Set your desired maximum text length here

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const domainTech = domain.technologies;
  return (
    <div>
      <div className="row">
        <h4 className="py-3 mb-4">Technical Info</h4>
        <div className="col-sm-6 col-lg-4 mb-4">
          <div className="card card-border-shadow-primary">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-primary">
                    <i className="ti ti-garden-cart ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0" style={{ fontSize: "18px", width:"100%" }}>
                  E-Commerce
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right" className="custom-tooltip">Card details will appear here...</Tooltip>
                    }
                    
                  >
                    <i style={{float:"right", color:"#c57f00"}} className="ti ti-exclamation-circle"></i>
                  </OverlayTrigger>{" "}
                </h4>
              </div>
              <p className="mb-1" style={{ fontSize: "13px" }}>
                {!domain.e_commerce || domain.e_commerce.length === 0 ? (
                  <small style={{ fontSize: "13px" }}>
                    Unavailable data, please try again in some time!
                  </small>
                ) : (
                  domain.e_commerce
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 mb-4">
          <div className="card card-border-shadow-warning">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-warning">
                    <i className="ti ti-brand-google-analytics ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0"  style={{ fontSize: "18px", width:"100%" }}>
                  Analytics
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right" style={{ maxWidth: '200px' }}>Card details will appear here...</Tooltip>
                    }
                    
                  >
                    <i style={{float:"right", color:"#c57f00"}} className="ti ti-exclamation-circle"></i>
                  </OverlayTrigger>{" "}
                </h4>
              </div>
              <p className="mb-1" style={{ fontSize: "13px" }}>
                {!domain.analytics_and_tracking ||
                domain.analytics_and_tracking.length === 0 ? (
                  <small style={{ fontSize: "13px" }}>
                    Unavailable data, please try again in some time!
                  </small>
                ) : (
                  domain.analytics_and_tracking
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 mb-4">
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <i className="ti ti-database ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0"  style={{ fontSize: "18px", width:"100%" }}>
                  Database
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-left" style={{ maxWidth: '200px' }}>Card details will appear here...</Tooltip>
                    }
                    
                  >
                    <i style={{float:"right", color:"#c57f00"}} className="ti ti-exclamation-circle"></i>
                  </OverlayTrigger>{" "}
                </h4>
              </div>
              <p className="mb-1" style={{ fontSize: "13px" }}>
                {!domain.database || domain.database.length === 0 ? (
                  <small style={{ fontSize: "13px" }}>
                    Unavailable data, please try again in some time!
                  </small>
                ) : (
                  domain.database
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-lg-4 mb-4">
          <div className="card card-border-shadow-primary">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-primary">
                    <i className="ti ti-code-dots ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0"  style={{ fontSize: "18px", width:"100%" }}>
                  Programming Languages
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right" style={{ maxWidth: '200px' }}>Card details will appear here...</Tooltip>
                    }
                    
                  >
                    <i style={{float:"right", color:"#c57f00"}} className="ti ti-exclamation-circle"></i>
                  </OverlayTrigger>{" "}
                </h4>
              </div>
              <p className="mb-1" style={{ fontSize: "13px" }}>
                {!domain.programming_languages ||
                domain.programming_languages.length === 0 ? (
                  <small style={{ fontSize: "13px" }}>
                    Unavailable data, please try again in some time!
                  </small>
                ) : (
                  domain.programming_languages
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 mb-4">
          <div className="card card-border-shadow-warning">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-warning">
                    <i className="ti ti-server-2 ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0"  style={{ fontSize: "18px", width:"100%" }}>
                  Web Servers
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right" style={{ maxWidth: '200px' }}>Card details will appear here...</Tooltip>
                    }
                    
                  >
                    <i style={{float:"right", color:"#c57f00"}} className="ti ti-exclamation-circle"></i>
                  </OverlayTrigger>{" "}
                </h4>
              </div>
              <p className="mb-1" style={{ fontSize: "13px" }}>
                {!domain.web_servers || domain.web_servers.length === 0 ? (
                  <small style={{ fontSize: "13px" }}>
                    Unavailable data, please try again in some time!
                  </small>
                ) : (
                  domain.web_servers
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 mb-4">
          <div className="card card-border-shadow-danger">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2 pb-1">
                <div className="avatar me-2">
                  <span className="avatar-initial rounded bg-label-danger">
                    <i className="ti ti-git-fork ti-md"></i>
                  </span>
                </div>
                <h4 className="ms-1 mb-0"  style={{ fontSize: "18px", width:"100%" }}>
                  Technologies
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-left" style={{ maxWidth: '200px' }}>Card details will appear here...</Tooltip>
                    }
                    
                  >
                    <i style={{float:"right", color:"#c57f00"}} className="ti ti-exclamation-circle"></i>
                  </OverlayTrigger>{" "}
                </h4>
              </div>
              {showMore ? (
                <p style={{ float: "left", fontSize: "13px" }} className="mb-1">
                  {!domainTech || domainTech.length === 0 ? (
                    <small style={{ fontSize: "13px" }}>
                      Unavailable data, please try again in some time!
                    </small>
                  ) : (
                    domainTech
                  )}
                </p>
              ) : (
                <p style={{ float: "left", fontSize: "13px" }} className="mb-1">
                  {String(domainTech).length > maxTextLength ? (
                    `${domainTech.slice(0, maxTextLength)}...`
                  ) : !domainTech || domainTech.length === 0 ? (
                    <small style={{ fontSize: "13px" }}>
                      Unavailable data, please try again in some time!
                    </small>
                  ) : (
                    domainTech
                  )}{" "}
                </p>
              )}
              {String(domainTech).length > maxTextLength && (
                <button
                  onClick={toggleShowMore}
                  className="btn btn-sm"
                  style={{ float: "right", boxShadow: "none" }}
                >
                  {showMore ? "Show Less" : "Show More"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalInfo;
