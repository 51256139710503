import React, { Component } from "react";
import AffiliateDataService from "../services/affiliate.service";
import SiteLogo from "../images/logo.png";
import authImage from "../assets/img/illustrations/auth-login-illustration-light.png";
import bgShapeImageLight from "../assets/img/illustrations/bg-shape-image-light.png";
import axios from "axios";

import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/css/pages/page-auth.css";
import "../assets/vendor/libs/@form-validation/umd/styles/index.min.css";
import "../assets/vendor/css/pages/front-page-landing.css";
// import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
// import "./assets/vendor/libs/typeahead-js/typeahead.css";
// import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/swiper/swiper.css";
import { Link } from "react-router-dom";

export default class SiteForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.saveEmail = this.saveEmail.bind(this);

    this.state = {
      userName: "",
      myCredits: 50,
      emailSent: "",
    };
  }

  componentDidMount() {
    //console.log("Registered " + this.state.registered);
  }

  getQueryVariable(ref) {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] == ref) { return pair[1]; }
    }
    return (false);
  }

  onChangeEmail(e) {
    this.setState({
      userName: e.target.value
    });
  }

  saveEmail() {

    if (this.state.userName === "") {
      this.setState({
        loginErr: "Email required!"
      });
      return false;
    }

    const apiUrl = AffiliateDataService.baseURL;
    // Request API.
    axios
    .post(apiUrl + '/auth/forgot-password', {
      email: this.state.userName, // user's email
    })
    .then(response => {
      console.log('Your user received an email');
      if (response.data.ok){
        this.setState({
          emailSent: this.state.userName,
        });
      }      
    })
    .catch(error => {
      console.log('An error occurred:', error.response);
    });

  }

  render() {
    const { loginErr, emailSent } = this.state;
    return (
      <div className="light-style customizer-hide">
        <div className="authentication-wrapper authentication-cover authentication-bg">
          <div className="authentication-inner row">
            <div className="d-none d-lg-flex col-lg-7 p-0">
              <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                <img
                  src={authImage}
                  alt="auth-login-cover"
                  className="img-fluid my-5 auth-illustration"
                  data-app-light-img="illustrations/auth-login-illustration-light.png"
                  data-app-dark-img="illustrations/auth-login-illustration-dark.png"
                />

                <img
                  src={bgShapeImageLight}
                  alt="auth-login-cover"
                  className="platform-bg"
                  data-app-light-img="illustrations/bg-shape-image-light.png"
                  data-app-dark-img="illustrations/bg-shape-image-dark.png"
                />
              </div>
            </div>
            <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
              <div className="w-px-400 mx-auto">
                <div className="app-brand mb-4">
                  <a href="index.html" className="app-brand-link gap-2">
                    <span className="app-brand-logo demo">
                      <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      </svg>
                    </span>
                  </a>
                </div>
                <h3 className="mb-1 fw-bold">Forgot Password?</h3>
                {loginErr !== "" ? <h5 className="text text-danger">{loginErr}</h5> : ""}

                <p className="mb-4">Please submit your email address to reset your password!</p>
                {emailSent !== "" ? <p className="text text-success">Account Reset link sent to your email address {emailSent}! Please follow the link inside to continue. </p> :
                  <p className="mb-4">Please submit your email address to reset your password!</p>
                }

                {/* <form id="formAuthentication" className="mb-3" action="index.html" method="POST"> */}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    required
                    value={this.state.userName}
                    onChange={this.onChangeEmail}
                    name="userName"
                    autoFocus
                  />
                </div>
                <button onClick={this.saveEmail} className="btn btn-primary d-grid w-100">Submit</button>
                {/* </form> */}

                <p className="text-center mt-3">
                  <span>Already have an account?</span>
                  <Link to="/signin">
                    <span>Sign In</span>
                  </Link>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
