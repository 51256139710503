import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ReceivablesTunoverChart = ({ company, authenticated, openFreeAccModal }) => { 
  const chartOptions = {
    chart: {
        type: 'column',
        height: 200,
    },
    legend: {
        enabled: false, // Hide the legends
      },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span> Receivables Tunover: <b>{point.y} (days)</b><br/>',
    },
    xAxis: {
      categories: ['2018', '2019', '2020', '2021', '2022'],
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: [
      {
        data: [parseInt(company.rot_cr_an1),parseInt(company.rot_cr_an2),parseInt(company.rot_cr_an3),parseInt(company.rot_cr_an4),parseInt(company.rot_cr_an5)],
        color: 'black',
      },
     ],

  };
  
  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card" style={{ height: "305px" }}>
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">Receivables Turnover (days)</h5>
          </div>
        </div>
        <div className="card-body pt-2 text-center">
        {authenticated ? (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          ) : (
            <a onClick={openFreeAccModal}
                className="btn btn-outline-danger waves-effect"
                href="#"
                style={{ display: "inline-block", marginTop:"13%" }}
              >
                Get Free Account
              </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReceivablesTunoverChart;
