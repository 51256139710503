import React, { Component, useState, useEffect } from "react";
import BookmarkCompaniesList from "./BookmarkCompaniesList";
import BookmarkDomainsList from "./BookmarkDomainsList";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom"; // Import useParams
import TopNavBar from "./TopNavBar";
import UserEmailsListTable from "./UserEmailListTable";

import AffiliateDataService from "../services/affiliate.service";
import "../assets/vendor/fonts/fontawesome.css";
import "../assets/vendor/fonts/tabler-icons.css";
import "../assets/vendor/fonts/flag-icons.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/css/demo.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/libs/node-waves/node-waves.css";
import "../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "../assets/vendor/libs/typeahead-js/typeahead.css";
import "../assets/vendor/libs/apex-charts/apex-charts.css";
import "../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
import "../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";
import "../assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css";
import "../assets/vendor/css/pages/cards-advance.css";
import CSVImport from "./csv-import";
import CommonFunctions from "../common/functions";

export default function EmailListHolder() {
  useEffect(() => {
    CommonFunctions.checkPermission("email_lists", 1);
  }, []);

  const [myCredits, setMyCredits] = useState(50);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [emailsListCount, setEmailsListCount] = useState([]);
  const [emailsList, setEmailsList] = useState([]);
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState("");
  const [allowNext, setAllowNext] = useState("");
  const [isMOMenuOpen, setIsMOMenuOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [countryFilter, setCountryFilter] = useState("");
  const [firmaFilter, setFirmaFilter] = useState("");
  const [industryFilter, setIndustryFilter] = useState("");
  const savedFilters = localStorage.getItem("filtersQuery");
  const [canVisitCompanyPage, setCanVisitCompanyPage] = useState("");
  const [planUpdateError, setPlanUpdateError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  //for auto complete
  //const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  // end for auto complete
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated")
  );
  
  let filterQuery = "";
  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const fetchEmailsList = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          console.log("No Companies Bookmarks found!");
        } else {
          setEmailsListCount(res.data.meta.pagination.total);
          setEmailsList(res.data.data);
          console.log(JSON.stringify(res.data.data));
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  const fetchEmailsListNextPage = (userToken, apiUrl) => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("next Page results " + res.data.length);
        if (res.data.data.length > 0) {
          console.log("Next page data : 1");
          setAllowNext(1);
        } else {
          console.log("Next page data : 0");
          setAllowNext(0);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const can_access_company_list = localStorage.getItem(
      "can_access_company_list"
    );
    // if(!can_access_company_list){
    //   navigate('/plans?package=na');
    // }
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = localStorage.getItem("userData");
    const userDataJSON = JSON.parse(userData);
    console.log("User ID: " + userDataJSON.id);

    //get page number
    const queryParams = getQueryParams(window.location.href);
    const pn = queryParams.p ? parseInt(queryParams.p) : 1;
    setPageNumber(pn);
    fetchUserCredits(userToken, apiUrl, userDataJSON.id);

    fetchEmailsList(
      userToken,
      apiUrl +
        "/user-email-lists?filters[user_id][$eq]=" +
        userDataJSON.id +
        "&pagination[page]=" +
        pn +
        "&pagination[pageSize]=50"
    );

    fetchEmailsListNextPage(
      userToken,
      apiUrl +
        "/user-email-lists?filters[user][id][$eq]=" +
        userDataJSON.id +
        "&pagination[page]=" +
        parseInt(pn + 1) +
        "&pagination[pageSize]=50"
    );
  }, [id]);
  const queryParams = getQueryParams(window.location.href);

  function capitalizeString(inputString, type) {
    // Convert the entire string to lowercase
    const lowercaseString = inputString.toLowerCase();
    if (type == "domain") {
      return lowercaseString;
    } else {
      // Capitalize the first letter and concatenate it with the rest of the string
      return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
    }
  }

  const onChangeCountryFilter = (e) => {
    setCountryFilter(e.target.value);
    if (countryFilter && countryFilter.length >= 1) {
      setIsLoading(1);
      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const countryFilterFinal = capitalizeString(countryFilter, "country");

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(
          apiUrl +
            "/companies?fields[0]=country&filters[country][$contains]=" +
            countryFilterFinal,
          {
            headers,
          }
        )
        .then((response) => {
          const uniqueCountries = new Set();
          const responseData = response.data.data;
          responseData.forEach((item) => {
            const country = item.attributes.country;
            uniqueCountries.add(country);
          });

          const uniqueCountryArray = Array.from(uniqueCountries);

          setCountrySuggestions(uniqueCountryArray);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setCountrySuggestions([]);
      //setIsLoading(0);
    }
  };

  const onChangeFirmaFilter = (e) => {
    setFirmaFilter(e.target.value);
  };

  const onChangeIndFilter = (e) => {
    setIndustryFilter(e.target.value);
  };

  const fetchAutoCompleteCompanies = (userToken, apiUrl) => {
    //for auto compleet
    if (firmaFilter && firmaFilter.length >= 3) {
      setIsLoading(1);
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(apiUrl + "/companies?filters[company][$contains]=" + firmaFilter, {
          headers,
        })
        .then((response) => {
          console.log("Suggestions: " + JSON.stringify(response.data.data));
          setSuggestions(response.data.data);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setSuggestions([]);
      //setIsLoading(0);
    }
    //end - for auto complete
    //setIsLoading(0);
  };

  const resetFilters = () => {
    setCountryFilter(""); // Reset country filter
    setIndustryFilter(""); // Reset domain filter
    setFirmaFilter(""); // Reset category filter
    setSelectedEbitRanges([]); // Reset size range filter
    setSelectedEbitdaRanges([]); // Reset domain authority filter
    setSelectedEmployeeRanges([]);
    setSelectedRanges([]);
    setSelectedRevGrowthRanges([]);

    // Clear selected filter values from localStorage
    localStorage.removeItem("countryFilter");
    localStorage.removeItem("firmaFilter");
    localStorage.removeItem("industryFilter");
    localStorage.removeItem("selectedRanges");
    localStorage.removeItem("selectedEbitRanges");
    localStorage.removeItem("selectedEbitdaRanges");
    localStorage.removeItem("selectedEmployeeRanges");
    localStorage.removeItem("selectedRevGrowthRanges");

    localStorage.removeItem("filtersQuery");

    window.location.reload();
  };

  const handleFilters = () => {
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const filters = [];
    localStorage.setItem("filtersQuery", "");
    if (countryFilter) {
      filters.push(`filters[country][$contains]=${countryFilter}`);
    }
    if (firmaFilter) {
      filters.push(`filters[company][$contains]=${firmaFilter}`);
    }
    if (industryFilter) {
      filters.push(`filters[name_industry][$contains]=${industryFilter}`);
    }

    localStorage.setItem("countryFilter", countryFilter);
    localStorage.setItem("firmaFilter", firmaFilter);
    localStorage.setItem("industryFilter", industryFilter);

    if (selectedRanges.length > 0) {
      selectedRanges.forEach((range, index) => {
        filters.push(`filters[tunover_level_filter][$in][${index}]=${range}`);
      });
    }

    if (selectedRevGrowthRanges.length > 0) {
      const rgArray = createArray(selectedRevGrowthRanges);

      if (rgArray.length === 1) {
        filters.push(
          `filters[turnover_filter][$${rgArray[0][1]}]=${rgArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        rgArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${
              range[4]
            }][turnover_filter][$${range[1]}]=${range[0].replace(
              /[ %<>]/g,
              ""
            )}`
          );
        });
      }
    }

    //ebit range handler
    if (selectedEbitRanges.length > 0) {
      const ebitArray = createArray(selectedEbitRanges);

      if (ebitArray.length === 1) {
        filters.push(
          `filters[mpn_an5][$${ebitArray[0][1]}]=${ebitArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        ebitArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][mpn_an5][$${
              range[1]
            }]=${range[0].replace(/[ %<>]/g, "")}`
          );
        });
      }
    }

    if (selectedEbitdaRanges.length > 0) {
      const ebitdaArray = createArray(selectedEbitdaRanges);

      if (ebitdaArray.length === 1) {
        filters.push(
          `filters[EBITDA_an5][$${
            ebitdaArray[0][1]
          }]=${ebitdaArray[0][0].replace(/[ %<>]/g, "")}`
        );
      } else {
        ebitdaArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][EBITDA_an5][$${
              range[1]
            }]=${range[0].replace(/[ %<>]/g, "")}`
          );
        });
      }
    }

    function createArray(dataRange) {
      const resArray = [];

      let orVal = -1;
      dataRange.forEach((value) => {
        orVal = parseInt(orVal + 1);
        //console.log("Or value: " + orVal);
        if (value.includes("-")) {
          const [min, max] = value.split("-");
          resArray.push([min, "gte", "$and", orVal, 0]);
          resArray.push([max, "lte", "$and", orVal, 1]);
        } else if (value.includes(">")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "gt", "$and", orVal, 0]);
        } else if (value.includes("<")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "lt", "$and", orVal, 0]);
        }
      });

      return resArray;
    }

    // employee filter handler
    if (selectedEmployeeRanges.length > 0) {
      const empArray = createArray(selectedEmployeeRanges);

      if (empArray.length === 1) {
        filters.push(
          `filters[nr_sal_an5][$${empArray[0][1]}]=${empArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        empArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][nr_sal_an5][$${
              range[1]
            }]=${range[0].replace(/[ %<>]/g, "")}`
          );
        });
      }
    }

    filterQuery = filters.length > 0 ? "?" + filters.join("&") : "";
    localStorage.setItem("filtersQuery", filterQuery);
    console.log("Filter Query is: " + filterQuery);

    //if (id) {
    window.location.href = "/companies";
    //}
  };

  function getQueryParams(urlString) {
    const url = new URL(urlString);
    const queryParams = url.searchParams;
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const fetchUserCredits = (userToken, apiUrl, userId) => {
    const script = document.createElement("script");
    script.src = "http://localhost:1337/plugins/strapi-stripe/static/stripe.js";
    script.async = true;
    document.head.appendChild(script);

    axios
      .get(
        apiUrl +
          "/credits-histories?populate=*&_sort=created_at:desc&_limit=1&filters[user][id][$eq]=" +
          userId +
          "&_limit=1",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.length === 0) {
          console.log("User credits history not found!");
        } else {
          const creditsLeft = res.data.data[0].attributes.creditsLeft;
          console.log("User credits history found!" + creditsLeft);
          setMyCredits(creditsLeft);
        }
      })
      .catch((error) => {
        // Handle error.
      });
  };

  useEffect(() => {
    //const queryParams = getQueryParams(window.location.href);
    //set plan update errror
    if (queryParams.package) {
      setPlanUpdateError(
        "You are not allowed to perform this action. Please upgrade your plan!"
      );
    }

    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = localStorage.getItem("userData");
    const userDataJSON = JSON.parse(userData);
    console.log("User ID: " + userDataJSON.id);
    //console.log("query " + localStorage.getItem('filtersQuery'));

    if (localStorage.getItem("filtersQuery")) {
      console.log("New api endpoint: " + apiUrl + "/companies" + savedFilters);
    }

    fetchAutoCompleteCompanies(userToken, apiUrl);

    //get page number
    const pn = queryParams.p ? parseInt(queryParams.p) : 1;
    setPageNumber(pn);
    console.log("Query is: " + filterQuery);

    fetchUserCredits(userToken, apiUrl, userDataJSON.id);
  }, [id, firmaFilter]);

  const ranges = [
    "< 100K EUR",
    "100 - 500K EUR",
    "0.5 - 1 Mn. EUR",
    "1 - 5 Mn. EUR",
    "5 - 10 Mn. EUR",
    "10 - 25 Mn. EUR",
    "25 - 50 Mn. EUR",
    "50 - 100 Mn. EUR",
    "100 - 500 Mn. EUR",
    "> 500 Mn. EUR",
  ];

  const revGrowthRanges = [
    "< -15%",
    "-15% - 0%",
    "0% - 25%",
    "25% - 50%",
    "> 50%",
  ];

  const ebitRanges = ["< 0%", "0% - 10%", "10% - 25%", "25% - 50%", "> 50%"];

  const ebitdaRanges = ["< 0%", "0% - 10%", "10% - 25%", "25% - 50%", "> 50%"];

  const employeeRanges = [
    "0 - 10",
    "10 - 50",
    "50 - 100",
    "100 - 500",
    "500 - 1000",
    "> 1000",
  ];
  //for auto complete
  const handleInputChange = (event) => {
    setFirmaFilter(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setFirmaFilter(suggestion);
    setSuggestions([]); // Clear suggestions when an item is selected
  };
  // end - for auto complete

  // Load the selected filter values from localStorage, if available
  const initialSelectedRanges = JSON.parse(
    localStorage.getItem("selectedRanges") || "[]"
  );

  const initialSelectedEbitRanges = JSON.parse(
    localStorage.getItem("selectedEbitRanges") || "[]"
  );

  const initialSelectedEbitdaRanges = JSON.parse(
    localStorage.getItem("selectedEbitdaRanges") || "[]"
  );

  const initialSelectedEmployeeRanges = JSON.parse(
    localStorage.getItem("selectedEmployeeRanges") || "[]"
  );

  const initialSelectedRevGrowthRanges = JSON.parse(
    localStorage.getItem("selectedRevGrowthRanges") || "[]"
  );

  const [selectedRanges, setSelectedRanges] = useState(initialSelectedRanges);
  const [selectedRevGrowthRanges, setSelectedRevGrowthRanges] = useState(
    initialSelectedRevGrowthRanges
  );
  const [selectedEbitRanges, setSelectedEbitRanges] = useState(
    initialSelectedEbitRanges
  );
  const [selectedEbitdaRanges, setSelectedEbitdaRanges] = useState(
    initialSelectedEbitdaRanges
  );
  const [selectedEmployeeRanges, setSelectedEmployeeRanges] = useState(
    initialSelectedEmployeeRanges
  );

  // Function to handle checkbox changes
  const handleRangeChange = (range) => {
    console.log("range: " + range);
    if (selectedRanges.includes(range)) {
      setSelectedRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedRanges((prevSelectedRanges) => [...prevSelectedRanges, range]);
    }
    // console.log("Selected range is: " + JSON.stringify(selectedRanges));
    // // Save the selected filter values to localStorage
    // localStorage.setItem("selectedRanges", JSON.stringify(selectedRanges));
  };

  const handleEbitRangeChange = (range) => {
    if (selectedEbitRanges.includes(range)) {
      setSelectedEbitRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedEbitRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleEbitdaRangeChange = (range) => {
    if (selectedEbitdaRanges.includes(range)) {
      setSelectedEbitdaRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedEbitdaRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleRevGrowthRangeChange = (range) => {
    if (selectedRevGrowthRanges.includes(range)) {
      setSelectedRevGrowthRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedRevGrowthRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleEmpRange = (range) => {
    if (selectedEmployeeRanges.includes(range)) {
      setSelectedEmployeeRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedEmployeeRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  useEffect(() => {
    console.log(
      "Selected range is: " + JSON.stringify(selectedRevGrowthRanges)
    );
    localStorage.setItem("selectedRanges", JSON.stringify(selectedRanges));

    localStorage.setItem(
      "selectedEmployeeRanges",
      JSON.stringify(selectedEmployeeRanges)
    );

    localStorage.setItem(
      "selectedRevGrowthRanges",
      JSON.stringify(selectedRevGrowthRanges)
    );

    localStorage.setItem(
      "selectedEbitdaRanges",
      JSON.stringify(selectedEbitdaRanges)
    );

    localStorage.setItem(
      "selectedEbitRanges",
      JSON.stringify(selectedEbitRanges)
    );
  }, [
    selectedRanges,
    selectedEmployeeRanges,
    selectedEbitRanges,
    selectedEbitdaRanges,
    selectedRevGrowthRanges,
  ]);

  const handleCountrySuggestionClick = (suggestion) => {
    setCountryFilter(suggestion);
    setCountrySuggestions([]); // Clear suggestions when an item is selected
  };

  //domain search filters starts
  const [domainCountryFilter, setDomainCountryFilter] = useState(
    localStorage.getItem("domainCountryFilter")
  );
  const [domainFilter, setDomainFilter] = useState(
    localStorage.getItem("domainFilter")
  );
  const [categoryFilter, setCategoryFilter] = useState(
    localStorage.getItem("categoryFilter")
  );
  const [domainSuggestions, setDomainSuggestions] = useState([]);
  const [domainCountrySuggestions, setDomainCountrySuggestions] = useState([]);
  const onChangeDomainCountryFilter = (e) => {
    setDomainCountryFilter(e.target.value);
    if (domainCountryFilter && domainCountryFilter.length >= 1) {
      setIsLoading(1);
      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const domainCountryFilterFinal = capitalizeString(
        domainCountryFilter,
        "country"
      );

      // Make an authorized GET request to fetch autocomplete domainSuggestions
      axios
        .get(
          apiUrl +
            "/domains?fields[0]=country&filters[country][$contains]=" +
            domainCountryFilterFinal,
          {
            headers,
          }
        )
        .then((response) => {
          const responseData = response.data.data;
          const uniqueCountries = new Set();
          responseData.forEach((item) => {
            const country = item.attributes.country;
            uniqueCountries.add(country);
          });

          const uniqueCountryArray = Array.from(uniqueCountries);

          setDomainCountrySuggestions(uniqueCountryArray);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setDomainCountrySuggestions([]);
      //setIsLoading(0);
    }
  };

  const onChangeDomainFilter = (e) => {
    setDomainFilter(e.target.value);
    if (domainFilter && domainFilter.length >= 1) {
      setIsLoading(1);
      const userToken = localStorage.getItem("userToken");
      const apiUrl = AffiliateDataService.baseURL;
      // Set up the headers with the authorization token
      const headers = {
        Authorization: `Bearer ${userToken}`,
      };

      const domainFilterFinal = capitalizeString(domainFilter, "domain");

      // Make an authorized GET request to fetch autocomplete suggestions
      axios
        .get(apiUrl + "/domains?filters[web][$contains]=" + domainFilterFinal, {
          headers,
        })
        .then((response) => {
          console.log("Suggestions: " + JSON.stringify(response.data.data));
          setDomainSuggestions(response.data.data);
          setIsLoading(0);
          console.log("loading ended: " + isLoading);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      // Clear suggestions when the input is empty
      setDomainSuggestions([]);
      //setIsLoading(0);
    }
  };

  const onChangeCategoryFilter = (e) => {
    setCategoryFilter(e.target.value);
  };

  // Function to reset filters
  const resetDomainFilters = () => {
    setDomainCountryFilter(""); // Reset country filter
    setDomainFilter(""); // Reset domain filter
    setCategoryFilter(""); // Reset category filter
    setSelectedSizeRanges([]); // Reset size range filter
    setSelectedDomainAuthRanges([]); // Reset domain authority filter

    // Clear selected filter values from localStorage
    localStorage.removeItem("selectedSizeRanges");
    localStorage.removeItem("selectedDomainAuthRanges");
    localStorage.removeItem("filtersQuery");

    window.location.reload();
  };

  const handleDomainFilters = () => {
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const filters = [];
    localStorage.setItem("filtersQuery", "");

    if (domainCountryFilter) {
      filters.push(`filters[country][$contains]=${domainCountryFilter}`);
    }
    if (domainFilter) {
      filters.push(`filters[web][$contains]=${domainFilter}`);
    }
    if (categoryFilter) {
      filters.push(`filters[category][$contains]=${categoryFilter}`);
    }

    localStorage.setItem("domainCountryFilter", domainCountryFilter);
    localStorage.setItem("domainFilter", domainFilter);
    localStorage.setItem("categoryFilter", categoryFilter);

    if (selectedSizeRanges.length > 0) {
      const sizeArray = createArray(selectedSizeRanges);

      if (sizeArray.length === 1) {
        const sizeVal =
          sizeArray[0][0] +
          (sizeArray[0][0] == "Giant" ? " Website" : " Websites");
        filters.push(`filters[crawled_pages][$${sizeArray[0][1]}]=${sizeVal}`);
      } else {
        sizeArray.forEach((range, index) => {
          const sizeVal =
            range[0] + (range[0] === "Giant" ? " Website" : " Websites");
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${range[4]}][crawled_pages][$${range[1]}]=${sizeVal}`
          );
        });
      }
    }

    //ebit range handler
    if (selectedDomainAuthRanges.length > 0) {
      const daArray = createArray(selectedDomainAuthRanges);

      if (daArray.length === 1) {
        filters.push(
          `filters[domain_authority][$${daArray[0][1]}]=${daArray[0][0].replace(
            /[ %<>]/g,
            ""
          )}`
        );
      } else {
        daArray.forEach((range, index) => {
          filters.push(
            `filters[$or][${range[3]}][${range[2]}][${
              range[4]
            }][domain_authority][$${range[1]}]=${range[0].replace(
              /[ %<>]/g,
              ""
            )}`
          );
        });
      }
    }

    function createArray(dataRange) {
      const resArray = [];

      let orVal = -1;
      dataRange.forEach((value) => {
        orVal = parseInt(orVal + 1);
        //console.log("Or value: " + orVal);
        if (value.includes("-")) {
          const [min, max] = value.split("-");
          resArray.push([min, "gte", "$and", orVal, 0]);
          resArray.push([max, "lte", "$and", orVal, 1]);
        } else if (value.includes(">")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "gt", "$and", orVal, 0]);
        } else if (value.includes("<")) {
          const val = value.replace(/[ %<>]/g, "", orVal, 0);
          resArray.push([val, "lt", "$and", orVal, 0]);
        } else {
          const val = value.replace(/[%<>]/g, "", orVal, 0);
          resArray.push([val, "eq", "$and", orVal, 0]);
        }
      });

      return resArray;
    }

    filterQuery = filters.length > 0 ? "?" + filters.join("&") : "";
    localStorage.setItem("filtersQuery", filterQuery);

    window.location.href = "/domains";
  };
  const sizeRanges = ["Small", "Medium Sized", "Large", "Very Large", "Giant"];

  //const domainAuthRanges = ["Low", "Moderate", "Intermediate", "High", "Very high"];
  const domainAuthRanges = [
    { key: "Low", value: "0-20" },
    { key: "Moderate", value: "21-40" },
    { key: "Intermediate", value: "41-60" },
    { key: "High", value: "61-80" },
    { key: "Very high", value: ">80" },
  ];

  // Load the selected filter values from localStorage, if available
  const initialSelectedSizeRanges = JSON.parse(
    localStorage.getItem("selectedSizeRanges") || "[]"
  );
  const initialSelectedDomainAuthRanges = JSON.parse(
    localStorage.getItem("selectedDomainAuthRanges") || "[]"
  );

  const [selectedSizeRanges, setSelectedSizeRanges] = useState(
    initialSelectedSizeRanges
  );
  const [selectedDomainAuthRanges, setSelectedDomainAuthRanges] = useState(
    initialSelectedDomainAuthRanges
  );

  // Function to handle checkbox changes
  const handleSizeRangeChange = (range) => {
    if (selectedSizeRanges.includes(range)) {
      setSelectedSizeRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedSizeRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleDomainAuthRange = (range) => {
    if (selectedDomainAuthRanges.includes(range)) {
      setSelectedDomainAuthRanges((prevSelectedRanges) =>
        prevSelectedRanges.filter((r) => r !== range)
      );
    } else {
      setSelectedDomainAuthRanges((prevSelectedRanges) => [
        ...prevSelectedRanges,
        range,
      ]);
    }
  };

  const handleDomainSuggestionClick = (suggestion) => {
    setDomainFilter(suggestion);
    setDomainSuggestions([]); // Clear suggestions when an item is selected
  };

  const handleDomainCountrySuggestionClick = (suggestion) => {
    setDomainCountryFilter(suggestion);
    setDomainCountrySuggestions([]); // Clear suggestions when an item is selected
  };

  // end - for auto complete
  useEffect(() => {
    console.log("selected range: " + JSON.stringify(selectedDomainAuthRanges));
    // Save the selected filter values to localStorage
    localStorage.setItem(
      "selectedSizeRanges",
      JSON.stringify(selectedSizeRanges)
    );

    // Save the selected filter values to localStorage
    localStorage.setItem(
      "selectedDomainAuthRanges",
      JSON.stringify(selectedDomainAuthRanges)
    );
  }, [selectedSizeRanges, selectedDomainAuthRanges]);
  //domain search filters ends

  const handleMouseEnter = () => {
    setIsMOMenuOpen(true);
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMOMenuOpen(false);
    setIsMenuOpen(false);
  };

  // Conditionally assign the event handlers
  const menuProps =
    isMenuOpen && !isMOMenuOpen
      ? {}
      : {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        };

  return (
    <div className="light-style layout-navbar-fixed layout-menu-fixed">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <aside
            {...menuProps}
            id="layout-menu"
            className={`layout-menu menu-vertical menu bg-menu-theme ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="app-brand demo">
              <a href="index.html" className="app-brand-link">
                <span className="app-brand-logo demo">
                  <svg
                    width="32"
                    height="22"
                    viewBox="0 0 32 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                      fill="#7367F0"
                    ></path>
                    <path
                      opacity="0.06"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                      fill="#161616"
                    ></path>
                    <path
                      opacity="0.06"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                      fill="#161616"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                      fill="#7367F0"
                    ></path>
                  </svg>
                </span>
                {isMenuOpen ? (
                  <span className="app-brand-text demo menu-text fw-bold">
                    Scoring
                  </span>
                ) : (
                  ""
                )}
              </a>

              <a
                onClick={toggleMenu}
                href="#"
                className="layout-menu-toggle menu-link text-large ms-auto"
              >
                <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
              </a>
            </div>
            <div className="menu-inner-shadow"></div>
            <div
              className="filter-section p-4 rounded"
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <div
                className={`accordion ${
                  isMenuOpen ? "" : "accordian-icons-only"
                }`}
              >
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading1">
                    <Link
                      to="/companies"
                      type="button"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-device-ipad-search"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Company Search" : ""}
                    </Link>
                  </h2>
                  <div
                    id="collapse1"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading1"
                    data-bs-parent="#collapsibleSection"
                    style={{}}
                  >
                    <div className="accordion-body">
                      <div className="form-group">
                        <div className="accordion">
                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingCountry"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCountry"
                                aria-expanded="false"
                                aria-controls="collapseCountry"
                              >
                                Country
                              </button>
                            </h2>
                            <div
                              id="collapseCountry"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingCountry"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    <input
                                      type="text"
                                      id="countryFilter"
                                      className="form-control mb-2"
                                      placeholder="Country"
                                      value={countryFilter}
                                      onChange={onChangeCountryFilter}
                                    />
                                    {isLoading === 1 ? (
                                      <span className="fas fa-spinner fa-spin"></span>
                                    ) : (
                                      ""
                                    )}
                                    <div className="list-group">
                                      {countrySuggestions.map(
                                        (suggestion, index) => (
                                          <a
                                            href="#"
                                            className="list-group-item list-group-item-action"
                                            key={index}
                                            onClick={() =>
                                              handleCountrySuggestionClick(
                                                suggestion
                                              )
                                            }
                                          >
                                            {suggestion}
                                          </a>
                                        )
                                      )}
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingCompany"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCompnay"
                                aria-expanded="false"
                                aria-controls="collapseCompnay"
                              >
                                Company
                              </button>
                            </h2>
                            <div
                              id="collapseCompnay"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingCompany"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {/* <input
                                    type="text"
                                    id="firmaFilter"
                                    className="form-control mb-2"
                                    placeholder="Firma"
                                    value={firmaFilter}
                                    onChange={onChangeFirmaFilter}
                                  /> */}
                                    <input
                                      type="text"
                                      className="form-control mb-2"
                                      placeholder="Firma"
                                      value={firmaFilter}
                                      onChange={handleInputChange}
                                    />
                                    {isLoading === 1 ? (
                                      <span className="fas fa-spinner fa-spin"></span>
                                    ) : (
                                      ""
                                    )}
                                    <div className="list-group">
                                      {suggestions.map((suggestion, index) => (
                                        <a
                                          href="#"
                                          className="list-group-item list-group-item-action"
                                          key={index}
                                          onClick={() =>
                                            handleSuggestionClick(
                                              suggestion.attributes.company
                                            )
                                          }
                                        >
                                          {suggestion.attributes.company}
                                        </a>
                                      ))}
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingIndustry"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseIndustry"
                                aria-expanded="false"
                                aria-controls="collapseIndustry"
                              >
                                Industry
                              </button>
                            </h2>
                            <div
                              id="collapseIndustry"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingIndustry"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    <select
                                      className="form-control"
                                      id="industrySelect"
                                      onChange={onChangeIndFilter}
                                    >
                                      <option value="Industry">Industry</option>
                                      <option value="Shows and entertainment">
                                        Shows and entertainment
                                      </option>
                                      <option value="Industrial activities">
                                        Industrial activities
                                      </option>
                                      <option value="Public administration">
                                        Public administration
                                      </option>
                                      <option value="Agriculture">
                                        Agriculture
                                      </option>
                                      <option value="Craft activities">
                                        Craft activities
                                      </option>
                                      <option value="Retail trade">
                                        Retail trade
                                      </option>
                                      <option value="Wholesale trade">
                                        Wholesale trade
                                      </option>
                                      <option value="Electrical equipment">
                                        Electrical equipment
                                      </option>
                                      <option value="Energy">Energy</option>
                                      <option value="Real estate">
                                        Real estate
                                      </option>
                                      <option value="Mining and quarrying">
                                        Mining and quarrying
                                      </option>
                                      <option value="Food industry">
                                        Food industry
                                      </option>
                                      <option value="Automotive industry">
                                        Automotive industry
                                      </option>
                                      <option value="Beverages">
                                        Beverages
                                      </option>
                                      <option value="Construction">
                                        Construction
                                      </option>
                                      <option value="Education">
                                        Education
                                      </option>
                                      <option value="IT">IT</option>
                                      <option value="Wood and derived products">
                                        Wood and derived products
                                      </option>
                                      <option value="Machinery and equipment">
                                        Machinery and equipment
                                      </option>
                                      <option value="Media and advertising">
                                        Media and advertising
                                      </option>
                                      <option value="Metal products">
                                        Metal products
                                      </option>
                                      <option value="Chemical products">
                                        Chemical products
                                      </option>
                                      <option value="Pharmaceutical products">
                                        Pharmaceutical products
                                      </option>
                                      <option value="Sanitation">
                                        Sanitation
                                      </option>
                                      <option value="Medical services">
                                        Medical services
                                      </option>
                                      <option value="Business services">
                                        Business services
                                      </option>
                                      <option value="Professional services">
                                        Professional services
                                      </option>
                                      <option value="Forestry">Forestry</option>
                                      <option value="Glass and ceramics">
                                        Glass and ceramics
                                      </option>
                                      <option value="Telecom">Telecom</option>
                                      <option value="Textiles, leather and footwear">
                                        Textiles, leather and footwear
                                      </option>
                                      <option value="Transport">
                                        Transport
                                      </option>
                                      <option value="Tourism and catering">
                                        Tourism and catering
                                      </option>
                                      <option value="Hunting and fishing">
                                        Hunting and fishing
                                      </option>
                                    </select>

                                    {/* <input
                                    type="text"
                                    id="industryFilter"
                                    className="form-control mb-2"
                                    placeholder="Industry"
                                    value={industryFilter}
                                    onChange={onChangeIndFilter}
                                  /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingRevenue"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseRevenue"
                                aria-expanded="false"
                                aria-controls="collapseRevenue"
                              >
                                Revenue
                              </button>
                            </h2>
                            <div
                              id="collapseRevenue"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingRevenue"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {ranges.map((range) => (
                                      <div key={range} className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={range}
                                          value={range}
                                          checked={selectedRanges.includes(
                                            range
                                          )}
                                          onChange={() =>
                                            handleRangeChange(range)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={range}
                                        >
                                          {range}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingRos">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseRos"
                                aria-expanded="false"
                                aria-controls="collapseRos"
                              >
                                Revenue Growth (%)
                              </button>
                            </h2>
                            <div
                              id="collapseRos"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingRos"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {revGrowthRanges.map((range) => (
                                      <div key={range} className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={range}
                                          value={range}
                                          checked={selectedRevGrowthRanges.includes(
                                            range
                                          )}
                                          onChange={() =>
                                            handleRevGrowthRangeChange(range)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={range}
                                        >
                                          {range}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingEbit">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEbit"
                                aria-expanded="false"
                                aria-controls="collapseEbit"
                              >
                                EBIT (%)
                              </button>
                            </h2>
                            <div
                              id="collapseEbit"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingEbit"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {ebitRanges.map((range) => (
                                      <div key={range} className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={range}
                                          value={range}
                                          checked={selectedEbitRanges.includes(
                                            range
                                          )}
                                          onChange={() =>
                                            handleEbitRangeChange(range)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={range}
                                        >
                                          {range}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingEmp">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEmp"
                                aria-expanded="false"
                                aria-controls="collapseEmp"
                              >
                                EBITDA (%)
                              </button>
                            </h2>
                            <div
                              id="collapseEmp"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingEmp"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {ebitdaRanges.map((range) => (
                                      <div key={range} className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={range}
                                          value={range}
                                          checked={selectedEbitdaRanges.includes(
                                            range
                                          )}
                                          onChange={() =>
                                            handleEbitdaRangeChange(range)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={range}
                                        >
                                          {range}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingEbitda">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEbitda"
                                aria-expanded="false"
                                aria-controls="collapseEbitda"
                              >
                                No. Of Employees
                              </button>
                            </h2>
                            <div
                              id="collapseEbitda"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingEbitda"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {employeeRanges.map((range) => (
                                      <div key={range} className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={range}
                                          value={range}
                                          checked={selectedEmployeeRanges.includes(
                                            range
                                          )}
                                          onChange={() => handleEmpRange(range)}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={range}
                                        >
                                          {range}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <button
                        id="filterButton"
                        className="btn btn-primary btn-block"
                        style={{ marginRight: "2px", width: "49%" }}
                        onClick={handleFilters}
                      >
                        Filter
                      </button>
                      <button
                        className="btn btn-secondary btn-block w-50"
                        onClick={resetFilters}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading2">
                  <Link
                  toef="/domains"
                      type="button"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-world-search"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Domain Search" : ""}
                    </Link>
                  </h2>
                  <div
                    id="collapse2"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2"
                    data-bs-parent="#collapsibleSection"
                    style={{}}
                  >
                    <div className="accordion-body">
                      <div className="form-group">
                        <div className="accordion">
                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingCountry2"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCountry2"
                                aria-expanded="false"
                                aria-controls="collapseCountry2"
                              >
                                Country
                              </button>
                            </h2>
                            <div
                              id="collapseCountry2"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingCountry2"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    <input
                                      type="text"
                                      id="domainCountryFilter"
                                      className="form-control mb-2"
                                      placeholder="Country"
                                      value={domainCountryFilter}
                                      onChange={onChangeDomainCountryFilter}
                                    />
                                    {isLoading === 1 ? (
                                      <span className="fas fa-spinner fa-spin"></span>
                                    ) : (
                                      ""
                                    )}
                                    <div className="list-group">
                                      {domainCountrySuggestions.map(
                                        (suggestion, index) => (
                                          <a
                                            href="#"
                                            className="list-group-item list-group-item-action"
                                            key={index}
                                            onClick={() =>
                                              handleDomainCountrySuggestionClick(
                                                suggestion
                                              )
                                            }
                                          >
                                            {suggestion}
                                          </a>
                                        )
                                      )}
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingDomain2"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseDomain2"
                                aria-expanded="false"
                                aria-controls="collapseDomain2"
                              >
                                Domain
                              </button>
                            </h2>
                            <div
                              id="collapseDomain2"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingDomain2"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    <input
                                      type="text"
                                      id="domainFilter"
                                      className="form-control mb-2"
                                      placeholder="Domain"
                                      value={domainFilter}
                                      onChange={onChangeDomainFilter}
                                    />
                                    {isLoading === 1 ? (
                                      <span className="fas fa-spinner fa-spin"></span>
                                    ) : (
                                      ""
                                    )}
                                    <div className="list-group">
                                      {domainSuggestions.map(
                                        (suggestion, index) => (
                                          <a
                                            href="#"
                                            className="list-group-item list-group-item-action"
                                            key={index}
                                            onClick={() =>
                                              handleDomainSuggestionClick(
                                                suggestion.attributes.web
                                              )
                                            }
                                          >
                                            {suggestion.attributes.web}
                                          </a>
                                        )
                                      )}
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingCategory2"
                            >
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseCategory2"
                                aria-expanded="false"
                                aria-controls="collapseCategory2"
                              >
                                Category
                              </button>
                            </h2>
                            <div
                              id="collapseCategory2"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingCategory2"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    <input
                                      type="text"
                                      id="categoryFilter"
                                      className="form-control mb-2"
                                      placeholder="Category"
                                      value={categoryFilter}
                                      onChange={onChangeCategoryFilter}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingSize2">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSize2"
                                aria-expanded="false"
                                aria-controls="collapseSize2"
                              >
                                Size
                              </button>
                            </h2>
                            <div
                              id="collapseSize2"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingSize2"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {sizeRanges.map((range) => (
                                      <div key={range} className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={range}
                                          value={range}
                                          checked={selectedSizeRanges.includes(
                                            range
                                          )}
                                          onChange={() =>
                                            handleSizeRangeChange(range)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={range}
                                        >
                                          {range}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card accordion-item">
                            <h2 className="accordion-header" id="headingDa2">
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseDa2"
                                aria-expanded="false"
                                aria-controls="collapseDa2"
                              >
                                Domain Authority
                              </button>
                            </h2>
                            <div
                              id="collapseDa2"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingDa2"
                              data-bs-parent="#collapsibleSection"
                              style={{}}
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md mb-md-0 mb-2">
                                    {domainAuthRanges.map((item) => (
                                      <div
                                        key={item.key}
                                        className="form-check"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={item.key}
                                          value={item.value}
                                          checked={selectedDomainAuthRanges.includes(
                                            item.value
                                          )}
                                          onChange={() =>
                                            handleDomainAuthRange(item.value)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={item.key}
                                        >
                                          {item.key}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <button
                        id="filterButton"
                        className="btn btn-primary btn-block"
                        style={{ marginRight: "2px", width: "49%" }}
                        onClick={handleDomainFilters}
                      >
                        Filter
                      </button>
                      <button
                        className="btn btn-secondary btn-block w-50"
                        onClick={resetDomainFilters}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <i
                        className="ti ti-map-pin"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Locations" : ""}
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#collapsibleSection"
                    style={{}}
                  >
                    <div className="accordion-body"></div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading4">
                    <a href="/bookmarks?t=companies" className="accordion-button collapsed">
                      <i
                        className="ti ti-building"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "My Companies" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading5">
                    <a href="/bookmarks?t=domains" className="accordion-button collapsed">
                      <i
                        className="ti ti-world-www"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "My Domains" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading6">
                    <a href="" className="accordion-button collapsed">
                      <i
                        className="ti ti-users"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Enrichments" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading7">
                    <Link to="/logout" className="accordion-button collapsed">
                      <i
                        className="ti ti-logout"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Logout" : ""}
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          </aside>

          <div
            className={`${
              isMenuOpen ? "layout-page" : "layout-page1"
            } custom-layout-padding`}
          >
            {authenticated ? (
              <TopNavBar
                toggleMenu={toggleMenu}
                myCredits={myCredits}
                isMenuOpen={isMenuOpen}
                authenticated={authenticated}
              ></TopNavBar>
            ) : (
              ""
            )}
            <div className="content-wrapper">
              <div className="flex-grow-1 container-p-y">
                <div className="row">
                {/* <UserEmailsListTable
                    pageNumber={pageNumber}
                    allowNext={allowNext}
                    userEmailLists={emailsList}
                    totalRecords={emailsListCount}
                  ></UserEmailsListTable> */}
                  <UserEmailsListTable></UserEmailsListTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
