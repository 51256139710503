import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FreeAcc = ({showModal, handleClose}) => {
    console.log("show modal: " + showModal);
  return (
    <div
      className={`modal fade${showModal ? ' show' : ''}`}
      id="modalScrollable"
      tabIndex="-1"
      style={{ display: (showModal ? "block" : "none"), paddingTop:"40px", paddingBottom:"40px" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              onClick={handleClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{zIndex:100}}
            ></button>
          </div>
          <div className="modal-body text-center" style={{ display: (showModal ? "block" : "none"), paddingTop: "40px", paddingBottom: "40px", height: "320px" }}>                
                <h2 className='text-center'>Create your free account</h2>
                <h5>100 free company & domain profiles per month. No credit card required.</h5>
                <Link style={{marginTop:"30px"}} className='btn btn-success' to="/sign-up">Unlock Data</Link>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeAcc;