import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function CSVImport({ listId }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };  

  const removeEmptyRowsAndColumns = (csvData) => {
    const lines = csvData.split(/\r?\n/);
    if (lines.length === 0) return [];
  
    // Split the first line (header) to get column names
    const headers = lines[0].split(",");
  
    // Initialize an array to hold cleaned rows
    const cleanedRows = [];
  
    for (let i = 1; i < lines.length; i++) {
      const row = lines[i].split(",");
  
      // Check if the row is entirely empty
      if (row.some((cellData) => cellData.trim() !== "")) {
        const newRow = {};
  
        // Check if the email column has a correct email format
        const emailColumnIndex = headers.indexOf("email");
        if (
          emailColumnIndex !== -1 &&
          row[emailColumnIndex] &&
          validateEmail(row[emailColumnIndex].trim())
        ) {
          for (let j = 0; j < headers.length; j++) {
            const header = headers[j];
            const cellData = row[j];
  
            // Only include non-empty columns
            if (cellData !== "") {
              newRow[header] = cellData;
            }
          }
  
          cleanedRows.push(newRow);
        }
      }
    }
  
    return cleanedRows;
  };
  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const reader = new FileReader();
        reader.onload = async (event) => {
          let csvData = event.target.result;

          // Remove carriage return characters ("\r")
          csvData = csvData.replace(/\r/g, "");

          const cleanedData = removeEmptyRowsAndColumns(csvData);

          // Assuming "user id" should be added to each row
          console.log("Selected list is : " + listId);
          // Add the user ID to each row
          const dataWithUserId = cleanedData.map((row) => {
            return { ...row, list_id: listId };
          });

          const importData = {
            slug: "api::email-record.email-record",
            data: JSON.stringify(dataWithUserId),
            format: "json",
          };

          const response = await axios.post(
            "https://bck.scoring.ro/api/import-export-entries/content/import",
            importData
          );
          setNotify("File uploaded successfully!");
          setNotifyClass("success");
          console.log("File uploaded successfully:", response.data);

          setTimeout(() => {
            window.location.href = "/email-list/" + listId;
          }, 1000);
        };

        reader.readAsText(selectedFile);
      } catch (error) {
        setNotify("Error uploading file:" + JSON.stringify(error));
        setNotifyClass("danger");
        console.error("Error uploading file:", error);
      }
    } else {
      setNotify("No file selected");
      setNotifyClass("danger");
      console.error("No file selected");
    }
  };

  const isMobileScreen = window.innerWidth <= 768;
  return (
    <div className="container">
      {/* <h5 className="card-header pb-2">Upload Emails List!</h5> */}

      {notify !== "" ? (
        <div className={`m-4 mb-0 mt-0 w-50 alert alert-${notifyClass}`}>
          {notify}
        </div>
      ) : (
        ""
      )}
      <div className={isMobileScreen ? '' : 'p-4'}>
        <Link
          to="/user-email-lists"
          class="btn btn-primary"
          style={{ float: "left" }}
        >
          {isMobileScreen ? <i className='ti ti-eye'></i> : "View All Lists"}
        </Link>

        <input
          style={{ width: isMobileScreen ? "50%" : "72%", float: "left", marginLeft: "15px", marginRight: "15px" }}
          className="form-control mb-2"
          type="file"
          onChange={handleFileChange}
        />
        <button className="btn btn-primary" onClick={handleUpload}>
        {isMobileScreen ? <i className='ti ti-upload'></i> : "Upload CSV"}
        </button>
      </div>
    </div>
  );
}

export default CSVImport;
