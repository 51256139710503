import React from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more"; // Import the Highcharts More module
import HighchartsReact from "highcharts-react-official";

// Initialize the Highcharts More module
HighchartsMore(Highcharts);
const MajesticChart = ({ domain }) => {

  var openr = domain.majestic_rank;
  var limit = 1000000;
  var displayvalue = 0;
  if (limit < openr){
    displayvalue = limit;
  } else {
    displayvalue = openr;
  }  
  const chartOptions = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '80%'
  },
    title: {
      text: "Majestic Rank",
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', '75%'],
      size: '110%'
  },

  // the value axis
  yAxis: {
      min: 0,
      max: 1000000,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
      tickLength: 25,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
          distance: 20,
          style: {
              fontSize: '10px'
          }
      },
      lineWidth: 0,
      plotBands: [{
          from: 0,
          to: 100000,
          color: '#018219', // green
          thickness: 20
      }, {
          from: 100000,
          to: 200000,
          color: '#05b025', //  green
          thickness: 20
      },{
          from: 200000,
          to: 300000,
          color: '#67e67f', //  green
          thickness: 20
      },{
          from: 300000,
          to: 400000,
          color: '#DBFF33', //  yellow
          thickness: 20
      },{
          from: 400000,
          to: 500000,
          color: '#FFFF33', // yellow
          thickness: 20
      },{
          from: 500000,
          to: 600000,
          color: '#FFE033', // yellow
          thickness: 20
      },{
          from: 600000,
          to: 700000,
          color: '#FFB833', //  orange
          thickness: 20
      },{
          from: 700000,
          to: 800000,
          color: '#FF9933', // orange
          thickness: 20
      },{
          from: 800000,
          to: 900000,
          color: '#FF6433', // orange
          thickness: 20
      },{
          from: 900000,
          to: 10000000,
          color: '#FF3933', // red
          thickness: 20
      }]
  },
    series: [
      {
        name: "Rank",
        data: [parseInt(displayvalue)], // Your data value
        tooltip: {
          valueSuffix: " ",
        },
        dataLabels: {
          format: "{y}",
          borderWidth: 0,
          color:
            (Highcharts.defaultOptions.title &&
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "#333333",
          style: {
            fontSize: "16px",
          },
        },
        dial: {
          radius: "80%",
          backgroundColor: "gray",
          baseWidth: 12,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "gray",
          radius: 6,
        },
      },
    ],
  };

  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-body pt-2">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default MajesticChart;
