import React, { createRef, useEffect, useState } from "react";
import { useScreenshot } from "use-react-screenshot";

const DomainProfile = ({ domain }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(ref.current);
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [domainScreenShot, setDomainScreenShot] = useState("");

  function getFirstSocialUrl(socialNetwork) {
    // Check if the input string is defined and contains a semicolon
    if (socialNetwork && socialNetwork.includes(";")) {
      // Split the string by semicolon and get the first value
      var valuesArray = socialNetwork.split(";");
      var firstValue = valuesArray[0];

      // Check if the first value starts with "https://"
      if (!firstValue.startsWith("https://")) {
        // If not, add "https://" to the beginning
        firstValue = "https://" + firstValue;
      }

      return firstValue;
    } else {
      // If there is no semicolon or the string is undefined, return an empty string
      if (socialNetwork && !socialNetwork.startsWith("https://")) {
        return "https://" + socialNetwork;
      } else {
        return socialNetwork === "NULL" || socialNetwork === ""
          ? ""
          : socialNetwork;
      }
    }
  }
  useEffect(() => {
    // Call the getFirstValue function and set the result in the state
    setFacebookUrl(getFirstSocialUrl(domain.facebook));
    setInstaUrl(getFirstSocialUrl(domain.instagram));
    setLinkedinUrl(getFirstSocialUrl(domain.linkedin));
    setTwitterUrl(getFirstSocialUrl(domain.twitter));
    setYoutubeUrl(getFirstSocialUrl(domain.video_platforms));

    var thumAuth = "71072-appscoringro";
    var thumUrl = "http://" + domain.web;

    var imgUrl = "//image.thum.io/get/auth/" + thumAuth + "/" + thumUrl;
    setDomainScreenShot(imgUrl);
  }, [
    domain.facebook,
    domain.instagram,
    domain.linkedin,
    domain.twitter,
    domain.video_platforms,
  ]);

  const isMobileScreen = window.innerWidth <= 768;

  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="py-3 mb-4">General Information</h4>
        <div className="card mb-4 alert alert-primary">
          <div className="card-body">
            <div className="row">
              <h3 className="mb-0 col-md-6">
                {" "}
                {domain.favicon ? (
                  <img
                    src={domain.favicon ? domain.favicon.split(';')[0] : ''}
                    className="rounded-circle me-3"
                    style={{ width: "50px", height: "40px" }}
                  />
                ) : ""}{" "}
                <strong>{domain.web}</strong>
              </h3>
              <div className="col-md-6 text-right">
                {facebookUrl == "" ? (
                  ""
                ) : (
                  <a
                    style={{ marginRight: "10px" }}
                    href={facebookUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-facebook fa-2x"
                      style={{ color: "#1877F2" }}
                    ></i>
                  </a>
                )}
                {youtubeUrl == "" ? (
                  ""
                ) : (
                  <a
                    style={{ marginRight: "10px" }}
                    href={youtubeUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-youtube fa-2x"
                      style={{ color: "#FF0000" }}
                    ></i>
                  </a>
                )}
                {twitterUrl == "" ? (
                  ""
                ) : (
                  <a
                    style={{ marginRight: "10px" }}
                    href={twitterUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-twitter fa-2x"
                      style={{ color: "#1DA1F2" }}
                    ></i>
                  </a>
                )}
                {linkedinUrl == "" ? (
                  ""
                ) : (
                  <a
                    style={{ marginRight: "10px" }}
                    href={linkedinUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-linkedin fa-2x"
                      style={{ color: "#0A66C2" }}
                    ></i>
                  </a>
                )}
                {instaUrl == "" ? (
                  ""
                ) : (
                  <a
                    style={{ marginRight: "10px" }}
                    href={instaUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fab fa-instagram fa-2x"
                      style={{ color: "#E4405F" }}
                    ></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        {/*vertical pills*/}
        <div
          className="nav-align-left nav-tabs-shadow mb-4"
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            backgroundColor: "#fff",
          }}
        >
          <ul className="nav nav-tabs" style={{ width: "40%" }} role="tablist">
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link active profil-left-btn"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-home"
                aria-controls="navs-left-home"
                aria-selected="true"
                style={{ fontSize: "12px" }}
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-network"></i>
                </span>
                Title
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link profil-left-btn"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-profile"
                aria-controls="navs-left-profile"
                aria-selected="false"
                tabindex="-1"
                style={{ fontSize: "12px" }}
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                About Us
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link profil-left-btn"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-messages"
                aria-controls="navs-left-messages"
                aria-selected="false"
                tabindex="-1"
                style={{ fontSize: "12px" }}
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-user-circle"></i>
                </span>
                Top Keywords
              </button>
            </li>{" "}
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link profil-left-btn"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-ipaddress"
                aria-controls="navs-left-ipaddress"
                aria-selected="false"
                tabindex="-1"
                style={{ fontSize: "12px" }}
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-database-search"></i>
                </span>
                Website on this IP
              </button>
            </li>{" "}
          </ul>
          <div className="tab-content" style={{ width: "60%" }}>
            <div
              className="tab-pane fade show active"
              id="navs-left-home"
              role="tabpanel"
            >
              <p style={{ fontSize: "12px" }}>{domain.title}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-profile"
              role="tabpanel"
            >
              <p style={{ fontSize: "12px" }}>{domain.description}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-messages"
              role="tabpanel"
            >
              <p style={{ fontSize: "12px" }}>{domain.top_keywords}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-ipaddress"
              role="tabpanel"
            >
              <p style={{ fontSize: "12px" }}>{domain.websites_on_this_ip}</p>
            </div>
          </div>
        </div>
        <div className="card mt-2">
          <div className="card-body">
            <ul className="p-0 m-0">
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-building ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Company</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.owner_name}</p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-building-bank ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Fiscal Code</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.fiscal_no}</p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-filter-cog ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Category</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.category}</p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-calendar-time ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Creation Date</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">
                      {domain.creation_date_whois_root_domain
                        ? domain.creation_date_whois_root_domain.split(" ")[0]
                        : ""}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-map-pin ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Country</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.country}</p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-database ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Server Location</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.server_location}</p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-text-recognition ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Language</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.language}</p>
                  </div>
                </div>
              </li>
              <li className="mb-1 pb-1 d-flex justify-content-between align-items-center">
                <div className="badge bg-label-info rounded p-2">
                  <i className="ti ti-network ti-sm"></i>
                </div>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <h6 className="mb-0 ms-3">Ip Address</h6>
                  <div className="d-flex">
                    <p className="mb-0 fw-medium">{domain.ip_address}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/*vertical pills*/}
        {/* <div className="nav-align-left nav-tabs-shadow mb-4">
          <ul className="nav nav-tabs" style={{ width: "40%" }} role="tablist">
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link active"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-home"
                aria-controls="navs-left-home"
                aria-selected="true"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Company
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-profile"
                aria-controls="navs-left-profile"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Fiscal Code
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-messages"
                aria-controls="navs-left-messages"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Category
              </button>
            </li>{" "}
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-ipaddress"
                aria-controls="navs-left-ipaddress"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Creation Date
              </button>
            </li>{" "}
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-websites"
                aria-controls="navs-left-websites"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Country
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-websites"
                aria-controls="navs-left-websites"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Server Location
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-websites"
                aria-controls="navs-left-websites"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                Language
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#navs-left-websites"
                aria-controls="navs-left-websites"
                aria-selected="false"
                tabindex="-1"
              >
                <span class="bg-primary text-white p-2 m-1 rounded">
                  <i class="ti ti-file-description"></i>
                </span>
                IP Address
              </button>
            </li>
          </ul>
          <div className="tab-content" style={{ width: "60%" }}>
            <div
              className="tab-pane fade show active"
              id="navs-left-home"
              role="tabpanel"
            >
              <p>{domain.owner_name}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-profile"
              role="tabpanel"
            >
              <p>{domain.fiscal_no}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-messages"
              role="tabpanel"
            >
              <p>{domain.category}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-ipaddress"
              role="tabpanel"
            >
              <p>{domain.creation_date_whois_root_domain}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-websites"
              role="tabpanel"
            >
              <p>{domain.country}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-websites"
              role="tabpanel"
            >
              <p>{domain.server_location}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-websites"
              role="tabpanel"
            >
              <p>{domain.language}</p>
            </div>
            <div
              className="tab-pane fade"
              id="navs-left-websites"
              role="tabpanel"
            >
              <p>{domain.ip_address}</p>
            </div>
          </div>
        </div> */}
      </div>
      {/*domain screenshot*/}
      <div className="col-md-6">
        <div
          style={{
            position: "relative",
            height: "650px",
            overflow: "hidden",
            marginTop: isMobileScreen ? "25px" : "",
          }}
        >
          {/* Laptop screen */}
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "100%",
              height: "97%",
              border: "20px solid #fff",
              borderBottom: "60px solid #fff",
              borderRadius: "10px",
            }}
          >
            {/* Content for laptop screen */}
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={domainScreenShot} // Placeholder image, replace with your laptop image
              alt="Laptop Screen"
            />
          </div>

          {/* Mobile screen with white borders */}
          {/* <div
            style={{
              position: "absolute",
              top: "150px", // Adjust the top position as needed
              left: "0px", // Adjust the left position as needed
              width: "300px",
              height: "480px",
              border: "20px solid #fff",
              borderRadius: "10px",
              overflow: "hidden",
              backgroundColor: "white",
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={`https://image.thum.io/get/width/250/crop/480/http://${domain.web}`} // Placeholder image, replace with your mobile image
              alt="Mobile Screen"
            />
          </div> */}
        </div>
      </div>{" "}
    </div>
  );
};

export default DomainProfile;
