import React from "react";
import PieChart from "../domains/pie";

const DomainPopularity = ({domain}) => {
  return (
    <div className="row" id="domainPop">
      <h4 className="py-3 mb-4">
        Domain Popularity
      </h4>
      <div className="col-lg-2 col-6 mb-4">
        <div className="card h-100 d-flex flex-column justify-content-center">
          <div className="card-body text-center" style={{paddingTop:"42%"}}>
            <div className="badge rounded-pill p-2 bg-label-danger mb-2">
              <i className="ti ti-users ti-sm"></i>
            </div>
            <h5 className="card-title mb-2">{domain.daily_visitors?.toLocaleString()}</h5>
            <span>Daily Visitors</span>
          </div>
        </div>
      </div>

      <div className="col-lg-2 col-6 mb-4">
        <div className="card h-100 d-flex align-items-center">
          <div className="card-body text-center" style={{paddingTop:"42%"}}>
            <div className="badge rounded-pill p-2 bg-label-danger mb-2">
              <i className="ti ti-clock-hour-5 ti-sm"></i>
            </div>
            <h5 className="card-title mb-2">{domain.response_time_on_page}</h5>
            <span>Response Time</span>
          </div>
        </div>
      </div>

      <div className="col-lg-2 col-6 mb-4">
        <div className="card h-100 d-flex align-items-center">
          <div className="card-body text-center" style={{paddingTop:"42%"}}>
            <div className="badge rounded-pill p-2 bg-label-danger mb-2">
              <i className="ti ti-click ti-sm"></i>
            </div>
            <h5 className="card-title mb-2">{domain.visits_website_traffic_host?.toLocaleString()}</h5>
            <span>Visits</span>
          </div>
        </div>
      </div>

      <div className="col-lg-2 col-6 mb-4">
        <div className="card h-100 d-flex align-items-center">
          <div className="card-body text-center" style={{paddingTop:"42%"}}>
            <div className="badge rounded-pill p-2 bg-label-danger mb-2">
              <i className="ti ti-map-search ti-sm"></i>
            </div>
            <h5 className="card-title mb-2">{domain.main_country_website_traffic_host}</h5>
            <span>Traffic Country</span>
          </div>
        </div>
      </div>

      <PieChart domain={domain}></PieChart>
    </div>
  );
};

export default DomainPopularity;
