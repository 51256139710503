import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

HighchartsMore(Highcharts);

const OPRank = ({ domain, rankTitle }) => {
  const [dataVal, setDataVal] = useState({});
  const rankValue = domain.open_pagerank;
  var openr = rankValue;
  var limit = 10;
  var displayvalue = 0;
  if (limit < openr){
    displayvalue = limit;
  } else {
    displayvalue = openr;
  }
  const chartOptions = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '80%'
  },

  title: {
      text: 'Open Page Rank'
  },

  pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', '75%'],
      size: '110%'
  },

  // the value axis
  yAxis: {
      min: 0,
      max: 10,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
      tickLength: 25,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
          distance: 20,
          style: {
              fontSize: '10px'
          }
      },
      lineWidth: 0,
      plotBands: [{
          from: 0,
          to: 1,
          color: '#018219', // green
          thickness: 20
      }, {
          from: 1,
          to: 2,
          color: '#05b025', //  green
          thickness: 20
      },{
          from: 2,
          to: 3,
          color: '#67e67f', //  green
          thickness: 20
      },{
          from: 3,
          to: 4,
          color: '#DBFF33', //  yellow
          thickness: 20
      },{
          from: 4,
          to: 5,
          color: '#FFFF33', // yellow
          thickness: 20
      },{
          from: 5,
          to: 6,
          color: '#FFE033', // yellow
          thickness: 20
      },{
          from: 6,
          to: 7,
          color: '#FFB833', //  orange
          thickness: 20
      },{
          from: 7,
          to: 8,
          color: '#FF9933', // orange
          thickness: 20
      },{
          from: 8,
          to: 9,
          color: '#FF6433', // orange
          thickness: 20
      },{
          from: 9,
          to: 10,
          color: '#FF3933', // red
          thickness: 20
      }]
  },

  series: [{
      name: 'Open Page Rank',
      data: [displayvalue],
      tooltip: {
          valueSuffix: ' '
      },
      dataLabels: {
          format: openr,
          borderWidth: 0,
          color: (
              Highcharts.defaultOptions.title &&
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
          ) || '#333333',
          style: {
              fontSize: '16px'
          }
      },
      dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%'
      },
      pivot: {
          backgroundColor: 'gray',
          radius: 6
      }

  }]
  };

  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-body pt-2">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default OPRank;
