import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const EbitdaChart = ({ company }) => { 
  const chartOptions = {
    chart: {
        type: 'column',
        height: 200,
    },
    legend: {
        enabled: false, // Hide the legends
      },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span> EBITDA: <b>{point.y} (%)</b><br/>',
    },
    xAxis: {
      categories: ['2018', '2019', '2020', '2021', '2022'],
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: [
      {
        data: [parseInt(company.EBITDA_an1),parseInt(company.EBITDA_an2),parseInt(company.EBITDA_an3),parseInt(company.EBITDA_an4),parseInt(company.EBITDA_an5)],
        color: 'skyblue',
      },
     ],

  };
  
  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">EBITDA (%)</h5>
          </div>
        </div>
        <div className="card-body pt-2">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}

export default EbitdaChart;
