import React, { useState, useEffect } from "react";
import axios from "axios";
import TopNavBar from "../TopNavBar";
import AffiliateDataService from "../../services/affiliate.service";
import "../../assets/vendor/fonts/fontawesome.css";
import "../../assets/vendor/fonts/tabler-icons.css";
import "../../assets/vendor/fonts/flag-icons.css";
import "../../assets/vendor/css/rtl/core.css";
import "../../assets/css/demo.css";
import "../../assets/vendor/css/rtl/theme-default.css";
import "../../assets/vendor/libs/node-waves/node-waves.css";
import "../../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "../../assets/vendor/libs/typeahead-js/typeahead.css";
import "../../assets/vendor/libs/apex-charts/apex-charts.css";
import "../../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
import "../../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";
import "../../assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css";
import "../../assets/vendor/css/pages/cards-advance.css";
import UserEmailsListTable from "../UserEmailListTable";
import { Link } from "react-router-dom";

export default function UserProfile() {
  const [myCredits, setMyCredits] = useState(
    localStorage.getItem("userCredits")
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMOMenuOpen, setIsMOMenuOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userPlanData, setUserPlanData] = useState([]);
  const [userMemData, setUserMemData] = useState([]);
  const [userMembershipId, setUserMembershipId] = useState(
    localStorage.getItem("userMembershipId")
  );
  const [notify, setNotify] = useState("");
  const [notifyClass, setNotifyClass] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserPass, setNewUserPass] = useState("");
  const [newUserAvatar, setNewUserAvatar] = useState("");
  const [profileRole, setProfileRole] = useState("");
  const [profileContact, setProfileContact] = useState("");
  const [profileCountry, setProfileCountry] = useState("");
  const [taxId, setTaxId] = useState("");
  const [profileLang, setProfileLang] = useState("");
  const [upgradePlanData, setUpgradePlanData] = useState("");
  const [remainingDays, setRemainingDays] = useState(0);
  const [planCompletePercent, setPlanCompletePercent] = useState(0);
  const [currentTab, setCurrentTab] = useState("billing");
  const [profileStatus, setProfileStatus] = useState("");

  const handleActiveTab = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const apiUrl = AffiliateDataService.baseURL;
    const userData = localStorage.getItem("userData");
    const myPlanData = JSON.parse(localStorage.getItem("userCurrentPlanData"));
    const membershipData = JSON.parse(localStorage.getItem("userMemData"));
    const userDataJSON = JSON.parse(userData);

    //set current tab

    axios
      .get(`${apiUrl}/users/${userDataJSON.id}?populate=*`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        //console.log("User avatar:", response.data.avatar.url);
        setNewUserAvatar("https://bck.scoring.ro" + response.data.avatar.url);
        // Process the response data here
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    //get upgrade plan data
    const planUpgradeTo =
      userMemData.plan_id === 1 || userMemData.plan_id === 2 ? 3 : 4;
    axios
      .get(`${apiUrl}/membership-plans?filters[id][$eq]=${planUpgradeTo}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUpgradePlanData(response.data.data[0].attributes);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    //set user info and selected plan
    setUserData(userDataJSON);
    setUserPlanData(myPlanData);
    setUserMemData(membershipData);

    //console.log("User Data:" + JSON.stringify(userData));
  }, [newUserName]);

  useEffect(() => {
    //profile status
    setProfileStatus(userMemData.status);
    //alert(userMemData.status);
    const parseDate = (dateString) => {
      if (!dateString) return null; // Add a check to handle undefined values
      const [datePart] = dateString.split("T"); // Extracting only the date part, excluding time
      const [year, month, day] = datePart.split("-");
      return new Date(`${year}-${month}-${day}`);
    };

    const planDates = {
      plan_started_on: parseDate(userMemData.plan_started_on),
      plan_exiry_date: parseDate(userMemData.plan_exiry_date),
    };

    const totalDurationInMilliseconds =
      planDates.plan_exiry_date - planDates.plan_started_on;
    const totalDurationInDays = Math.ceil(
      totalDurationInMilliseconds / (1000 * 60 * 60 * 24)
    );

    const currentDate = new Date();
    const daysPassedInMilliseconds = currentDate - planDates.plan_started_on;
    const daysPassed = Math.ceil(
      daysPassedInMilliseconds / (1000 * 60 * 60 * 24)
    );

    const completionPercentage = (daysPassed / totalDurationInDays) * 100;
    setRemainingDays(totalDurationInDays - daysPassed);

    console.log(`Days passed: ${daysPassed}`);
    console.log(`Total duration in days: ${totalDurationInDays}`);
    setPlanCompletePercent(completionPercentage.toFixed(0));

    console.log(userPlanData.monthly_details);
  }, [userMemData, userPlanData]); // useEffect will run every time userMemData changes

  const handleMouseEnter = () => {
    setIsMOMenuOpen(true);
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMOMenuOpen(false);
    setIsMenuOpen(false);
  };

  // Conditionally assign the event handlers
  const menuProps =
    isMenuOpen && !isMOMenuOpen
      ? {}
      : {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        };

  const styleWithImportant = {
    maxHeight: "80vh",
    overflowY: "auto",
    paddingLeft: "5px !important",
  };

  function formatDateTime(dateString) {
    // Convert the string to a Date object
    const date = new Date(dateString);

    // Get month name
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[date.getMonth()];

    // Get day and year
    const day = date.getDate();
    const year = date.getFullYear();

    // Get time
    const time = date.toLocaleTimeString();

    // Format the date
    const formattedDate = monthName + " " + day + ", " + year + " " + time;

    return formattedDate;
  }

  const [isEditing, setIsEditing] = useState(false);
  const apiUrl = AffiliateDataService.baseURL;
  const userToken = localStorage.getItem("userToken");

  const handleEditList = () => {
    // Set the edit mode to true and populate the editedListTitle state
    setIsEditing(true);
    setNewUserName(userData.username);
    setNewUserEmail(userData.email);
    setNewUserPass(userData.password);
    setNewUserAvatar(userData.avatar);
    setTaxId(userData.taxid);
    setProfileContact(userData.contact);
    setProfileCountry(userData.country);
    setProfileLang(userData.language);
    setProfileRole(userData.profile_role);
  };

  const changePassword = () => {
    axios.post(
      `${apiUrl}/auth/change-password`,
      {
        data: {
          currentPassword: userData.password,
          password: newUserPass,
          passwordConfirmation: newUserPass,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const uploadAvatar = () => {
    const formData = new FormData();
    formData.append("files", newUserAvatar);
    formData.append("ref", "plugin::users-permissions.user");
    formData.append("refId", userData.id);
    formData.append("field", "avatar");
    console.log(formData);

    return axios.post(`${apiUrl}/upload`, formData);
  };

  const handleSaveEdit = () => {
    axios
      .put(
        `${apiUrl}/users/${userData.id}`,
        {
          username: newUserName,
          email: newUserEmail,
          password: newUserPass,
          country: profileCountry,
          contact: profileContact,
          language: profileLang,
          taxid: taxId,
          profile_role: profileRole,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the successful update of the list here if needed
        console.log("User profile updated:", response.data);
        //return false;
        setNotify("User Profile updated successfully!");
        setNotifyClass("success");
        // Clear the editedListTitle and exit edit mode
        setIsEditing(false);

        if (newUserAvatar) {
          uploadAvatar();
        }

        if (newUserPass) {
          changePassword();
        }

        localStorage.setItem("userName", response.data.username);
        localStorage.setItem("userData", JSON.stringify(response.data));
        setTimeout(() => {
          window.location.href = "/profile";
        }, 1000);
      })
      .catch((error) => {
        setNotify("Error updating user profile: " + error.message);
        setNotifyClass("danger");
        console.error("Error updating user profile:", error);
      });
  };

  const handleCancelSubscription = (cancelUserId) => {
    console.log("Cancel subscrition for user id: " + cancelUserId);
    axios
      .put(
        `${apiUrl}/scorers/${cancelUserId}`,
        {
          data: {
            status: "cancelled",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the successful update of the list here if needed
        console.log("Subscription Cancelled", response.data);
        setNotify("Subscription Cancelled!");
        setNotifyClass("success");
        setProfileStatus("cancelled");

        setTimeout(() => {
          window.location.href = "/profile";
        }, 1000);
      })
      .catch((error) => {
        setNotify("Error updating user profile: " + error.message);
        setNotifyClass("danger");
        console.error("Error updating user profile:", error);
      });
  };

  return (
    <div className="light-style layout-navbar-fixed layout-menu-fixed">
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <aside
            {...menuProps}
            id="layout-menu"
            className={`layout-menu menu-vertical menu bg-menu-theme ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="app-brand demo">
              <a href="index.html" className="app-brand-link">
                <span className="app-brand-logo demo">
                  <svg
                    width="32"
                    height="22"
                    viewBox="0 0 32 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                      fill="#7367F0"
                    ></path>
                    <path
                      opacity="0.06"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                      fill="#161616"
                    ></path>
                    <path
                      opacity="0.06"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                      fill="#161616"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                      fill="#7367F0"
                    ></path>
                  </svg>
                </span>
                {isMenuOpen ? (
                  <span className="app-brand-text demo menu-text fw-bold">
                    Scoring
                  </span>
                ) : (
                  ""
                )}
              </a>

              <a
                onClick={toggleMenu}
                href="#"
                className="layout-menu-toggle menu-link text-large ms-auto"
              >
                <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
              </a>
            </div>
            <div className="menu-inner-shadow"></div>
            <div
              className="filter-section p-2 rounded"
              style={styleWithImportant}
            >
              <div
                className={`accordion ${
                  isMenuOpen ? "" : "accordian-icons-only"
                }`}
              >
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading1">
                    <Link
                      to="/companies"
                      type="button"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-device-ipad-search"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Company Search" : ""}
                    </Link>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading2">
                    <Link
                      to="/domains"
                      type="button"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-world-search"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Domain Search" : ""}
                    </Link>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      type="button"
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <i
                        className="ti ti-map-pin"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Locations" : ""}
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#collapsibleSection"
                    style={{}}
                  >
                    <div className="accordion-body"></div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading4">
                    <a
                      href="/bookmarks?t=companies"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-building"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "My Companies" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading5">
                    <a
                      href="/bookmarks?t=domains"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-world-www"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "My Domains" : ""}
                    </a>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading6">
                    <Link
                      to="/user-email-lists"
                      className="accordion-button collapsed"
                    >
                      <i
                        className="ti ti-users"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Enrichments" : ""}
                    </Link>
                  </h2>
                </div>
                <div className="card accordion-item">
                  <h2 className="accordion-header" id="heading7">
                    <Link to="/logout" className="accordion-button collapsed">
                      <i
                        className="ti ti-logout"
                        style={{
                          marginRight: "5px",
                          fontSize: "25px",
                          lineHeight: "20px",
                        }}
                      ></i>{" "}
                      {isMenuOpen ? "Logout" : ""}
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          </aside>

          <div
            className={`${
              isMenuOpen ? "layout-page" : "layout-page1"
            } custom-layout-padding`}
          >
            <TopNavBar
              toggleMenu={toggleMenu}
              myCredits={myCredits}
              isMenuOpen={isMenuOpen}
            ></TopNavBar>
            <div className="content-wrapper">
              <div className="flex-grow-1 container-p-y">
                {" "}
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-4 order-1 order-md-0">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="user-avatar-section">
                          <div className=" d-flex align-items-center flex-column">
                            {newUserAvatar && (
                              <img
                                className="img-fluid rounded mb-3 pt-1 mt-4 rounded-circle"
                                src={newUserAvatar}
                                height="100"
                                width="100"
                                alt="User avatar"
                              />
                            )}
                            <div className="user-info text-center">
                              <h4 className="mb-2">{userData.username}</h4>
                            </div>
                          </div>
                        </div>

                        <div className="info-container">
                          <ul className="list-unstyled text-center">
                            <li className="mb-2">
                              <span className="fw-medium me-1">Username:</span>
                              <span>{userData.username}</span>
                            </li>
                            <li className="mb-2 pt-1">
                              <span className="fw-medium me-1">Email:</span>
                              <span>{userData.email}</span>
                            </li>
                            {userData.contact && (
                              <li className="mb-2 pt-1">
                                <span className="fw-medium me-1">Contact:</span>
                                <span>{userData.contact}</span>
                              </li>
                            )}
                            {userData.country && (
                              <li className="mb-2 pt-1">
                                <span className="fw-medium me-1">Country:</span>
                                <span>{userData.country}</span>
                              </li>
                            )}
                            {userData.language && (
                              <li className="mb-2 pt-1">
                                <span className="fw-medium me-1">
                                  Language:
                                </span>
                                <span>{userData.language}</span>
                              </li>
                            )}
                            {userData.taxid && (
                              <li className="mb-2 pt-1">
                                <span className="fw-medium me-1">Tax Id:</span>
                                <span>{userData.taxid}</span>
                              </li>
                            )}
                            {userData.profile_role && (
                              <li className="mb-2 pt-1">
                                <span className="fw-medium me-1">Role:</span>
                                <span>{userData.profile_role}</span>
                              </li>
                            )}
                          </ul>
                          <div className="d-flex justify-content-center">
                            <a
                              href="javascript:;"
                              className="btn btn-primary me-3 waves-effect waves-light"
                              data-bs-target="#editUser"
                              data-bs-toggle="modal"
                              onClick={() => handleEditList()}
                            >
                              Edit
                            </a>
                            <a
                              href="javascript:;"
                              className="btn btn-label-danger suspend-user waves-effect"
                            >
                              Suspended
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-start">
                          <span className="badge bg-label-primary">
                            {userPlanData.plan_name}
                          </span>
                          <div className="d-flex justify-content-center">
                            <sup className="h6 fw-medium pricing-currency mt-3 mb-0 me-1 text-primary">
                              $
                            </sup>
                            <h1 className="mb-0 text-primary">
                              {userMemData.plan_type === "Monthly"
                                ? userPlanData.monthly_price
                                : userPlanData.yearly_price}
                            </h1>
                            <sub className="h6 fw-medium pricing-duration mt-auto mb-2 text-muted">
                              /month
                            </sub>
                          </div>
                        </div>

                        {userPlanData.plan_name !== "Free" && (
                          <div>
                            <div
                              style={{ whiteSpace: "pre-line" }}
                              className="pb-3 pt-3"
                            >
                              {userMemData.plan_type === "Monthly"
                                ? userPlanData.monthly_details
                                : userPlanData.yearly_details}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-1 fw-medium text-heading">
                              <span>Days</span>
                              <span>{planCompletePercent}% Completed</span>
                            </div>
                            <div
                              className="progress mb-1"
                              style={{ height: "8px" }}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${planCompletePercent}%` }}
                                aria-valuenow={planCompletePercent}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span>{remainingDays} days remaining</span>
                          </div>
                        )}
                        <div className="d-grid w-100 mt-4">
                          <Link
                            className="btn btn-primary waves-effect waves-light"
                            to="/plans"
                          >
                            Upgrade Plan
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9 col-lg-8 col-md-8 order-0 order-md-1">
                    <ul className="nav nav-pills flex-column flex-md-row mb-4">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            currentTab === "billing" ? "active" : ""
                          }`}
                          onClick={() => handleActiveTab("billing")}
                          href="#"
                        >
                          <i className="ti ti-currency-dollar me-1 ti-xs"></i>
                          Billing &amp; Plans
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            currentTab === "enrichments" ? "active" : ""
                          }`}
                          onClick={() => handleActiveTab("enrichments")}
                          href="#"
                        >
                          <i className="ti ti-user-check me-1 ti-xs"></i>
                          Enrichments
                        </a>
                      </li>
                    </ul>{" "}
                    {isEditing ? (
                      <div className="card mb-4">
                        <div className="card-body">
                          <h4>Edit User Profile</h4>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="userName">
                              <strong>
                                <i>UserName</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="userName"
                              name="userName"
                              className="form-control"
                              value={newUserName}
                              onChange={(e) => setNewUserName(e.target.value)}
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="userEmail">
                              <strong>
                                <i>Email</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="userEmail"
                              name="userEmail"
                              className="form-control"
                              value={newUserEmail}
                              onChange={(e) => setNewUserEmail(e.target.value)}
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="userPassword">
                              <strong>
                                <i>Password</i>
                              </strong>
                            </label>
                            <input
                              type="password"
                              id="userPassword"
                              name="userPassword"
                              className="form-control"
                              value={newUserPass}
                              onChange={(e) => setNewUserPass(e.target.value)}
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="taxId">
                              <strong>
                                <i>Tax ID</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="taxId"
                              name="taxId"
                              className="form-control"
                              value={taxId}
                              onChange={(e) => setTaxId(e.target.value)}
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="taxId">
                              <strong>
                                <i>Profile Role</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="role"
                              name="role"
                              className="form-control"
                              value={profileRole}
                              onChange={(e) => setProfileRole(e.target.value)}
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="contact">
                              <strong>
                                <i>Contact</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="contact"
                              name="contact"
                              className="form-control"
                              value={profileContact}
                              onChange={(e) =>
                                setProfileContact(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="language">
                              <strong>
                                <i>Language</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="language"
                              name="language"
                              className="form-control"
                              value={profileLang}
                              onChange={(e) => setProfileLang(e.target.value)}
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-md-6 col-xs-12"
                            style={{ float: "left", padding: "10px" }}
                          >
                            <label htmlFor="country">
                              <strong>
                                <i>Country</i>
                              </strong>
                            </label>
                            <input
                              type="text"
                              id="country"
                              name="country"
                              className="form-control"
                              value={profileCountry}
                              onChange={(e) =>
                                setProfileCountry(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-12"
                            style={{ float: "left" }}
                          >
                            <label htmlFor="avatar">
                              <strong>
                                <i>Profile Picture</i>
                              </strong>
                            </label>
                            <input
                              type="file"
                              id="avatar"
                              name="avatar"
                              className="form-control file"
                              onChange={(e) =>
                                setNewUserAvatar(e.target.files[0])
                              }
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div
                            className="form-group mb-4 col-12"
                            style={{ float: "left" }}
                          >
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              onClick={() => handleSaveEdit(true)}
                              style={{ width: "125px" }}
                            >
                              Save
                            </button>
                            &nbsp;
                            <button
                              className="btn btn-sm btn-secondary"
                              onClick={() => {
                                setIsEditing(false);
                              }}
                              style={{ width: "125px" }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="card mb-4"
                      style={{
                        display: currentTab === "billing" ? "block" : "none",
                      }}
                    >
                      <h4 className="card-header">Current Plan</h4>
                      <hr className="mt-0 mb-1" />
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-6 order-1 order-xl-0">
                            <div className="mb-2">
                              <h6 className="mb-1">
                                Your Current Plan is {userPlanData.plan_name}
                              </h6>
                              <p>A simple start for everyone</p>
                            </div>
                            {userPlanData.plan_name !== "Free" && (
                            <div className="mb-2 pt-1">
                              <h6 className="mb-1">
                                Active until{" "}
                                {formatDateTime(userMemData.plan_exiry_date)}
                              </h6>
                              <p>
                                We will send you a notification upon
                                Subscription expiration
                              </p>
                            </div>
                            )}
                            <div className="mb-3 pt-1">
                              <h6 className="mb-1">
                                <span className="me-2">
                                  ${upgradePlanData.monthly_price} Per Month
                                </span>{" "}
                                <span className="badge bg-label-primary">
                                  Popular
                                </span>
                              </h6>
                              <div
                                style={{ whiteSpace: "pre-line" }}
                                className="pb-3 pt-3"
                              >
                                {upgradePlanData.monthly_details}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 order-0 order-xl-0">
                            <div className="alert alert-warning" role="alert">
                              <h5 className="alert-heading mb-2">
                                {myCredits} <i>Credits left!</i>
                              </h5>
                            </div>
                          </div>
                          <div className="col-12 order-2 order-xl-0 d-flex flex-wrap gap-2">
                          {profileStatus !== "cancelled" ? (
                            <Link
                              className="btn btn-primary me-2 waves-effect waves-light"
                              style={{ maxHeight: "45px" }}
                              to="/plans"
                            >
                              Upgrade Plan
                            </Link>
                          ) : ""}
                            {profileStatus === "cancelled" ? (
                              <div className="alert alert-danger">
                                Subscription Cancelled!
                              </div>
                            ) : (
                              <a
                                id={userMembershipId}
                                className="btn btn-label-danger cancel-subscription waves-effect"
                                onClick={() =>
                                  handleCancelSubscription(userMembershipId)
                                }
                              >
                                Cancel Subscription
                              </a>
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card mb-4"
                      style={{
                        display:
                          currentTab === "enrichments" ? "block" : "none",
                      }}
                    >
                      <h4 className="card-header">My Enrichments</h4>
                      <hr className="mt-0 mb-1" />
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-12">
                            <UserEmailsListTable parentComp="profile"></UserEmailsListTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
